// SocketContext.tsx
import React, { createContext, useContext, useEffect } from "react";
import { io, Socket } from "socket.io-client";
import { baseURL } from "../constants";

interface SocketContextValue {
  socket: Socket | null;
}

const SocketContext = createContext<SocketContextValue | undefined>(undefined);

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
};

interface SocketProviderProps {
  children: React.ReactNode;
}

const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const socket = io(baseURL, {
    transports: ["websocket"],
    // reconnectionAttempts: 15,
  }); // Replace with your server URL

  useEffect(() => {
    // return () => {
    //   socket.disconnect();
    // };
  }, [socket]);

  const value: SocketContextValue = {
    socket,
  };

  return (
    <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
  );
};

export default SocketProvider;
