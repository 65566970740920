import React from "react";
import { Add, ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  List,
  ListItem,
  Rating,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import useReviews from "../../../utils/hooks/useReviews";
import ApplicantShimmer from "../../../components/skeleton/applicant_shimmer";
import ReviewsCard from "../../../components/reviews/reviews_card";
import noDataImage from "../../../assets/images/empty.png";
import { BrandedDialog } from "../../../components/dialog";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/apphook";
import { useFormik } from "formik";
import * as Yup from "yup";
import RoundedButton from "../../../components/button/round_button";
import { setLoading } from "../../../redux/reducers/loader";
import APIService from "../../../service";
import toast from "react-hot-toast";
import { mutate } from "swr";

type ReviewVals = {
  rating: number;
  comment: string;
};

type Props = {
  profile: any;
  setOpen: any;
  userData: any;
};

export default function UserReviews() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data, deviceType } = location?.state;
  const [open, setOpen] = React.useState<boolean>(false);
  const [isConnected, setConnected] = React.useState(false);

  const profile = useAppSelector((state) => state.auth.profile);

  const { data: reviewsData, loading, mutate } = useReviews(data?.id);

  React.useEffect(() => {
    // Check if this user is a connection
    profile?.connections?.forEach((item: any) => {
      if (item === data?.id) {
        // A connection
        setConnected(true);
      } else {
        setConnected(false);
      }
    });
   
  }, []);

  // console.log("REVIEWS DATA SWR :: ", reviewsData);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"start"}
    >
      <BrandedDialog
        open={open}
        setOpen={setOpen}
        title="Write A Review"
        body={
          <WriteReview profile={profile} setOpen={setOpen} userData={data} />
        }
      />
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"stretch"}
        alignItems={"center"}
      >
        <Button
          startIcon={<ArrowBack />}
          sx={{ textTransform: "capitalize" }}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <Box p={2}>
          <Typography
            variant={deviceType === "pc" ? "h5" : "body1"}
            fontWeight={700}
            textAlign={"center"}
          >
            {`Reviews`}
          </Typography>
        </Box>
      </Box>
      <List disablePadding>
        {loading ? (
          [1, 2, 3].map((item: number, index: number) => (
            <ListItem key={index} >
              <ApplicantShimmer />
            </ListItem>
          ))
        ) : reviewsData.data?.length > 0 ? (
          reviewsData?.data?.map((item: any, index: number) => (
            <ListItem key={index} >
              <ReviewsCard data={item} />
            </ListItem>
          ))
        ) : (
          <Box
            p={4}
            width={"100%"}
            height={"70vh"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <img src={noDataImage} alt="" width={100} />
            <Typography>No data found</Typography>
            {isConnected && <Button
              startIcon={<Add />}
              sx={{ textTransform: "capitalize" }}
              onClick={() => setOpen(true)}
            >
              Write review
            </Button>}
            
          </Box>
        )}
      </List>
    </Box>
  );
}

const WriteReview: React.FC<Props> = ({ profile, setOpen, userData }) => {
  const theme = useTheme();
  const [isClicked, setClicked] = React.useState(false);
  const dispatch = useAppDispatch();

  const validationSchema = Yup.object().shape({
    rating: Yup.number().required("Rating is required!"),
    comment: Yup.string().required("Comment is required!"),
  });

  const initialValues: ReviewVals = {
    comment: "",
    rating: 1,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (isClicked) {
        try {
          dispatch(setLoading(true));
          const payload = {
            rating: values.rating,
            comment: values.comment,
            userId: userData?.id,
            fullname: userData?.bio?.firstname + " " + userData?.bio?.lastname,
            reviewer: {
              id: profile?.id,
              name: profile?.bio?.firstname + " " + profile?.bio?.lastname,
              photo: profile?.bio?.image,
              email: profile?.email,
            },
          };

          const resp = await APIService.post(
            `/review/create/${profile?.email}`,
            payload
          );

          console.log("JK L ", resp.data);
          mutate("/review/byUser/"+profile?.email+"?userId="+userData?.id)

          setOpen(false);
          dispatch(setLoading(false));
        } catch (error) {
          console.log("EE", error);
          dispatch(setLoading(false));
        }
      } else {
        toast.error("Rating is not selected!");
      }
    },
  });

  const {
    errors,
    touched,
    getFieldProps,
    handleSubmit,
    values,
    setFieldValue,
  } = formik;

  return (
    <Box
      p={2}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Typography variant="body2" gutterBottom textAlign={"center"}>
        Please note that your profile will be visible to the public to enable
        others make informed decisions.
      </Typography>
      <Box
        pb={1}
        width={"100%"}
        alignSelf={"start"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <FormControl error={Boolean(touched.rating && errors.rating)}>
          <Rating
            name="simple-controlled"
            value={values.rating}
            onChange={(event, newValue) => {
              setFieldValue("rating", newValue);
              setClicked(true);
            }}
          />
          <FormHelperText>{errors.rating}</FormHelperText>
        </FormControl>
        <Typography color={"red"}>{values.rating}</Typography>
      </Box>
      <TextField
        fullWidth
        multiline
        minRows={5}
        maxRows={10}
        variant="outlined"
        label="Write Message"
        {...getFieldProps("comment")}
        error={Boolean(touched.comment && errors.comment)}
        helperText={errors.comment}
      />
      <br />
      <RoundedButton
        fullWidth
        variant="contained"
        onClick={() => handleSubmit()}
        sx={{ bgcolor: theme.palette.primary.main, color: "white" }}
      >
        Submit Review
      </RoundedButton>
    </Box>
  );
};
