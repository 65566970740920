import {
  ArrowBack,
  FileDownload,
  TrackChangesRounded,
  Work,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  ListItem,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { formatDistance } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
// import { useAppSelector } from "../../utils/hooks/apphook";
import pdfImage from "../../assets/images/pdf.png";
import docImage from "../../assets/images/docs.png";
import image from "../../assets/images/placeholder.png";

interface DateOptions {
  includeSeconds: boolean;
  addSuffix: boolean;
}

export default function ApplicationInfo() {
  const location = useLocation();
  const { data } = location?.state;

  const [resumeExtension, setResumeExtension] = React.useState("");
  const [deviceType, setDeviceType] = React.useState("mobile");

  //   const myAppliedJobs = useAppSelector((state) => state.jobs.myJobApplications);
  //   const profile = useAppSelector((state) => state.auth.profile);
  //   const isAuth = useAppSelector((state) => state.auth.isAuth);

  const theme = useTheme();
  const navigate = useNavigate();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  React.useEffect(() => {
    if (data) {
      const ext = data?.resume?.toString().split("resumes")[1].split("?alt")[0];
      setResumeExtension(ext);
      console.log("RESMUE ;; ", data?.resume);
      console.log("EXT ;; ", ext);
    }
  }, [data]);

  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet]);

  let options: DateOptions = {
    addSuffix: true,
    includeSeconds: true,
  };


  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"start"}>
      {deviceType === "pc" ? <Toolbar /> : <></>}
      <Box
        pb={2}
        width={"100%"}
        display="flex"
        flexDirection={"row"}
        justifyContent={"start"}
        alignItems={"center"}
      >
        <Button
          startIcon={<ArrowBack />}
          onClick={() => navigate(-1)}
          sx={{ textTransform: "capitalize" }}
        >
          Back
        </Button>
        <Typography
          px={2}
          variant={deviceType === "pc" ? "h4" : "h6"}
          textTransform={"capitalize"}
        >
          {data?.job?.jobTitle}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8}>
          <Box
            display="flex"
            flexDirection={"row"}
            justifyContent={"start"}
            alignItems={"center"}
          >
            <Avatar
              src={data?.applicant?.bio?.image}
              variant="rounded"
              sx={{ width: 56, height: 56 }}
            />
            <Box
              pl={2}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"start"}
              alignItems={"startt"}
              textTransform={"capitalize"}
            >
              <Typography fontWeight={500}>{`${data?.applicant?.bio?.firstname} ${data?.applicant?.bio?.lastname}`}</Typography>
              <Typography fontSize={12} textTransform={"lowercase"}>
                {data?.applicant?.email}
              </Typography>
              <Typography fontSize={12} textTransform={"lowercase"}>
                {data?.applicant?.bio?.phone}
              </Typography>
            </Box>
          </Box>
          <Typography fontSize={14} pt={1} textTransform={"capitalize"}>
            {`Applied on ${new Date(data?.createdAt).toLocaleDateString(
              "en-GB"
            )} (${formatDistance(
              Date.parse(`${data?.createdAt}`),
              new Date(),
              options
            )})`}
          </Typography>

          <Typography pt={2} color={"black"} fontWeight={900} fontSize={18}>
            Job Information
          </Typography>
          <Box
            pb={1}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"start"}
            alignItems={"center"}
            textTransform={"capitalize"}
          >
            <Work />
            <Typography px={2} fontWeight={400} variant="body2">
              {`${data?.job?.jobType} `}
            </Typography>
          </Box>
          <Box
            pb={1}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"start"}
            alignItems={"center"}
            textTransform={"capitalize"}
          >
            <TrackChangesRounded />
            <Typography px={2} fontWeight={400} variant="body2">
              {`${data?.job?.jobStatus}`}
            </Typography>
          </Box>

          <Box
            pb={2}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"start"}
            alignItems={"start"}
          >
            <Typography
              color={"black"}
              fontWeight={900}
              fontSize={18}
              textTransform={"capitalize"}
            >
              {`Description `}
            </Typography>
            <Typography variant="body2">{`${data?.job?.description}`}</Typography>
          </Box>

          <Box
            pb={2}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"start"}
            alignItems={"start"}
            textTransform={"capitalize"}
          >
            <Typography color={"black"} fontWeight={900} fontSize={18}>
              {`Minimum qualification: `}
            </Typography>
            <Typography variant="body2">{`${data?.job?.minimumQualification}`}</Typography>
          </Box>

          <Box
            pb={2}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"start"}
            alignItems={"start"}
          >
            <Typography
              color={"black"}
              fontWeight={900}
              gutterBottom
              fontSize={18}
            >
              {`Applicant's Resume`}
            </Typography>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"start"}
              alignItems={"center"}
            >
              <Avatar
                variant="rounded"
                src={
                  resumeExtension?.endsWith("pdf")
                    ? pdfImage
                    : resumeExtension?.endsWith("doc") ||
                      resumeExtension?.endsWith("docx")
                    ? docImage
                    : image
                }
              />
              <Typography px={4}>
                {`${data?.applicant?.bio?.firstname}'s resume`}
              </Typography>
              <a href={data?.resume} download="your-file.pdf">
                <IconButton>
                  <FileDownload />
                </IconButton>
              </a>
            </Box>
          </Box>

          <Box
            pb={2}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"start"}
            alignItems={"start"}
          >
            <Typography
              color={"black"}
              fontWeight={900}
              gutterBottom
              fontSize={18}
            >
              {`Screening Answers`}
            </Typography>
            <>
              {data?.answers?.map((item: any, index: number) => (
                <Box
                  key={index}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"start"}
                  alignItems={"start"}
                  component={ListItem}
                  divider
                  disablePadding
                >
                  <Typography variant="body2" fontWeight={600}>
                    {item?.question}
                  </Typography>
                  <Typography variant="body2">{item?.answer}</Typography>
                </Box>
              ))}
            </>
          </Box>
        </Grid>
      </Grid>
      <Toolbar />
    </Box>
  );
}
