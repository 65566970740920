import React from "react";

import {
  Avatar,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";

import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { setLoading } from "../../../redux/reducers/loader";

import { countries } from "../../../utils/data/countries";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CustomSwitch from "../../../components/switch/ios_switch";
import RoundedButton from "../../../components/button/round_button";

import {
  storage,
  uploadBytesResumable,
  getDownloadURL,
} from "../../../utils/firebase-config";
import { ref } from "firebase/storage";
import APIService from "../../../service";
import { toast } from "react-hot-toast";
import { CameraAlt } from "@mui/icons-material";
import { useAppSelector } from "../../../utils/hooks/apphook";
import { setProfile } from "../../../redux/reducers/auth";

interface ItemProps {
  data: any;
  setOpen: any;
}

export default function UpdateExperience({ data, setOpen }: ItemProps) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const userProfile = useAppSelector((state) => state.auth.profile);

  const pickerRef = React.useRef<any>();
  const [mfile, setMFile] = React.useState<any>(null);
  const [previewImage, setPreviewImage] = React.useState(data?.companyLogo);

  const validationSchema = Yup.object().shape({
    company: Yup.string().required("Company name is required!"),
    role: Yup.string().required("Role is required!"),
    region: Yup.string().required("Region/state is required!"),
    country: Yup.string().required("Country name is required!"),
    workType: Yup.string().required("Work type is required!"),
    startDate: Yup.date().required("State date is required!"),
    endate: Yup.date().nullable(),
    stillHere: Yup.boolean().nullable(),
  });

  let initialValues = {
    role: data?.role,
    region: data?.region,
    endate: new Date(data?.endate).toLocaleDateString("en-GB") ?? new Date(),
    country: data?.country,
    company: data?.company,
    workType: data?.workType,
    stillHere: data?.stillHere ?? false,
    startDate: new Date(data?.startDate),
  };

  React.useEffect(() => {
    if (data) {
      initialValues.company = data?.company;
    }
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async () => {},
  });

  const {
    errors,
    touched,
    getFieldProps,
    handleSubmit,
    values,
    setFieldValue,
  } = formik;

  const handleFileInputChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      try {
        if (event.target?.files[0]) {
          setMFile(file);
          setPreviewImage(URL.createObjectURL(file));
        } else {
          setPreviewImage("");
        }
      } catch (e) {}
    } else {
      setMFile(null);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Box
        pb={4}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"start"}
        alignItems={"stretch"}
        component={Form}
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
      >
        <Toolbar />
        {/* Default first row  */}
        <Box px={6} width={"100%"}>
          <Grid
            container
            spacing={2}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-evenly"}
            alignItems={"center"}
          >
            <Grid item xs={12} sm={12} md={6}>
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <IconButton
                  sx={{ p: 0, position: "relative" }}
                  onClick={() => {
                    pickerRef.current.click();
                  }}
                >
                  <Box
                    position={"absolute"}
                    bottom={1}
                    zIndex={10}
                    width={86}
                    height={35}
                    display="flex"
                    flexDirection={"column"}
                    justifyContent={"end"}
                    alignItems={"center"}
                    sx={{
                      width: 56,
                      height: 56,
                      borderRadius: 28,
                      backgroundImage: "linear-gradient(transparent, #ccc)",
                    }}
                  >
                    <CameraAlt
                      fontSize="small"
                      sx={{ color: "white", mb: 2 }}
                    />
                  </Box>
                  <Avatar
                    sx={{
                      width: 58,
                      height: 58,
                      borderRadius: 29,
                      border: "1px solid #0066F5",
                    }}
                    alt="Companly logo"
                    src={previewImage}
                  />
                </IconButton>
                <input
                  ref={pickerRef}
                  id="image"
                  name="image"
                  type="file"
                  style={{ display: "none" }}
                  // value={image}
                  onChange={handleFileInputChange}
                  placeholder="Image banner"
                />
                <Typography fontSize={14} p={2}>
                  Company Logo
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                variant="outlined"
                label="Company"
                type="text"
                size="small"
                required
                fullWidth
                placeholder="Enter company name"
                {...getFieldProps("company")}
                error={Boolean(errors.company && touched.company)}
                // helperText={errors.company && touched.company}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Default first row  */}
        <Box px={6} py={2}>
          <Grid
            container
            spacing={2}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-evenly"}
            alignItems={"center"}
          >
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                variant="outlined"
                label="Role"
                type="text"
                size="small"
                required
                fullWidth
                placeholder="Enter role here"
                {...getFieldProps("role")}
                error={Boolean(errors.role && touched.role)}
                // helperText={errors.role}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                variant="outlined"
                label="Region"
                type="text"
                size="small"
                required
                fullWidth
                placeholder="Enter region/state"
                {...getFieldProps("region")}
                error={Boolean(errors.region && touched.region)}
                // helperText={errors.region}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Default first row  */}
        <Box px={6}>
          <Grid
            container
            spacing={2}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-evenly"}
            alignItems={"center"}
          >
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                fullWidth
                error={Boolean(errors.workType && touched.workType)}
              >
                <InputLabel id="demo-simple-select-label">Work Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  variant="outlined"
                  label="Work Type"
                  type="text"
                  size="small"
                  required
                  fullWidth
                  placeholder="Enter work type here"
                  {...getFieldProps("workType")}
                >
                  {["part-time", "full-time", "contract"].map(
                    (item: string, index: number) => (
                      <MenuItem
                        key={index}
                        sx={{ textTransform: "capitalize" }}
                        value={item}
                      >
                        {item}
                      </MenuItem>
                    )
                  )}
                </Select>
                {/* <FormHelperText>{errors.workType}</FormHelperText> */}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                fullWidth
                error={Boolean(errors.country && touched.country)}
              >
                <InputLabel id="demo-select-label-">Country</InputLabel>
                <Select
                  labelId="demo-select-label"
                  variant="outlined"
                  label="Country"
                  type="text"
                  size="small"
                  required
                  fullWidth
                  placeholder="Select country here"
                  {...getFieldProps("country")}
                  error={Boolean(errors.country && touched.country)}
                >
                  {countries.map((item: string, index: number) => (
                    <MenuItem
                      key={index}
                      sx={{ textTransform: "capitalize" }}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                {/* <FormHelperText>{errors.country}</FormHelperText> */}
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        {/* Default first row  */}
        <Box px={6} py={2}>
          <Grid
            container
            spacing={2}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-evenly"}
            alignItems={"center"}
          >
            <Grid item xs={12} sm={12} md={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Start Date"
                  inputFormat="dd/MM/yyyy"
                  value={values.startDate}
                  disableFuture
                  // readOnly
                  // disableToolbar
                  // minDate={date}
                  maxDate={new Date()}
                  onChange={(value) => {
                    console.log("CHECK VALUE", value);
                    setFieldValue("startDate", value);
                    // setDatePicked(true);
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      fullWidth
                      required
                      inputProps={{
                        disabled: true,
                      }}
                      disabled={true}
                      size="small"
                      {...params}
                      placeholder="Enter date of birth"
                      error={Boolean(touched.startDate && errors.startDate)}
                      helperText={touched.startDate && errors.startDate}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              sx={{ display: values.stillHere ? "none" : "block" }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="End Date"
                  inputFormat="dd/MM/yyyy"
                  value={values.endate}
                  disableFuture
                  // readOnly
                  // disableToolbar
                  // minDate={date}
                  maxDate={new Date()}
                  onChange={(value) => {
                    console.log("CHECK VALUE", value);
                    setFieldValue("endate", value);
                    // setDatePicked(true);
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      fullWidth
                      required
                      inputProps={{
                        disabled: true,
                      }}
                      disabled={true}
                      size="small"
                      {...params}
                      placeholder="Enter date of birth"
                      error={Boolean(touched.endate && errors.endate)}
                      helperText={touched.endate && errors.endate}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <CustomSwitch
                label="Still here"
                checked={values.stillHere}
                name="stillHere"
                setFieldValue={setFieldValue}
              />
            </Grid>
          </Grid>
          <Toolbar />
          <RoundedButton
            fullWidth
            type="submit"
            onClick={async (e) => {
              e.stopPropagation();
              console.log("NOw WHAT??");
              dispatch(setLoading(true));

              if (mfile) {
                // Company logo is present
                //First save to cloud storage here
                let storageRef = ref(
                  storage,
                  "experience/" + userProfile.id + "/" + new Date().getTime()
                );
                let uploadTask = uploadBytesResumable(storageRef, mfile);

                uploadTask.on(
                  "state_changed",
                  (snapshot) => {
                    const uprogress =
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    // setProgress(uprogress);
                  },
                  (error) => {
                    // setIsUploading(false);
                    console.log(error);
                    // enqueueSnackbar(`${error.message}`, { variant: "error" });
                  },
                  () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(
                      async (downloadURL) => {
                        const updatedExperiences = userProfile?.experience?.map(
                          (expe: any) =>
                            expe.company === data?.company
                              ? { ...values, companyLogo: downloadURL }
                              : expe
                        );

                        const payload = {
                          experience: [...updatedExperiences],
                        };

                        try {
                          const res = await APIService.update(
                            "/updateuser",
                            userProfile?.email,
                            {
                              ...payload,
                            }
                          );

                          dispatch(setProfile(res.data.data));
                          console.log("RESP HERE >>> ", `${res}`);
                          setOpen(false);
                          dispatch(setLoading(false));

                          toast.success(`${res.data.message}`);
                        } catch (error: any) {
                          dispatch(setLoading(false));
                          console.log(
                            "ERROR HERE >>> ",
                            `${
                              error?.response?.data?.message ||
                              error?.message ||
                              "Something went wrong, try again."
                            }`
                          );
                        }
                      }
                    );
                  }
                );
              } else {
                const updatedExperiences = userProfile?.experience?.map(
                  (expe: any) =>
                    expe.company === data?.company ? { ...values } : expe
                );

                await setTimeout(() => {
                  console.log("Just do nothing ...");
                }, 4000);

                const payload = {
                  experience: [...updatedExperiences],
                };

                try {
                  const res = await APIService.update(
                    "/updateuser",
                    userProfile?.email,
                    {
                      ...payload,
                    }
                  );

                  console.log("RESP HERE >>> ", `${res}`);
                  dispatch(setProfile(res.data.data));
                  setOpen(false);
                  dispatch(setLoading(false));

                  toast.success(`${res.data.message}`);
                } catch (error: any) {
                  dispatch(setLoading(false));
                  console.log(
                    "ERROR HERE >>> ",
                    `${
                      error?.response?.data?.message ||
                      error?.message ||
                      "Something went wrong, try again."
                    }`
                  );
                }
              }
            }}
            variant="contained"
            sx={{ bgcolor: theme.palette.primary.main, color: "white" }}
          >
            Save Changes
          </RoundedButton>
        </Box>
      </Box>
    </FormikProvider>
  );
}
