import useSWR from "swr";

export default function useReviews(userId: string) {
  let authMail: string = localStorage.getItem("auth-email") ?? "";
  // console.log("KJNKS", authMail);
  

  const { data, mutate, error } = useSWR("/review/byUser/"+authMail+"?userId="+userId);

  console.log('REVIEW SS UIHIU )>', data);
  const loading = !data && !error;
  const loggedOut =
    (error && error?.message === "No token provided.") ||
    error?.response?.status === 401 ||
    error?.response?.status === 403 ||
    error?.response?.data?.message === "No user found!" ||
    data?.accountStatus === "frozen";

  return {
    loading,
    loggedOut,
    data,
    mutate,
  };
}
