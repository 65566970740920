import useSWR from "swr";
import { RootState } from "../../redux/store";
import { useAppSelector } from "./apphook";

export default function useConversations() {
  const isAuthenticated = useAppSelector((state: RootState) => state.auth.isAuth); 
  let authMail: string = localStorage.getItem("auth-email") ?? "";
  const { data, mutate, error } = useSWR(isAuthenticated ? "/chat/allChats/" + authMail : "");

  console.log("CHAT CONVERSATIONS RESPONSE", data);
  
  const loading = !data && !error;
  const loggedOut =
    (error && error?.message === "No token provided.") ||
    error?.response?.status === 401 ||
    error?.response?.status === 403 ||
    error?.response?.data?.message === "No user found!" ||
    data?.accountStatus === "frozen";

  return isAuthenticated ? {
    loading,
    loggedOut,
    data,
    mutate,
  } : {};
}
