import React from "react";
import Box from "@mui/material/Box";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import Typography from "@mui/material/Typography";
import { Avatar, IconButton, useMediaQuery } from "@mui/material";
import theme from "../../assets/theme/Theme";
import CustomContainer from "../../components/container";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";

interface Props {
  data: any;
}

interface ItemProps {
    item: any;
  }

const Testimonial: React.FC<Props> = ({ data }) => {
    const [deviceType, setDeviceType] = React.useState("mobile");
    const customSlider: React.RefObject<Slider> = React.createRef();
    const mobile = useMediaQuery(theme.breakpoints.only("xs"));
    const tablet = useMediaQuery(theme.breakpoints.only("sm"));
    const tabletBig = useMediaQuery(theme.breakpoints.only("md"));


    React.useEffect(() => {
        if (mobile) {
          setDeviceType("mobile");
        } else if (tablet) {
          setDeviceType("tablet");
        } else if (tabletBig) {
          setDeviceType("tabletBig");
        } else {
          setDeviceType("pc");
        }
      }, [mobile, tablet, tabletBig]);

  var settings = {
    dots: false,
    infinite: true,
    autoPlay: true,
    speed: 500,
    slidesToShow:
      deviceType === "mobile"
        ? 1
        : deviceType === "tablet"
        ? 2
        : deviceType === "tabletBig"
        ? 3
        : 4,
    slidesToScroll: 1,
  };

  return (
    <Box paddingY={deviceType === "pc" ? 16 : deviceType === "tablet" ? 12 : 8}>
      <CustomContainer>
        <Typography gutterBottom fontWeight={600} variant="h4">
         {data?.title}
        </Typography>
        <Box position={"relative"}>
          <Slider {...settings} ref={customSlider}>
            {data?.testimonials?.map((item: any, index: number) => (
              <Item key={index} item={item} />
            ))}
          </Slider>
          <Box
            position={"absolute"}
            top={144}
            bottom={144}
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <IconButton
              sx={{
                bgcolor: "#000",
                color: "white",
              }}
              onClick={() => customSlider?.current?.slickPrev()}
            >
              <ArrowBackIosNew />
            </IconButton>

            <IconButton
              sx={{
                bgcolor: "#000",
                color: "white",
              }}
              onClick={() => customSlider?.current?.slickNext()}
            >
              <ArrowForwardIos />
            </IconButton>
          </Box>
        </Box>
      </CustomContainer>
    </Box>
  );
};

const Item = ({ item }: ItemProps) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"center"}
      p={2}
      height={256}
    >
      <Avatar src={item?.image} />
      <br />
      <br />
      <Typography gutterBottom>{item?.name}</Typography>
      <Typography gutterBottom fontStyle={"italic"} fontSize={13}>
        {item?.message}
      </Typography>
    </Box>
  );
};

export default Testimonial;
