import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
interface JobState {
  jobs: any;
  myJobApplications: any;
  myPostedJobs: any[];
  profileJobSearchResults: any;
  requirements: string[];
  screeningQuestions: string[];
}

// Define the initial state using that type
const initialState: JobState = {
  jobs: null,
  myPostedJobs: [],
  myJobApplications: null,
  profileJobSearchResults: null,
  requirements: [],
  screeningQuestions: [],
};

export const jobSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setJobs: (state, action: PayloadAction<any>) => {
      state.jobs = action.payload;
    },
    setMyPostedJobs: (state, action: PayloadAction<any[]>) => {
      state.myPostedJobs = action.payload;
    },
    setProfileJobSearchResults: (state, action: PayloadAction<any>) => {
      state.profileJobSearchResults = action.payload;
    },
    setRequirements: (state, action: PayloadAction<string[]>) => {
      state.requirements = action.payload;
    },
    setScreeningQuestions: (state, action: PayloadAction<string[]>) => {
      state.screeningQuestions = action.payload;
    },
    setMyJobApplications: (state, action: PayloadAction<any>) => {
      state.myJobApplications = action.payload;
    },
  },
});

export const {
  setJobs,
  setMyPostedJobs,
  setProfileJobSearchResults,
  setRequirements,
  setScreeningQuestions,
  setMyJobApplications,
} = jobSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const getJobs = (state: RootState) => state.;

export default jobSlice.reducer;
