const relationships: string[] = [
  "parent",
  "sibling",
  "nephew",
  "niece",
  "cousin",
  "aunty",
  "uncle",
  "grand-parent",
  "friend",
  "colleague",
];

export default relationships;