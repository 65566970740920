import React from "react";

import { Props } from "../../../pages/user/experience_section";
import {
  Avatar,
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";

import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { setLoading } from "../../../redux/reducers/loader";

import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CustomSwitch from "../../../components/switch/ios_switch";
import RoundedButton from "../../../components/button/round_button";

import {
  storage,
  uploadBytesResumable,
  getDownloadURL,
} from "../../../utils/firebase-config";
import { ref } from "firebase/storage";
import APIService from "../../../service";
import { toast } from "react-hot-toast";
import { CameraAlt } from "@mui/icons-material";
import { setProfile } from "../../../redux/reducers/auth";

export default function AddEducation({
  experience,
  setOpen,
  userProfile,
}: Props) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const pickerRef = React.useRef<any>();
  const [mfile, setMFile] = React.useState<any>(null);
  const [previewImage, setPreviewImage] = React.useState("");

  // const profile = useAppSelector((state) => state.auth.profile);

  const validationSchema = Yup.object().shape({
    school: Yup.string().required("School name is required!"),
    degree: Yup.string().required("Degree is required!"),
    course: Yup.string().nullable(),
    endate: Yup.date().nullable(),
    stillSchooling: Yup.boolean().nullable(),
  });

  const initialValues = {
    school: "",
    degree: "",
    endate: null,
    course: "",
    stillSchooling: false,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async () => {
      dispatch(setLoading(true));
      if (mfile) {
        // Company logo is present
        //First save to cloud storage here
        let storageRef = ref(
          storage,
          "education/" + userProfile.id + "/" + new Date().getTime()
        );
        let uploadTask = uploadBytesResumable(storageRef, mfile);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const uprogress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // setProgress(uprogress);
          },
          (error) => {
            // setIsUploading(false);
            console.log(error);
            // enqueueSnackbar(`${error.message}`, { variant: "error" });
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then(
              async (downloadURL) => {
                const payload = {
                  education: [
                    ...userProfile?.education,
                    { ...values, schoolLogo: downloadURL },
                  ],
                };

                try {
                  const res = await APIService.update(
                    "/updateuser",
                    userProfile?.email,
                    {
                      ...payload,
                    }
                  );

                  console.log("RESP HERE >>> ", `${res}`);
                  dispatch(setProfile(res.data.data));
                  setOpen(false);
                  dispatch(setLoading(false));

                  toast.success("Operation successful");
                } catch (error: any) {
                  dispatch(setLoading(false));
                  console.log(
                    "ERROR HERE >>> ",
                    `${
                      error?.response?.data?.message ||
                      error?.message ||
                      "Something went wrong, try again."
                    }`
                  );
                }
              }
            );
          }
        );
      } else {
        const payload = {
          education: [...userProfile?.education, { ...values, schoolLogo: "" }],
        };

        try {
          const res = await APIService.update(
            "/updateuser",
            userProfile?.email,
            {
              ...payload,
            }
          );


          console.log("RESP HERE >>> ", `${res}`);
          dispatch(setProfile(res.data.data));
          setOpen(false);
          dispatch(setLoading(false));

          toast.success("Operation successful");
        } catch (error: any) {
          dispatch(setLoading(false));
          console.log(
            "ERROR HERE >>> ",
            `${
              error?.response?.data?.message ||
              error?.message ||
              "Something went wrong, try again."
            }`
          );
        }
      }
    },
  });

  const {
    errors,
    touched,
    getFieldProps,
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
  } = formik;

  const handleFileInputChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      try {
        if (event.target?.files[0]) {
          setMFile(file);
          setPreviewImage(URL.createObjectURL(file));
        } else {
          setPreviewImage("");
        }
      } catch (e) {}
    } else {
      setMFile(null);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Box
        pb={4}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"start"}
        alignItems={"stretch"}
        component={Form}
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
      >
        <Toolbar />
        {/* Default first row  */}
        <Box
          px={6}
          width={"100%"}
          display={values.degree === "none" ? "none" : "flex"}
          flexDirection={"column"}
        >
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <IconButton
              sx={{ p: 0, position: "relative" }}
              onClick={() => {
                pickerRef.current.click();
              }}
            >
              <Box
                position={"absolute"}
                bottom={1}
                zIndex={10}
                width={86}
                height={35}
                display="flex"
                flexDirection={"column"}
                justifyContent={"end"}
                alignItems={"center"}
                sx={{
                  width: 56,
                  height: 56,
                  borderRadius: 28,
                  backgroundImage: "linear-gradient(transparent, #ccc)",
                }}
              >
                <CameraAlt fontSize="small" sx={{ color: "white", mb: 2 }} />
              </Box>
              <Avatar
                sx={{
                  width: 58,
                  height: 58,
                  borderRadius: 29,
                  border: "1px solid #0066F5",
                }}
                alt="School logo"
                src={previewImage}
              />
            </IconButton>
            <input
              ref={pickerRef}
              id="image"
              name="image"
              type="file"
              style={{ display: "none" }}
              // value={image}
              onChange={handleFileInputChange}
              placeholder="Image banner"
            />
            <Typography fontSize={14} p={2}>
              School Logo
            </Typography>
          </Box>

          <Box>
            <TextField
              variant="outlined"
              label="School"
              type="text"
              size="small"
              required
              fullWidth
              placeholder="Enter school name"
              {...getFieldProps("school")}
              error={Boolean(errors.school && touched.school)}
              helperText={errors.school}
            />
          </Box>
        </Box>

        <Box
          px={6}
          py={2}
          display={
            values.degree === "none" || values.degree === "secondary"
              ? "none"
              : "flex"
          }
        >
          <TextField
            variant="outlined"
            label="Course of study"
            type="text"
            size="small"
            required
            fullWidth
            placeholder="Enter course here"
            {...getFieldProps("course")}
            error={Boolean(errors.course && touched.course)}
            helperText={errors.course}
          />
        </Box>

        <Box px={6} py={1}>
          <FormControl
            fullWidth
            error={Boolean(errors.degree && touched.degree)}
          >
            <InputLabel id="demo-simple-select-label">Degree</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              variant="outlined"
              label="Degree"
              type="text"
              size="small"
              required
              fullWidth
              name="degree"
              value={values.degree}
              onChange={(e) => {
                handleChange("degree");
                setFieldValue("degree", e.target.value);
                console.log("CURRENT DEGREE :: ", e.target.value);
                if (e?.target.value === "none") {
                  setFieldValue("endate", null);
                }
              }}
              placeholder="Select degree here"
              //   {...getFieldProps("degree")}
            >
              {[
                "none",
                "secondary",
                "under-graduate",
                "first-degree",
                "masters",
                "doctorate",
              ].map((item: string, index: number) => (
                <MenuItem
                  key={index}
                  sx={{ textTransform: "capitalize" }}
                  value={item}
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.degree}</FormHelperText>
          </FormControl>
        </Box>

        {/* Default first row  */}
        <Box
          px={6}
          py={2}
          display={values.degree === "none" ? "none" : "flex"}
          flexDirection={"column"}
        >
          <Box sx={{ display: values.stillSchooling ? "none" : "block" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                label="End Date"
                inputFormat="dd/MM/yyyy"
                value={values.endate}
                disableFuture
                // readOnly
                // disableToolbar
                // minDate={date}
                maxDate={new Date()}
                onChange={(value) => {
                  console.log("CHECK VALUE", value);
                  setFieldValue("endate", value);
                  // setDatePicked(true);
                }}
                renderInput={(params: any) => (
                  <TextField
                    fullWidth
                    required
                    inputProps={{
                      disabled: true,
                    }}
                    disabled={true}
                    size="small"
                    {...params}
                    placeholder="Enter graduation date"
                    error={Boolean(touched.endate && errors.endate)}
                    helperText={touched.endate && errors.endate}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box display={values.degree === "none" ? "none" : "flex"} mt={2}>
            <CustomSwitch
              label="Still a student"
              checked={values.stillSchooling}
              name="stillSchooling"
              setFieldValue={setFieldValue}
            />
          </Box>
          <Toolbar />
        </Box>

        <Box px={6} py={2}>
          <RoundedButton
            fullWidth
            type="submit"
            variant="contained"
            sx={{ bgcolor: theme.palette.primary.main, color: "white" }}
          >
            Save Education
          </RoundedButton>
        </Box>
      </Box>
    </FormikProvider>
  );
}
