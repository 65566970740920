import { Box, Checkbox, Typography, useTheme } from "@mui/material";
import RoundedButton from "../../components/button/round_button";
import theme from "../../assets/theme/Theme";
import { Chat, Flag, Visibility } from "@mui/icons-material";
// import { useAppSelector } from "../../utils/hooks/apphook";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import React from "react";
import { InfoDialog } from "../../components/dialog";
import { useAppDispatch, useAppSelector } from "../../utils/hooks/apphook";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import APIService from "../../service";
import coinLogo from "../../assets/images/coin_gold.png";
import { setLoading } from "../../redux/reducers/loader";
import { mutate } from "swr";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/store";

interface Props {
  deviceType: any;
  from?: string;
  userData: any;
}

interface ContactInfoProps {
  isConnected?: boolean;
  userData: any;
  setOpen: any;
}

export default function ActionRow(props: Props) {
  const { deviceType, from, userData } = props;

  const [open, setOpen] = React.useState(false);
  const [openReport, setOpenReport] = React.useState(false);
  const [isConnected, setConnected] = React.useState(false);

  const profile = useAppSelector((state) => state.auth.profile);
  const myConnections = useAppSelector((state: RootState) => state.users.myConnections);

  console.log("CONNECTIONS:: ", myConnections);
  console.log("USEFDATA :: ", userData);
  

  React.useEffect(() => {
    // Check if this user is a connection
    myConnections?.forEach((item: any) => {
      if (item?.id === userData?.id) {
        // A connection
        setConnected(true);
      } 
    });

  }, [myConnections, userData?.id]);

  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={deviceType === "pc" ? "stretch" : "space-between"}
      alignItems={"center"}
      height={deviceType === "mobile" ? 38 : 42}
    >
      <InfoDialog
        open={open}
        setOpen={setOpen}
        title="Contact Information"
        body={
          <ContactInfoBody
            isConnected={isConnected}
            userData={userData}
            setOpen={setOpen}
          />
        }
      />
      <InfoDialog
        open={openReport}
        setOpen={setOpenReport}
        title={`Report/Block ${userData?.bio?.firstname}`.toUpperCase()}
        body={<ReportUserBody userData={userData} setOpen={setOpenReport} />}
      />
      <RoundedButton
        variant="contained"
        sx={{ bgcolor: theme.palette.primary.main, color: "white", mr: 0.5 }}
        onClick={() => setOpen(true)}
      >
        <Box
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Visibility
            fontSize="small"
            sx={{ mx: 1, display: { xs: "none", sm: "none", md: "block" } }}
          />
          <Typography
            fontSize={deviceType === "mobile" ? "0.9rem" : "1.0rem"}
          >{`${
            deviceType === "mobile" ? "Contact" : "Contact info"
          }`}</Typography>
        </Box>
      </RoundedButton>

      <RoundedButton
        variant="contained"
        sx={{
          bgcolor: theme.palette.secondary.main,
          color: "black",
          mr: 0.5,
          display: from || isConnected ? "block" : "none",
        }}
        onClick={() => setOpenReport(true)}
      >
        <Box
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Flag
            fontSize="small"
            sx={{ mx: 1, display: { xs: "none", sm: "none", md: "block" } }}
          />
          <Typography
            fontSize={deviceType === "mobile" ? "0.9rem" : "1.0rem"}
          >{`${
            isConnected ? (deviceType === "pc" ? "Report/Block" : "Report") : ""
          }`}</Typography>
        </Box>
      </RoundedButton>

      <RoundedButton
        variant="contained"
        sx={{ bgcolor: theme.palette.info.main, color: "white", ml: 0.5 }}
      >
        {
          <FactCheckIcon
            fontSize="small"
            sx={{ mx: 1, display: { xs: "none", sm: "none", md: "block" } }}
          />
        }
        <Typography
          fontSize={deviceType === "mobile" ? "0.9rem" : "1.0rem"}
        >{`${
          deviceType === "mobile" ? "Verified" : "Verified Doc"
        }`}</Typography>
      </RoundedButton>
    </Box>
  );
}

export const ContactInfoBody = ({
  isConnected,
  userData,
  setOpen,
}: ContactInfoProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isClicked, setClicked] = React.useState(false);
  const profile = useAppSelector((state) => state.auth.profile);
  // const { mutate } = useSWRConfig();

  function _obscurePhone(phone: string) {
    var part1 =
      phone?.length > 11 ? phone?.substring(0, 6) : phone?.substring(0, 4);
    var part2 =
      phone?.length > 11 ? phone?.substring(11, 14) : phone?.substring(8, 11);

    return part1 + "xxxxx" + part2;
  }

  function _obscureEmail(email: string) {
    var rem = email?.split("@")[1];
    var secon1 = rem?.split(".")[0];

    return "xxxxxx@" + secon1 + ".xxx";
  }

  const performAction = async () => {
    setOpen(false);

    const payload = {
      guestId: userData?.id,
      guestName: `${userData?.bio?.firstname} ${userData?.bio?.middlename} ${userData?.bio?.lastname}`,
      userId: profile?.id,
    };

    console.log("GUEST ", userData);

    if (!isConnected) {
      dispatch(setLoading(true));
      try {
        const response = await APIService.update(
          "/connection",
          profile?.email,
          payload
        );
        mutate("/user/" + profile?.email);
        toast.success(`${response.data?.message}`);
        dispatch(setLoading(false));
        console.log("CONNECTION RESPONSE ==>>> ", response.data);
      } catch (error: any) {
        console.log("RRO ", error?.message);
        dispatch(setLoading(false));
      }
    } else {
      // Route to messages for start chat 
      console.log("FIRE DOWN RIGHT AWAY !!");
      dispatch(setLoading(true));
      try {
        const resp = await APIService.update("/chat/init", profile?.email, {
          userId: userData?.id,
        });

        mutate("/chat/allChats/" + profile?.email)

        dispatch(setLoading(false));
        console.log("RESP FOR CHAT :: ", resp.data);

        navigate("/dashboard/message");

      } catch (error: any) {
        dispatch(setLoading(false));
        console.log("ERROR FOR CHAT :: ", error?.message);
      }
    }
  };

  return (
    <Box
      py={4}
      px={2}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"stretch"}
    >
      <Typography gutterBottom>
        {isConnected ? profile?.bio?.phone : _obscurePhone(profile?.bio?.phone)}
      </Typography>
      <Typography gutterBottom>
        {isConnected ? profile?.email : _obscureEmail(profile?.email)}
      </Typography>

      <Box
        width={"100%"}
        px={4}
        pt={4}
        display={isClicked && !isConnected ? "none" : "flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <RoundedButton
          startIcon={isConnected ? <Chat /> : <VisibilityOff />}
          variant="outlined"
          fullWidth
          sx={{
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
          }}
          onClick={() => {
            if (!isConnected) {
              setClicked(true);
            } else {
              performAction();
            }
          }}
        >
          {isConnected ? "Start Chat" : "Show Contact"}
        </RoundedButton>
      </Box>
      {isClicked && !isConnected && (
        <Box
          py={2}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"start"}
          alignItems={"stretch"}
        >
          <Box
            p={1}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <img src={coinLogo} alt="" width={56} />
            <Typography variant="h6" fontWeight={900} gutterBottom>
              Coin Alert
            </Typography>
          </Box>

          <Box pb={4}>
            <Typography>
              You will be charged 200 coins from your wallet for this
              connection.
            </Typography>
            <Typography>Do you wish to proceed?</Typography>
          </Box>

          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box flex={1} pr={1}>
              <RoundedButton
                fullWidth
                variant="outlined"
                sx={{
                  borderColor: theme.palette.primary.main,
                  color: theme.palette.primary.main,
                }}
                onClick={() => setOpen(false)}
              >
                Cancel
              </RoundedButton>
            </Box>

            <Box flex={1} pl={1}>
              <RoundedButton
                fullWidth
                variant="contained"
                sx={{
                  bgcolor: theme.palette.primary.main,
                  color: "white",
                }}
                onClick={() => performAction()}
              >
                Proceed
              </RoundedButton>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const ReportUserBody = ({ userData, setOpen }: ContactInfoProps) => {
  const [isChecked, setChecked] = React.useState(false);

  const action = async() => {
    if (isChecked) {
      // Report only. Don't block
      try {
        // const resp = await APIService.post();
      } catch (error) {
        console.log("ERROR::: ", error);
        
      }
    } else {
      // Block this user

    }
  }

  return (
    <Box
      py={4}
      px={2}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"stretch"}
    >
      <Typography gutterBottom variant="body2">
        {`Are you sure you want to block ${userData?.bio?.firstname}? You can choose to only report this user by checking the box below.`}
      </Typography>

      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"start"}
        alignItems={"center"}
      >
        <Checkbox
          value={isChecked}
          onChange={(e, checked) => setChecked(checked)}
        />
        <Typography px={1}>Report only</Typography>
      </Box>

      <Box
        width={"100%"}
        pt={4}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <RoundedButton
          variant="outlined"
          fullWidth
          sx={{
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            mx: 0.5,
          }}
          onClick={() => setOpen(false)}
        >
          {"Cancel"}
        </RoundedButton>
        <RoundedButton
          variant="contained"
          fullWidth
          sx={{
            bgcolor: theme.palette.primary.main,
            color: "white",
            mx: 0.5,
          }}
          onClick={() => setOpen(true)}
        >
          {isChecked ? "Report" : "Block"}
        </RoundedButton>
      </Box>
    </Box>
  );
};
