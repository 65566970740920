import { Box, Skeleton } from "@mui/material";

export default function ChatShimmer() {
  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"start"}
    >
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"start"}
        alignItems={"center"}
      >
        <Skeleton variant="circular" height={48} animation="wave" width={56} />
        <Box width={"100%"}>
          <Skeleton sx={{ mx: 2 }} height={18} animation="wave" width={"75%"} />
          <Skeleton sx={{ mx: 2 }} height={18} animation="wave" width={"50%"} />
        </Box>
      </Box>
    </Box>
  );
}
