import {
  Box,
  Grid,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as React from "react";
// import RoundedButton from "../../../components/button/round_button";
import { useAppSelector } from "../../../utils/hooks/apphook";
import { useNavigate } from "react-router-dom";
import TabSectionPros from "../explore/tab_section";
import TabSectionJobs from "./tab_section";

import RoundedButton from "../../../components/button/round_button";
import { Add } from "@mui/icons-material";
import { BrandedDialog } from "../../../components/dialog";
import PostJob from "../../jobs/post_job";
import notFoundImage from "../../../assets/images/empty.png";
import MyPostedJobCard from "../../../components/jobs/my_job_card";

export default function Jobs(): React.JSX.Element {
  let theme = useTheme();
  const navigate = useNavigate();
  const [currProgress, setCurrProgress] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [deviceType, setDeviceType] = React.useState("mobile");

  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  const profile = useAppSelector((state) => state.auth.profile);
  const postedJobs = useAppSelector((state) => state.jobs.myPostedJobs);
  // const jobs = useAppSelector((state) => state.jobs.jobs);

  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet]);

  console.log("PRofile :: ", postedJobs);

  // const calculateProgress = async () => {};

  React.useEffect(() => {
    if (!profile?.hasProfile) {
      setCurrProgress(10);
    }
  }, []);

  return (
    <Box>
      <BrandedDialog
        open={open}
        setOpen={setOpen}
        title={`Post New Job`}
        body={<PostJob setOpen={setOpen} />}
        width={"100%"}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Box
            px={deviceType === "pc" ? 6 : deviceType === "tablet" ? 4 : 2}
            py={deviceType === "pc" ? 4 : deviceType === "tablet" ? 3 : 0}
            bgcolor={theme.palette.primary.main}
            borderRadius={5}
            color={"white"}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={deviceType === "mobile" ? "center" : "start"}
              alignItems={deviceType === "mobile" ? "center" : "start"}
              pb={deviceType !== "pc" ? 2 : 0}
              py={2}
            >
              <Typography variant="body2" gutterBottom>
                {profile?.accountType === "recruiter" ? "Post your job here. You have a minimum of 5 free jobs posting slot after which, you'll have to pay to post a job." : ""}
              </Typography>

              <Box
                pt={1}
                width={"100%"}
                display={profile?.accountType === "recruiter" ? "flex" : "none"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <RoundedButton
                  variant="contained"
                  startIcon={deviceType === "pc" ? <Add /> : null}
                  sx={{
                    bgcolor: "white",
                    color: theme.palette.primary.main,
                    px: 2,
                    height: 36,
                  }}
                  onClick={() => setOpen(true)}
                >
                  {deviceType === "pc" ? "Post New Job" : "Post Job"}
                </RoundedButton>

                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"end"}
                  alignItems={"center"}
                >
                  {/* <Box
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography fontSize={13} fontWeight={700}>
                      Count
                    </Typography>
                    <Typography>{profile?.jobCount}</Typography>
                  </Box> */}

                  <Box
                    px={1}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography fontSize={13} fontWeight={700}>
                      Jobs
                    </Typography>
                    <Typography>{postedJobs?.length}</Typography>
                  </Box>

                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography fontSize={13} fontWeight={700}>
                      Active
                    </Typography>
                    <Typography>{postedJobs?.filter((element: any) => element['jobStatus'] === "accepting")?.length}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Toolbar />

          <Box height={"100%"}>
            {profile?.accountType === "recruiter" && (
              <>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"start"}
                  alignItems={"start"}
                >
                  <Typography variant="h6" fontWeight={800} gutterBottom>
                    Recently Posted
                  </Typography>
                  {postedJobs.length > 0 ? (
                    <Grid container spacing={2}>
                      {postedJobs?.map((item: any, index: number) => (
                        <Grid key={index} item xs={12} sm={12} md={6} >
                          <MyPostedJobCard data={item} />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      height={320}
                      width={"100%"}
                    >
                      <img src={notFoundImage} alt="" />
                      <Typography fontSize={13} textAlign={"center"}>
                        No jobs posted yet
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Toolbar />
              </>
            )}

            {profile?.accountType !== "recruiter" && (
              <>
                {deviceType === "pc" ? (
                  <TabSectionJobs />
                ) : (
                  <>
                    <TabSectionJobs />
                    <Toolbar />
                  </>
                )}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
