import {
  Avatar,
  Box,
  Chip,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../utils/hooks/apphook";
interface Props {
  formData: any;
  questions: any;
}

export default function ReviewStep({ formData, questions }: Props) {
  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  const [deviceType, setDeviceType] = React.useState("mobile");
  const userProfile = useAppSelector((state) => state.auth.profile);

  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet]);

  return (
    <Box
      p={2}
      display={"flex"}
      flexDirection={"column"}
      justifyContent="start"
      alignItems="start"
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent="start"
        alignItems="center"
      >
        <Box
          borderRadius={48}
          width={deviceType === "pc" ? 96 : deviceType === "tablet" ? 60 : 48}
          height={deviceType === "pc" ? 96 : deviceType === "tablet" ? 60 : 48}
          bgcolor={theme.palette.primary.main}
        >
          <Avatar
            alt="Remy Sharp"
            sx={{
              width:
                deviceType === "pc" ? 94 : deviceType === "tablet" ? 58 : 46,
              height:
                deviceType === "pc" ? 94 : deviceType === "tablet" ? 58 : 46,
            }}
            src={userProfile?.bio?.image}
          />
        </Box>
        <Box
          px={2}
          display={"flex" }
          flexDirection={"column"}
          justifyContent="start"
          alignItems="start"
        >
          <Typography fontSize={deviceType === "pc" ? 21 : deviceType === "tablet" ? 18 : 16} fontWeight={900}>
            {`${userProfile?.bio?.firstname} ${userProfile?.bio?.middlename} ${userProfile?.bio?.lastname}`}
          </Typography>
          <Typography
            color={"grey"}
            fontSize={deviceType === "pc" ? 16 : deviceType === "tablet" ? 14 : 12}
            fontWeight={700}
          >{`${userProfile?.email}`}</Typography>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent="start"
            alignItems="start"
          >
            {userProfile?.skills?.map((item: any, index: number) => {
              return <Chip label={item?.name} sx={{ mr: 1 }} />;
            })}
          </Box>
        </Box>
      </Box>
      <Typography mt={4} fontSize={12}>
        Email Address*
      </Typography>
      <Typography gutterBottom fontSize={14}>
        {formData?.email}
      </Typography>
      <Typography mt={1} fontSize={12}>
        Phone Number*
      </Typography>
      <Typography mb={2} gutterBottom fontSize={14}>
        {formData?.phone}
      </Typography>
      <Divider />
      <Typography mt={2} fontSize={14} fontWeight={700}>
        Resume*
      </Typography>
      <Typography fontSize={12}>
        Be sure to upload your most recent resume
      </Typography>
      <Typography mb={2} gutterBottom fontSize={14}>
        {formData?.resume}
      </Typography>
      <Divider />
      <Typography mt={2} gutterBottom fontSize={14} fontWeight={700}>
        Additional Questions*
      </Typography>
      {questions?.map((item: any, index: number) => {
        return (
          <Box
            pb={1}
            key={index}
            display={"flex"}
            flexDirection={"column"}
            justifyContent="start"
            alignItems="start"
          >
            <Typography fontSize={12}>{item}</Typography>
            <Typography fontSize={14}>
              {index === 0
                ? formData?.answer1
                : index === 1
                ? formData?.answer2
                : index === 2
                ? formData?.answer3
                : index === 3
                ? formData?.answer4
                : index === 4
                ? formData?.answer5
                : index === 5
                ? formData?.answer6
                : index === 6
                ? formData?.answer7
                : index === 7
                ? formData?.answer8
                : index === 8
                ? formData?.answer9
                : formData?.answer10}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}
