import { Box, Grid, Toolbar } from '@mui/material'
import React from 'react'
import ProCard from '../../../components/professional/pro_card'
import ProShimmer from '../../../components/skeleton/pro_card_loader'
import { useLocation } from 'react-router-dom'


const UserConnections: React.FC = () => {
    const location = useLocation();
    const { data } = location?.state;

    console.log("CONNECTIONS ::: ", data);
    

  return (
    <Box>
    <Grid container spacing={2}>
      {data
        ? data?.map((item: any, index: number) => (
            <Grid key={index} item xs={12} sm={12} md={6} lg={4}>
              <ProCard data={item} height={360} />{' '}
            </Grid>
          ))
        : [1, 2, 3]?.map(item => (
            <Grid key={item} item xs={12} sm={12} md={6} lg={4}>
              <ProShimmer />
            </Grid>
          ))}
    </Grid>
    <Toolbar />
  </Box>
  )
}

export default UserConnections