import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../utils/hooks/apphook";
// import { statesCities } from "../../../utils/data/locations";

export interface Props {
  values: any;
  getFieldProps: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  handleChange: any;
}

export default function JobRequirementStep({
  errors,
  getFieldProps,
  values,
  touched,
  setFieldValue,
  handleChange
}: Props) {
  const theme = useTheme();
  const professions = useAppSelector((state) => state.professions.professions);

//   console.log("PROFESSIONS ==>>> ", professions);

  return (
    <React.Fragment>
      {professions && (
        <Box
          py={4}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"start"}
          alignItems={"center"}
        >
          <FormControl
            fullWidth
            error={Boolean(errors.profession && touched.profession)}
          >
            <InputLabel id="demo-simple-select-label">Profession</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              variant="outlined"
              label="Profession"
              type="text"
              size="small"
              required
              fullWidth
              name="profession"
              value={values.profession}
              onChange={(e) => {
                // console.log("PROFESSION VALUE ::: ",  e.target.value);
                
                // handleChange("profession");
                setFieldValue("profession", e.target.value);
                // if (e?.target.value === "none") {
                //   setFieldValue("endate", null);
                // }
              }}
              placeholder="Select Profession"
            >
              {professions?.map(
                (item: any, index: number) => (
                  <MenuItem
                    key={index}
                    sx={{ textTransform: "capitalize" }}
                    value={item?.id}
                  >
                    {item?.name}
                  </MenuItem>
                )
              )}
            </Select>
            <FormHelperText>{errors.profession}</FormHelperText>
          </FormControl>

          <TextField
            variant="outlined"
            label="Job Description"
            size="small"
            fullWidth
            multiline
            minRows={5}
            {...getFieldProps("description")}
            error={Boolean(touched.description && errors.description)}
            helperText={errors.description}
            sx={{ my: 1.5 }}
          />

          <TextField
            variant="outlined"
            label="Minimum Qualification"
            size="small"
            fullWidth
            {...getFieldProps("minQualification")}
            error={Boolean(touched.minQualification && errors.minQualification)}
            helperText={errors.minQualification}
          />
        </Box>
      )}
    </React.Fragment>
  );
}
