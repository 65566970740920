import { Circle, LocationCity, Work } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Container,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { formatDistance } from 'date-fns'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import RoundedButton from '../../components/button/round_button'
import React from 'react'
import { useAppSelector } from '../../utils/hooks/apphook'
import APIService from '../../service'
import CustomContainer from '../../components/container'

interface DateOptions {
  includeSeconds: boolean
  addSuffix: boolean
}

export default function JobInfo () {
  const location = useLocation()
  const { data } = location?.state

  const [isApplied, setApplied] = React.useState(false)
  const [isCountLoaded, setCountLoaded] = React.useState(false)
  const [applicationCount, setApplicationCount] = React.useState(0)
  const [deviceType, setDeviceType] = React.useState('mobile')

  const myAppliedJobs = useAppSelector(state => state.jobs.myJobApplications)
  const profile = useAppSelector(state => state.auth.profile)
  const isAuth = useAppSelector(state => state.auth.isAuth)

  const theme = useTheme()
  const navigate = useNavigate()
  const mobile = useMediaQuery(theme.breakpoints.only('xs'))
  const tablet = useMediaQuery(theme.breakpoints.only('sm'))

  const getApplications = async () => {
    try {
      const resp = await APIService.fetcher(
        '/job/applications/' + profile?.email + '?jobId=' + data?.id
      )
      console.log('zrE ', resp?.docs)

      setCountLoaded(true)
      setApplicationCount(resp?.docs?.length)
    } catch (e: any) {
      console.log(e.toString())
    }
  }

  React.useEffect(() => {
    getApplications()
  }, [])

  React.useEffect(() => {
    if (myAppliedJobs) {
      myAppliedJobs?.forEach((elem: any) => {
        if (elem?.job?._id === data?.id) {
          setApplied(true)
        }
      })
    }
  }, [data?.id, myAppliedJobs])

  React.useEffect(() => {
    if (mobile) {
      setDeviceType('mobile')
    } else if (tablet) {
      setDeviceType('tablet')
    } else {
      setDeviceType('pc')
    }
  }, [mobile, tablet])

  let options: DateOptions = {
    addSuffix: true,
    includeSeconds: true
  }

  console.log("DATA  :: :: :: :: ", data);
  

  return (
    <Box display={'flex'} flexDirection={'column'}>
      {deviceType === 'pc' ? <Toolbar /> : <br />}

      <CustomContainer>
        <Box component={profile?.accountType !== 'recruiter' ? Box : Box}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8}>
              <Typography
                py={2}
                gutterBottom
                variant={deviceType === 'pc' ? 'h4' : 'h5'}
                textTransform={'capitalize'}
              >
                {data?.jobTitle}
              </Typography>
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'start'}
                alignItems={'center'}
                textTransform={'capitalize'}
              >
                <Typography pr={1} fontWeight={500}>
                  {data?.company}
                </Typography>
                <Circle fontSize='small' sx={{ width: 6, height: 6 }} />
                <Typography
                  color={'green'}
                  fontWeight={500}
                  px={1}
                  textTransform={'capitalize'}
                >
                  {` ${formatDistance(
                    Date.parse(`${data?.createdAt}`),
                    new Date(),
                    options
                  )}`}
                </Typography>
                {deviceType === 'pc' && (
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'start'}
                    alignItems={'center'}
                  >
                    <Circle fontSize='small' sx={{ width: 6, height: 6 }} />
                    <Typography px={1} fontWeight={400}>
                      {`${isCountLoaded ? data?.applicants?.length : 'Loading ...'} ${
                        isCountLoaded
                          ? data?.applicants?.length > 1
                            ? 'applicants'
                            : 'applicant'
                          : ''
                      } `}
                    </Typography>
                    {data?.applicants?.length > 0 &&
                      profile?.id === data?.recruiter?._id && (
                        <NavLink
                          to={`/dashboard/jobs/${data?.id}/applications`}
                          style={{ fontSize: 13 }}
                          state={{ data: data, deviceType: deviceType }}
                        >
                          {' '}
                          View all
                        </NavLink>
                      )}
                  </Box>
                )}
              </Box>
              {deviceType !== 'pc' && (
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'start'}
                  alignItems={'center'}
                >
                  <Typography pr={1} fontWeight={400}>
                    {`${isCountLoaded ? data?.applicants?.length : 'Loading ...'} ${
                      isCountLoaded
                        ? data?.applicants?.length > 1
                          ? 'applicants'
                          : 'applicant'
                        : ''
                    } `}
                  </Typography>
                  {data?.applicants?.length > 0 && profile?.id === data?.recruiter?._id && (
                    <NavLink
                      to={`/dashboard/jobs/${data?.id}/applications`}
                      style={{ fontSize: 13 }}
                      state={{ data: data, deviceType: deviceType }}
                    >
                      {' '}
                      View all
                    </NavLink>
                  )}
                </Box>
              )}
              <Box
                pt={2}
                pb={1}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'start'}
                alignItems={'center'}
                textTransform={'capitalize'}
              >
                <Work />
                <Typography px={2} fontWeight={400}>
                  {`${data?.jobType} (${data?.workplaceType})`}
                </Typography>
              </Box>
              <Box
                pb={1}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'start'}
                alignItems={'center'}
                textTransform={'capitalize'}
              >
                <LocationCity />
                <Typography px={2} fontWeight={400}>
                  {`${data?.jobLocation?.city},  ${data?.jobLocation?.state}`}
                </Typography>
              </Box>

              <Box
                pb={2}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'start'}
                alignItems={'start'}
                textTransform={'capitalize'}
              >
                <Typography pr={1} fontWeight={600}>
                  {`Minimum qualification: `}
                </Typography>
                <Typography
                  flex={1}
                >{`${data?.minimumQualification}`}</Typography>
              </Box>

              <Box
                pb={2}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'start'}
                alignItems={'start'}
              >
                <Typography
                  pr={1}
                  textTransform={'capitalize'}
                  fontWeight={600}
                >
                  {`About this job: `}
                </Typography>
                <Typography flex={1} textTransform={'revert'}>
                  {`${data?.description}`}
                </Typography>
              </Box>

              <Box
                pb={2}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'start'}
                alignItems={'start'}
              >
                <Typography
                  pr={1}
                  textTransform={'capitalize'}
                  fontWeight={600}
                >
                  {`Requirements: `}
                </Typography>
                <ul>
                  {data?.requirements?.map((item: any, index: number) => (
                    <li key={index}> {item} </li>
                  ))}
                </ul>
              </Box>

              <Box
                pb={4}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'start'}
                alignItems={'center'}
              >
                <IconButton>
                  <Avatar
                    alt={data?.recruiter?.bio?.firstname}
                    src={data?.recruiter?.bio?.image}
                  />
                </IconButton>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'start'}
                  alignItems={'start'}
                  textTransform={'capitalize'}
                >
                  <Typography fontWeight={600} variant='body1'>
                    {data?.recruiter?.bio?.firstname + ' ' + data?.recruiter?.bio?.lastname}
                  </Typography>
                  <Typography fontWeight={600} variant='body2'>
                    {`Posted on ${new Date(
                      Date.parse('' + data?.createdAt)
                    ).toLocaleDateString()}`}
                  </Typography>
                </Box>
              </Box>

              <Box
                pb={2}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'start'}
                alignItems={'start'}
              >
                <RoundedButton
                  disabled={isApplied || data?.jobStatus !== 'accepting'}
                  sx={{
                    height: 48,
                    width: 128,
                    color: 'white',
                    backgroundColor: theme.palette.primary.main,
                    display:
                      profile?.accountType === 'recruiter' ? 'none' : 'block'
                  }}
                  onClick={() => {
                    if (!isAuth) {
                      navigate('/login')
                    }
                  }}
                >
                  {isApplied
                    ? 'Applied'
                    : data?.jobStatus !== 'accepting'
                    ? data?.jobStatus
                    : 'Apply now'}
                </RoundedButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CustomContainer>

      <Toolbar />
    </Box>
  )
}
