import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../utils/hooks/apphook";
import { statesCities } from "../../../utils/data/locations";

export interface Props {
  values: any;
  getFieldProps: any;
  errors: any;
  touched: any;
  setFieldValue: any;
}

export default function JobInfoStep({
  errors,
  getFieldProps,
  values,
  touched,
  setFieldValue,
}: Props) {
  const theme = useTheme();
  const [selectedCities, setSelectedCities] = React.useState<string[]>([]);
  const userProfile = useAppSelector((state) => state.auth.profile);

  return (
    <Box
      py={4}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"center"}
    >
      <TextField
        variant="outlined"
        label="Title"
        size="small"
        fullWidth
        {...getFieldProps("jobTitle")}
        error={Boolean(touched.jobTitle && errors.jobTitle)}
        helperText={errors.jobTitle}
      />

      <TextField
        variant="outlined"
        label="Company"
        size="small"
        fullWidth
        {...getFieldProps("company")}
        error={Boolean(touched.company && errors.company)}
        helperText={errors.company}
        sx={{ my: 1.5 }}
      />

      <FormControl fullWidth error={Boolean(errors.jobType && touched.jobType)}>
        <InputLabel id="demo-simple-select-label">Job Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          variant="outlined"
          label="Job Type"
          type="text"
          size="small"
          required
          fullWidth
          name="jobType"
          value={values.jobType}
          onChange={(e) => {
            // handleChange("degree");
            setFieldValue("jobType", e.target.value);
            if (e?.target.value === "none") {
              setFieldValue("endate", null);
            }
          }}
          placeholder="Select Job Type"
        >
          {[
            "full-time",
            "part-time",
            "contract",
            "volunteer",
          ].map((item: string, index: number) => (
            <MenuItem
              key={index}
              sx={{ textTransform: "capitalize" }}
              value={item}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{errors.jobType}</FormHelperText>
      </FormControl>

      <FormControl fullWidth error={Boolean(errors.workplaceType && touched.workplaceType)}  sx={{ my: 1.5 }}>
        <InputLabel id="demo-simple-select-label">Workplaace Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          variant="outlined"
          label="Job Type"
          type="text"
          size="small"
          required
          fullWidth
          name="workplaceType"
          value={values.workplaceType}
          onChange={(e) => {
            // handleChange("degree");
            setFieldValue("workplaceType", e.target.value);
            if (e?.target.value === "none") {
              setFieldValue("endate", null);
            }
          }}
          placeholder="Select Workplace Type"
        >
          {[
            "on-site",
            "remote",
            "hybrid",
          ].map((item: string, index: number) => (
            <MenuItem
              key={index}
              sx={{ textTransform: "capitalize" }}
              value={item}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{errors.workplaceType}</FormHelperText>
      </FormControl>

      <FormControl fullWidth error={Boolean(errors.country && touched.country)}>
        <InputLabel id="demo-simple-select-label">Country</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          variant="outlined"
          label="Country"
          type="text"
          size="small"
          required
          fullWidth
          name="country"
          value={values.country}
          onChange={(e) => {
            // handleChange("degree");
            setFieldValue("country", e.target.value);
            if (e?.target.value === "none") {
              setFieldValue("endate", null);
            }
          }}
          placeholder="Select country"
        >
          {[
            "nigeria",
          ].map((item: string, index: number) => (
            <MenuItem
              key={index}
              sx={{ textTransform: "capitalize" }}
              value={item}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{errors.country}</FormHelperText>
      </FormControl>

      <FormControl fullWidth error={Boolean(errors.state && touched.state)}  sx={{ my: 1.5 }}>
        <InputLabel id="demo-simple-select-label">State</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          variant="outlined"
          label="State"
          type="text"
          size="small"
          required
          fullWidth
          name="state"
          value={values.state}
          onChange={(e) => {
            // handleChange("degree");
            setFieldValue("state", e.target.value);
            const filtered = statesCities.filter((item) => item.name.toLowerCase() === e.target.value?.toLowerCase());
            setSelectedCities(filtered[0].cities)
            // if (e?.target.value === "none") {
            //   setFieldValue("endate", null);
            // }
          }}
          placeholder="Select state"
        >
          {statesCities.map((item, index: number) => (
            <MenuItem
              key={index}
              sx={{ textTransform: "capitalize" }}
              value={item.name.toLowerCase()}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{errors.state}</FormHelperText>
      </FormControl>

      <FormControl fullWidth error={Boolean(errors.city && touched.city)}>
        <InputLabel id="demo-simple-select-label">City</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          variant="outlined"
          label="City"
          type="text"
          size="small"
          required
          fullWidth
          name="city"
          value={values.city}
          onChange={(e) => {
            // handleChange("degree");
            setFieldValue("city", e.target.value);
            
          }}
          placeholder="Select city"
        >
          {selectedCities.map((item: string, index: number) => (
            <MenuItem
              key={index}
              sx={{ textTransform: "capitalize" }}
              value={item}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{errors.city}</FormHelperText>
      </FormControl>

    </Box>
  );
}
