import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";

import { Outlet, useLocation, useNavigate } from "react-router-dom";

import {
  Avatar,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import RoundedButton from "../../components/button/round_button";
import { useAppSelector } from "../../utils/hooks/apphook";

import CustomContainer from "../../components/container";
import ProfessionalNavbar, {
  ProfessionalNavbarMobile,
} from "../navbars/professional_navbar";
import RecruiterNavbar, {
  RecruiterNavbarMobile,
} from "../navbars/recruiter_navbar";
import LinearProgressLabel from "../../components/progress/linear_progress";
import coins from "../../assets/images/coin_gold.png";
import CustomizedDialog from "../../components/dialog";
import { coinsList } from "../../utils/data/coins";
import { pstk } from "../../utils/constants";
import { usePaystackPayment } from "react-paystack";
import APIService from "../../service";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/reducers/loader";
import toast from "react-hot-toast";
import { setProfile } from "../../redux/reducers/auth";
import RoundedButtonOutlined from "../../components/button/rounded_button_outlined";

interface Props {
  setOpen: any;
}

export default function DashbboardLayout() {
  const theme = useTheme();
  // const navigate = useNavigate();
  const currlocation = useLocation();
  const [currProgress, setCurrProgress] = React.useState(0);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  // const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [deviceType, setDeviceType] = React.useState("mobile");

  // const dispatch = useAppDispatch();
  const isAuth = useAppSelector((state) => state.auth.isAuth);
  const userProfile = useAppSelector((state) => state.auth.profile);
  const currentChatGuest = useAppSelector(
    (state) => state.messages.currentChatGuest
  );

  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet]);

  console.log("CURRENT GUEST DATA :: ", currentChatGuest);

  // const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
  //   null
  // );

  // const logout = () => {
  //   dispatch(setLoading(true));
  //   try {
  //     setTimeout(() => {
  //       localStorage.clear();
  //       dispatch(setAuth(false));
  //       dispatch(setProfile(null));
  //       dispatch(setLoading(false));
  //       toast.success("Logged out successfully");
  //       navigate("/");
  //     }, 2000);
  //   } catch (error) {
  //     console.log(error);
  //     dispatch(setLoading(false));
  //   }
  // };

  React.useEffect(() => {
    if (currlocation.pathname === "/dashboard/explore") {
      setSelectedIndex(0);
    } else if (currlocation.pathname === "/dashboard/jobs") {
      setSelectedIndex(1);
    } else if (currlocation.pathname === "/dashboard/message") {
      setSelectedIndex(2);
    } else if (currlocation.pathname === "/dashboard/account") {
      setSelectedIndex(5);
    }
  }, [currlocation]);

  // const handlleItemClick = (
  //   e: React.MouseEvent<HTMLElement>,
  //   index: number,
  //   route: string
  // ) => {
  //   setSelectedIndex(index);
  //   navigate(route);
  // };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <CustomizedDialog
        open={openDialog}
        setOpen={setOpenDialog}
        body={<TopupContent setOpen={setOpenDialog} />}
        title="Topup Wallet "
      />
      {/* {deviceType !== "pc" && (
        <AppBar elevation={0.0}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ml: -0.75,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon color="primary" />
            </IconButton>
            <img
              src={brand}
              alt=""
              width={128}
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            />
            <Box sx={{ flexGrow: 1 }} />
            <img
              src={customMenuIcon}
              alt=""
              width={32}
              style={{ color: "white" }}
            />
          </Toolbar>
        </AppBar>
      )} */}

      {isAuth &&
      userProfile &&
      userProfile?.accountType?.toLowerCase() !== "recruiter" ? (
        deviceType === "pc" ? (
          <ProfessionalNavbar />
        ) : (
          <ProfessionalNavbarMobile />
        )
      ) : deviceType === "pc" ? (
        <RecruiterNavbar />
      ) : (
        <RecruiterNavbarMobile />
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: deviceType === "pc" ? 3 : 2,
          px: deviceType === "pc" ? 3 : 0,
        }}
      >
        {<Toolbar />}
        <CustomContainer>
          <Grid container spacing={deviceType === "pc" ? 2 : 0}>
            <Grid
              item
              xs={12}
              sm={12}
              md={currlocation.pathname === "/dashboard/account" ? 12 : 9}
            >
              <Outlet />
            </Grid>
            <Grid
              item
              md={3}
              sx={{
                display: {
                  sm: "none",
                  xs: "none",
                  md:
                    currlocation.pathname === "/dashboard/account"
                      ? "none"
                      : "block",
                },
              }}
              position={"relative"}
            >
              <Box position={"fixed"} minWidth={326}>
                <Card sx={{ borderRadius: 2 }}>
                  <Box
                    px={4}
                    pt={4}
                    pb={2}
                    bgcolor={"#fff"}
                    color={"white"}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Box
                      borderRadius={36}
                      width={72}
                      height={72}
                      bgcolor={theme.palette.primary.main}
                    >
                      <Avatar
                        alt="Remy Sharp"
                        sx={{ width: 70, height: 70 }}
                        src={
                          currlocation.pathname.startsWith("/dashboard/message")
                            ? currentChatGuest?.bio?.image ?? ""
                            : userProfile?.bio?.image
                        }
                      />
                    </Box>
                    <Typography
                      mt={2}
                      color={theme.palette.primary.main}
                      fontWeight={900}
                    >
                      {`${
                        currlocation.pathname.startsWith("/dashboard/message")
                          ? currentChatGuest?.bio?.firstname ?? ""
                          : userProfile?.bio?.firstname
                      } ${
                        currlocation.pathname.startsWith("/dashboard/message")
                          ? currentChatGuest?.bio?.middlename ?? ""
                          : userProfile?.bio?.middlename
                      } ${
                        currlocation.pathname.startsWith("/dashboard/message")
                          ? currentChatGuest?.bio?.lastname ?? ""
                          : userProfile?.bio?.lastname
                      }`}
                    </Typography>
                    <Typography gutterBottom variant="body2" color={"black"}>
                      {`${
                        currlocation.pathname.startsWith("/dashboard/message")
                          ? currentChatGuest?.email ?? ""
                          : userProfile?.email
                      }`}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      textTransform={"uppercase"}
                      color={"primary"}
                    >
                      {`${
                        currlocation.pathname.startsWith("/dashboard/message")
                          ? currentChatGuest?.accountType ?? ""
                          : userProfile?.accountType
                      }`}
                    </Typography>
                  </Box>
                  <Box
                    bgcolor={"#0066f51d"}
                    py={3}
                    px={2}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={
                      currlocation.pathname.startsWith("/dashboard/message")
                        ? "center"
                        : "start"
                    }
                    alignItems={
                      currlocation.pathname.startsWith("/dashboard/message")
                        ? "center"
                        : "start"
                    }
                  >
                    {currlocation.pathname.startsWith("/dashboard/message") ? (
                      <RoundedButtonOutlined
                        variant="outlined"
                        sx={{
                          borderColor: theme.palette.primary.main,
                          color: theme.palette.primary.main,
                          height: 40,
                          px: 4,
                        }}
                      >
                        View Profile
                      </RoundedButtonOutlined>
                    ) : (
                      <>
                        <Typography
                          sx={{
                            color: "black",
                            fontWeight: 600,
                            fontSize: 12,
                            textAlign: "start",
                          }}
                        >
                          Profile Completeness:
                        </Typography>
                        <LinearProgressLabel progress={currProgress} />
                      </>
                    )}
                  </Box>
                  <Box
                    p={2}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"start"}
                    alignItems={"stretch"}
                  >
                    <Box
                      py={1}
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography>Connections</Typography>
                      <Typography>
                        {currlocation.pathname.startsWith("/dashboard/message")
                          ? currentChatGuest?.connections?.length ?? 0
                          : userProfile?.connections?.length}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      py={1}
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography>
                        {currlocation.pathname.startsWith("/dashboard/message")
                          ? "Joined since"
                          : userProfile?.accountType === "recruiter"
                          ? "Posted Jobs"
                          : "Saved Jobs"}
                      </Typography>
                      <Typography>
                        {currlocation.pathname.startsWith("/dashboard/message")
                          ? new Date(currentChatGuest?.createdAt).toLocaleDateString("en-GB") ?? "" : userProfile?.accountType === "recruiter"
                          ? userProfile?.jobCount
                          : userProfile?.savedJobs?.length}
                      </Typography>
                    </Box>
                  </Box>
                </Card>

                {!currlocation.pathname.startsWith("/dashboard/message") && (
                  <Card sx={{ mt: 1, borderRadius: 2 }}>
                    <Box
                      py={4}
                      px={2}
                      width={"100%"}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"start"}
                      alignItems={"start"}
                    >
                      <Typography
                        variant="body2"
                        fontWeight={600}
                        textAlign={"center"}
                        textTransform={"uppercase"}
                        color={"primary"}
                        gutterBottom={true}
                      >
                        My Wallet
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6}>
                          <img src={coins} alt="" width={"40%"} />
                          <Typography>{`${userProfile?.wallet?.balance} coins`}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <Box>
                            <RoundedButton
                              sx={{
                                bgcolor: theme.palette.primary.main,
                                color: "white",
                              }}
                              fullWidth
                              onClick={() => setOpenDialog(true)}
                            >
                              Topup Wallet
                            </RoundedButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                )}
              </Box>
            </Grid>
          </Grid>
        </CustomContainer>
      </Box>
    </Box>
  );
}

const TopupContent = ({ setOpen }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const userProfile = useAppSelector((state) => state.auth.profile);
  const [selected, setSelected] = React.useState("");
  const [amount, setAmount] = React.useState(0);

  const channels: any[] = ["card"];
  const tn = new Date().getTime().toString();

  const config = {
    reference: tn,
    email: userProfile?.email,
    amount: amount * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: pstk,
    channels: channels,
    firstname: userProfile?.bio?.firstname,
    lastname: userProfile?.bio?.lastname,
    phone: userProfile?.bio?.phone?.replace("+234", "0"),
  };

  const initializePayment = usePaystackPayment(config);

  const handleChange = (e: any) => {
    const { value } = e.target;

    setSelected(value);
    const filtered = coinsList.filter((item) => item.value === value);
    setAmount(filtered[0].amount);
  };

  const onPaymentSuccess = () => {
    // Implementation for whatever you want to do with reference and after success call.
    // Now trigger API
    dispatch(setLoading(true));
    const payload = {
      amount: amount,
      value: selected,
      reference: tn,
      userId: userProfile?.id,
      type: "fund_wallet",
      status: "success",
      summary: `Fund wallet with value of ${selected} coins`,
    };

    APIService.update("/wallet/topup", userProfile?.email, payload)
      .then((res) => {
        dispatch(setLoading(false));
        console.log("EROR ==>>> ", res);
        dispatch(setProfile(res.data?.data));
        toast.success(`${res?.data?.message}`);
      })
      .catch((error) => {
        console.log("ERROR  ==> ", error);
        dispatch(setLoading(false));
      });
  };

  const onPaymentClose = () => {
    // Implementation for whatever you want to do when the Paystack dialog is closed.
    console.log("closed");
  };

  const triggerForm = () => {
    setOpen(false);
    initializePayment(onPaymentSuccess, onPaymentClose);
  };

  return (
    <Box
      p={2}
      minWidth={320}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"start"}
    >
      <FormControl fullWidth sx={{ mb: 4 }}>
        <InputLabel id="demo-simple-select-label">Coins Amount</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          variant="outlined"
          label="Degree"
          type="text"
          size="small"
          required
          fullWidth
          name="selected"
          value={selected}
          onChange={handleChange}
          placeholder="Select coins here"
        >
          {coinsList.map((item, index: number) => (
            <MenuItem
              key={index}
              sx={{ textTransform: "capitalize" }}
              value={item.value}
            >
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        value={amount}
        disabled
        variant="outlined"
        label="Amount"
        type="number"
        size="small"
        required
        fullWidth
        name="amount"
      />

      <RoundedButton
        disabled={!amount}
        fullWidth
        onClick={() => triggerForm()}
        variant="outlined"
        sx={{ borderColor: theme.palette.primary.main, mt: 4 }}
      >
        Continue
      </RoundedButton>
    </Box>
  );
};
