import * as React from "react";
// import { styled } from "@mui/material/styles";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, useMediaQuery, useTheme } from "@mui/material";

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export interface CustomDialogProps {
  setOpen: any;
  open: boolean;
  body: any;
  title: string;
  width?: string | number;
}

export function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ color: "white" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function CustomizedDialog(props: CustomDialogProps) {
  const { open, setOpen, body, title } = props;

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        keepMounted
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {title}
        </BootstrapDialogTitle>
        {body}
      </Dialog>
    </div>
  );
}

export function BrandedDialog(props: CustomDialogProps) {
  const { open, setOpen, body, title, width = 325 } = props;
  const theme = useTheme();
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("sm");

  const handleClose = () => {
    setOpen(false);
  };

  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.only("md"));
  const lg = useMediaQuery(theme.breakpoints.only("lg"));
  const xl = useMediaQuery(theme.breakpoints.only("xl"));

  React.useEffect(() => {
    if (sm) {
      setMaxWidth("sm");
    } else if (md) {
      setMaxWidth("sm");
    } else if (lg) {
      setMaxWidth("sm");
    } else if (xl) {
      setMaxWidth("sm");
    }
  }, [lg, md, sm, xl]);

  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        maxWidth={maxWidth}
        open={open}
        keepMounted
        fullWidth={true}
        sx={{ borderRadius: 100 }}
      >
        <Box
          borderRadius={10}
          width={"100%"}
          minWidth={width}
          position={"relative"}
        >
          <Box bgcolor={theme.palette.primary.main} color={"white"}>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              {title}
            </BootstrapDialogTitle>
          </Box>
          {body}
        </Box>
      </Dialog>
    </React.Fragment>
  );
}

export function InfoDialog(props: CustomDialogProps) {
  const { open, setOpen, body, title, width = 325 } = props;
  const theme = useTheme();
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("xs");

  const handleClose = () => {
    setOpen(false);
  };

  // const sm = useMediaQuery(theme.breakpoints.down("sm"));
  // const md = useMediaQuery(theme.breakpoints.only("md"));
  // const lg = useMediaQuery(theme.breakpoints.only("lg"));
  // const xl = useMediaQuery(theme.breakpoints.only("xl"));

  // React.useEffect(() => {
  //   if (sm) {
  //     setMaxWidth("sm");
  //   } else if (md) {
  //     setMaxWidth("md");
  //   } else if (lg) {
  //     setMaxWidth("md");
  //   } else if (xl) {
  //     setMaxWidth("md");
  //   }
  // }, [lg, md, sm, xl]);

  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        maxWidth={maxWidth}
        open={open}
        keepMounted
        fullWidth={true}
        sx={{ borderRadius: 100 }}
      >
        <Box
          borderRadius={10}
          width={"100%"}
          minWidth={width}
          position={"relative"}
        >
          <Box bgcolor={theme.palette.primary.main} color={"white"}>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              {title}
            </BootstrapDialogTitle>
          </Box>
          {body}
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
