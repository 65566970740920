import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Grid, Toolbar, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../utils/hooks/apphook";
import useJobApplications from "../../utils/hooks/useJobApplications";
import ApplicantShimmer from "../../components/skeleton/applicant_shimmer";
import ApplicantCard from "../../components/jobs/applicant_card";

export default function JobApplicants() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data, deviceType } = location?.state;
  const profile = useAppSelector((state) => state.auth.profile);

  const {
    data: applicantsData,
    loading,
  } = useJobApplications(data?.id);

  console.log("APPLIC :: ", applicantsData);
  

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"start"}
    >
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"stretch"}
        alignItems={"center"}
      >
        <Button
          startIcon={<ArrowBack />}
          sx={{ textTransform: "capitalize" }}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <Box flex={1} py={2}>
          <Typography
            variant={deviceType === "pc" ? "h5" : "body1"}
            fontWeight={700}
            textAlign={"center"}
          >
            {`Job Applicants for ${data?.jobTitle}`}
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2} >
        {
            loading ? [1,2,3,].map((item: number, index: number) => (<Grid item key={index} xs={12} sm={12} md={6} lg={4} >
                <ApplicantShimmer />
            </Grid>)) : applicantsData?.docs?.map((item: any, index: number) => (<Grid key={index} item xs={12} sm={12} md={6} lg={4} >
                <ApplicantCard data={item} />
            </Grid>))
        }
      </Grid>
      <Toolbar />
    </Box>
  );
}
