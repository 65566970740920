import * as React from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../utils/hooks/apphook";
import RoundedButton from "../../../../components/button/round_button";
import theme from "../../../../assets/theme/Theme";
import { PersonalProfileProps } from ".";
import * as Yup from "yup";
import {
  storage,
  uploadBytesResumable,
  getDownloadURL,
} from "../../../../utils/firebase-config";
import { ref } from "firebase/storage";
import APIService from "../../../../service";
import { toast } from "react-hot-toast";
import { setLoading } from "../../../../redux/reducers/loader";
import relationships from "../../../../utils/data/relationship";

interface InitialState {
  name: string;
  email: string;
  phone: string;
  address: string;
  relationship: string;
}

export default function Guarantor({ selectedFile }: PersonalProfileProps) {
  const profile = useAppSelector((state) => state.auth.profile);
  const dispatch = useAppDispatch();

  const guarantorSchema = Yup.object().shape({
    name: Yup.string().required("Gurantor's name is required"),
    email: Yup.string()
      .email("Email address is invalid")
      .required("Email address is required"),
    address: Yup.string().required("address is required"),
    phone: Yup.string()
      .min(10, "Phone number format is incorrect")
      .max(14, "Phone number format is incorrect")
      .required("Phone number required"),
    relationship: Yup.string().required("Relationship type is required"),
  });

  const initialState: InitialState = {
    name: profile?.guarantor?.name ?? "",
    email: profile?.guarantor?.email ?? "",
    phone: profile?.guarantor?.phone ?? "",
    address: profile?.guarantor?.address ?? "",
    relationship: profile?.guarantor?.relationship ?? "",
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: guarantorSchema,
    onSubmit: async () => {
      dispatch(setLoading(true));
      if (selectedFile) {
        // Upload file to firebase first

        try {
          //First save to cloud storage here
          let storageRef = ref(storage, "photos/" + values.email);
          let uploadTask = uploadBytesResumable(storageRef, selectedFile);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const uprogress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              // setProgress(uprogress);
            },
            (error) => {
              // setIsUploading(false);
              console.log(error);
              // enqueueSnackbar(`${error.message}`, { variant: "error" });
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then(
                async (downloadURL) => {
                  const payload = {
                    bio: {
                      ...profile?.bio,
                      image: downloadURL,
                    },
                    guarantor: {
                      ...values,
                    },
                  };

                  try {
                    const res = await APIService.update(
                      "/updateuser",
                      profile.email,
                      {
                        ...payload,
                      }
                    );

                    console.log("RESP HERE >>> ", `${res.data}`);
                    dispatch(setLoading(false));

                    toast.success("Operation successful");
                  } catch (error: any) {
                    dispatch(setLoading(false));
                    console.log(
                      "ERROR HERE >>> ",
                      `${
                        error?.response?.data?.message ||
                        error?.message ||
                        "Something went wrong, try again."
                      }`
                    );
                  }
                }
              );
            }
          );
        } catch (error: any) {
          dispatch(setLoading(false));
          console.log(
            "ERROR HERE >>> ",
            `${
              error?.response?.data?.message ||
              error?.message ||
              "Something went wrong, try again."
            }`
          );
        }
      } else {
        // Update profile direct
        const payload = {
          guarantor: {
            ...values,
          },
        };

        try {
          const res = await APIService.update("/updateuser", profile?.email, {
            ...payload,
          });

          console.log("RESP HIA >>> ", `${{...res.data}}`);
          dispatch(setLoading(false));

          toast.success("Operation successful");
        } catch (error: any) {
          dispatch(setLoading(false));
          console.log(
            "ERROR HERE >>> ",
            `${
              error?.response?.data?.message ||
              error?.message ||
              "Something went wrong, try again."
            }`
          );
        }
      }
    },
  });

  const {
    handleSubmit,
    getFieldProps,
    values,
    errors,
    touched,
    setFieldValue,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        style={{ width: "100%" }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"start"}
          alignItems={"stretch"}
        >
          <Grid
            container
            spacing={2}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                variant="outlined"
                type="text"
                fullWidth
                required
                label="Full Name"
                size="small"
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                variant="outlined"
                type="email"
                fullWidth
                required
                label="Email Address"
                size="small"
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Grid>
          </Grid>

          <br />

          <Grid
            container
            spacing={2}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                variant="outlined"
                fullWidth
                required
                error={Boolean(touched.phone && errors.phone)}
              >
                <PhoneInput
                  country={"ng"}
                  value={values.phone}
                  disableDropdown={true}
                  onChange={(phone) => {
                    const regex = /^[789]/;
                    let formatPhone = phone?.startsWith("0")
                      ? "234" + phone?.slice(1)
                      : regex.test(phone)
                      ? "234" + phone
                      : phone;
                    setFieldValue("phone", formatPhone);
                    // console.log("CHECKING :: ", formatPhone);
                    console.log("VP :: ", values.phone);
                  }}
                  disableCountryCode={true}
                  placeholder="Enter phone number"
                  inputProps={{
                    required: true,
                  }}
                  inputStyle={{
                    width: "100%",
                    border: "1px solid",
                    height: 41,
                  }}
                />
                <FormHelperText>{touched.phone && errors.phone}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                fullWidth
                size="small"
                required
                error={Boolean(touched.relationship && errors.relationship)}
              >
                <InputLabel id="demo-simple-select-label">
                  Relationship
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Relationship"
                  {...getFieldProps("relationship")}
                  error={Boolean(touched.relationship && errors.relationship)}
                >
                  {relationships.map((elem, index: number) => (
                    <MenuItem key={index} value={`${elem}`.toLowerCase()}>
                      {elem}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {touched.relationship && errors.relationship}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <br />

          <Box width={"100%"}>
            <TextField
              variant="outlined"
              type="text"
              fullWidth
              required
              label="Address"
              size="small"
              {...getFieldProps("address")}
              error={Boolean(touched.address && errors.address)}
              helperText={touched.address && errors.address}
            />
          </Box>
          <br />

          <RoundedButton
            variant="contained"
            type="submit"
            sx={{ bgcolor: theme.palette.primary.main, color: "white" }}
          >
            Save Guarantor
          </RoundedButton>
        </Box>
      </Form>
    </FormikProvider>
  );
}
