import React from "react";
import {
  Box,
  Chip,
  IconButton,
  List,
  ListItem,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { Telegram } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../utils/hooks/apphook";
import io from "socket.io-client";
import APIService from "../../service";
import ChatShimmer from "../../components/skeleton/chat_shimmer";
import ScrollableFeed from "react-scrollable-feed";
import { color } from "framer-motion";
import { baseURL } from "../../utils/constants";

interface Props {
  deviceType: string;
}

// const ENDPOINT = "http://192.168.1.35:8082"; // "https://talk-a-tive.herokuapp.com"; -> After deployment
var socket: any, selectedChatCompare: any;

const ChatBox = ({ deviceType }: Props) => {
  const theme = useTheme();
  const [typing, setTyping] = React.useState(false);
  const [messages, setMessages] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [istyping, setIsTyping] = React.useState(false);
  const [newMessage, setNewMessage] = React.useState("");
  const [socketConnected, setSocketConnected] = React.useState(false);

  const selectedConversation = useAppSelector(
    (state) => state.messages.selectedConversation
  );

  const profile = useAppSelector((state) => state.auth.profile);

  React.useEffect(() => {
    socket = io(baseURL);
    socket.emit("setup", profile);
    socket.on("userConnected", () => setSocketConnected(true));
    socket.on("typing", () => setIsTyping(true));
    socket.on("stop typing", () => setIsTyping(false));
  }, [profile]);

  React.useEffect(() => {
    fetchMessages();

    selectedChatCompare = selectedConversation;
    // eslint-disable-next-line
  }, [selectedConversation]);

  React.useEffect(() => {
    socket.on("message recieved", (newMessageRecieved: any) => {
      console.log("LOGGING RECEIVED MSG :: ", newMessageRecieved);

      if (
        !selectedChatCompare || // if chat is not selected or doesn't match current chat
        selectedChatCompare.id !== newMessageRecieved.chat.id
      ) {
        // if (!notification.includes(newMessageRecieved)) {
        //   setNotification([newMessageRecieved, ...notification]);
        // //   setFetchAgain(!fetchAgain);
        // }
      } else {
        let currMessages = messages;
        // currMessages.push(newMessageRecieved);
        setMessages([...currMessages, newMessageRecieved]);
      }
    });
  }, [messages]);

  const fetchMessages = async () => {
    if (!selectedConversation) return;

    try {
      setLoading(true);

      const response = await APIService.fetcher(
        `/chat/messages/${profile?.email}/${selectedConversation?.id}`
      );
      console.log("CURRENT CHAT MESSAGES ::: ", response);

      setMessages(response);
      setLoading(false);

      socket.emit("join chat", selectedConversation.id);
    } catch (error) {
      console.log("ERRO OCCURRED N:: ", error);
    }
  };

  const sendMessage = async (event: any) => {
    if (newMessage) {
      socket.emit("stop typing", selectedConversation.id);
      try {
        setNewMessage("");
        const resp = await APIService.post(
          "/chat/message/post/" + profile?.email,
          {
            content: newMessage,
            chatId: selectedConversation?.id,
          }
        );

        console.log("SEND MESAGE RESPONE ::: ", resp.data);

        socket.emit("new message", resp.data);
        setMessages([...messages, resp.data]);
      } catch (error) {
        console.log("EOR SEND MSG ::: ", error);
      }
    }
  };

  const typingHandler = (e: any) => {
    setNewMessage(e.target.value);

    if (!socketConnected) return;

    if (!typing) {
      // setTyping(true);
      socket.emit("typing", selectedConversation.id);
    }

    let lastTypingTime = new Date().getTime();
    var timerLength = 2000;

    setTimeout(() => {
      var timeNow = new Date().getTime();
      var timeDiff = timeNow - lastTypingTime;

      if (timeDiff >= timerLength && typing) {
        socket.emit("stop typing", selectedConversation.id);
        // setTyping(false);
      }
    }, timerLength);
  };

  
  

  return (
    <Box
      width={"100%"}
      height={deviceType === "pc" ? "100%" : "82vh"}
      display={selectedConversation ? "flex" : "none"}
      //   position={"relative"}
    >
      {loading ? (
        <List sx={{ width: "100%" }}>
          {[1, 2, 3, 4, 5].map((ele: any, pos: number) => (
            <ListItem divider key={pos}>
              <ChatShimmer />
            </ListItem>
          ))}
        </List>
      ) : (
        <Box
          flex={1}
          sx={{ overflowY: "auto" }}
          zIndex={10}
          width={"100%"}
          p={deviceType === "pc" ? 2 : 0}
        >
          <ScrollableFeed>
            {messages?.map((item, i) => (
              <Box
                key={i}
                m={1}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={
                  item?.sender?.id === profile?.id ? "end" : "start"
                }
              >
                <Box
                  px={2}
                  py={1}
                  borderRadius={2}
                  maxWidth={
                    deviceType === "pc"
                      ? "75%"
                      : deviceType === "tablet"
                      ? "80%"
                      : "86%"
                  }
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"end"}
                  alignItems={"start"}
                  bgcolor={
                    item?.sender?.id === profile?.id
                      ? theme.palette.primary.main
                      : theme.palette.success.main
                  }
                  color={"white"}
                >
                  <Typography variant="body2">{item?.content}</Typography>
                </Box>
              </Box>
            ))}
            <Toolbar />
          </ScrollableFeed>
        </Box>
      )}
      <Box
        width={ deviceType === "pc" ? "42vw" : "90vw"}
        p={deviceType === "pc" ? 1 : 0}
        position={"fixed"}
        bgcolor={"white"}
        bottom={1}
        zIndex={120}
        boxSizing={"border-box"}
      >
        {deviceType === "pc" && (
          <Box
            display={istyping ? "flex" : "none"}
            flexDirection={"row"}
            justifyContent={"start"}
            alignItems={"center"}
          >
            <Chip
              label={istyping ? "Typing... " : ""}
              sx={{ fontStyle: "italic" }}
            />
          </Box>
        )}
        <Box pl={deviceType === "pc" ? 1 : 0.1} pr={1} py={1} >
          <TextField
            fullWidth
            variant="outlined"
            value={newMessage}
            onChange={typingHandler}
            label=""
            placeholder="Type your message"
            InputProps={{
              disableUnderline: true,
              endAdornment: newMessage ? (
                <IconButton onClick={sendMessage}>
                  <Telegram />
                </IconButton>
              ) : (
                <></>
              ),
            }}
          />
        </Box>
        {
          deviceType !== "pc" && <div>
            <Toolbar />
            <br />
          </div>
        }
      </Box>
    </Box>
  );
};

export default ChatBox;
