import useSWR from "swr";
import { useAppSelector } from "./apphook";
import { RootState } from "../../redux/store";

export default function usePostedJobs() {
  const isAuthenticated = useAppSelector((state: RootState) => state.auth.isAuth); 
  let authMail: string = localStorage.getItem("auth-email") ?? "";
  console.log("AUTHED? ", isAuthenticated);
  

  const { data, mutate, error } = useSWR(isAuthenticated ? "/job/byUser/"+authMail : "");

  // console.log('POSTED response', data);
  const loading = !data && !error;
  const loggedOut =
    (error && error?.message === "No token provided.") ||
    error?.response?.status === 401 ||
    error?.response?.status === 403 ||
    error?.response?.data?.message === "No user found!" ||
    data?.accountStatus === "frozen";

  return isAuthenticated ? {
    loading,
    loggedOut,
    data,
    mutate,
  } : {};
}
