import {
  Avatar,
  Box,
  Chip,
  Grid,
  ListItemButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../utils/hooks/apphook";

import WorkIcon from "@mui/icons-material/Work";
import CreditCardIcon from "@mui/icons-material/CreditCard";

interface Props {
  data: any;
}

export default function TransactionHistory() {
  const theme = useTheme();
  const [deviceType, setDeviceType] = React.useState("mobile");
  const profile = useAppSelector((state) => state.auth.profile);

  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet]);

  return (
    <Box
      p={2}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
    >
      <Box
        px={deviceType === "pc" ? 6 : deviceType === "tablet" ? 4 : 2}
        py={deviceType === "pc" ? 4 : deviceType === "tablet" ? 3 : 2}
        bgcolor={theme.palette.primary.main}
        borderRadius={5}
        color={"white"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={deviceType === "mobile" ? "center" : "start"}
          alignItems={deviceType === "mobile" ? "center" : "start"}
          pb={deviceType !== "pc" ? 2 : 0}
        >
          <Typography
            textAlign={deviceType === "mobile" ? "center" : "start"}
            variant="h5"
            gutterBottom
          >
            Transaction History
          </Typography>
          <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec
            purus urna. Sed eget semper dolor, id venenatis quam. Maecenas id
            justo nec turpis
          </Typography>
        </Box>
      </Box>

      <Toolbar />
      <Box p={2}>
        {profile?.transactions?.map((item: any, index: number) => (
          <Item key={index} data={item} />
        ))}
      </Box>
    </Box>
  );
}

const Item = ({ data }: Props) => {
  const theme = useTheme();

  return (
    <Box
      p={1}
      component={ListItemButton}
      divider
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"start"}
      alignItems={"center"}
    >
      <Avatar
        variant="circular"
        sx={{
          bgcolor: theme.palette.primary.light,
          color: theme.palette.primary.main,
        }}
      >
        {data?.type?.startsWith("job") ? <WorkIcon /> : <CreditCardIcon />}
      </Avatar>
      <Box
        p={1}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"start"}
        alignItems={"start"}
      >
        <Typography textTransform={'capitalize'} >{`${data?.type}`.replaceAll('_', ' ')}</Typography>
        <Typography fontSize={13} >{data?.summary}</Typography>
      </Box>
    </Box>
  );
};
