import * as React from "react";
import {
  Box,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/apphook";
import RoundedButton from "../../../components/button/round_button";
import theme from "../../../assets/theme/Theme";
import * as Yup from "yup";
import APIService from "../../../service";
import { toast } from "react-hot-toast";
import { setLoading } from "../../../redux/reducers/loader";
import { VisibilityOff } from "@mui/icons-material";
import Visibility from "@mui/icons-material/Visibility";
import securityLogo from "../../../assets/images/security_big.svg";

interface InitialState {
  oldpassword: string;
  newpassword: string;
  confirmpassword: string;
}

export default function SecurityTab() {
  const profile = useAppSelector((state) => state.auth.profile);
  const dispatch = useAppDispatch();

  const [deviceType, setDeviceType] = React.useState("mobile");

  const [showOld, setShowOld] = React.useState(false);
  const [showNew, setShowNew] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);

  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  const guarantorSchema = Yup.object().shape({
    oldpassword: Yup.string()
      .min(8, "Minimum of 8 cahracters required")
      .required("Current password is required"),
    newpassword: Yup.string()
      .min(8, "Minimum of 8 cahracters required")
      .required("New password is required"),
    confirmpassword: Yup.string()
      .min(8, "Minimum of 8 cahracters required")
      .oneOf([Yup.ref("newpassword")], "Pssword does not match!")
      .required("Password confirmation is required"),
  });

  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet]);

  const initialState: InitialState = {
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: guarantorSchema,
    onSubmit: async () => {
      dispatch(setLoading(true));

      // Update profile direct
      const payload = {
        guarantor: {
          ...values,
        },
      };

      try {
        const res = await APIService.update("/updateuser", profile?.email, {
          ...payload,
        });

        console.log("RESP HIA >>> ", `${{ ...res.data }}`);
        dispatch(setLoading(false));

        toast.success("Operation successful");
      } catch (error: any) {
        dispatch(setLoading(false));
        console.log(
          "ERROR HERE >>> ",
          `${
            error?.response?.data?.message ||
            error?.message ||
            "Something went wrong, try again."
          }`
        );
      }
    },
  });

  const { handleSubmit, getFieldProps, values, errors, touched } = formik;

  return (
    <Box>
      {deviceType !== "pc" && (
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <img src={securityLogo} alt="" width={150} height={150} />
          <Typography textAlign={"center"} py={1}>
            Fill out the form below to change your password.
          </Typography>
          <Toolbar />
        </Box>
      )}
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          style={{ width: "100%" }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"start"}
            alignItems={"stretch"}
          >
            <Grid
              container
              spacing={2}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  variant="outlined"
                  type={showOld ? "text" : "password"}
                  fullWidth
                  required
                  label="Old Password"
                  size="small"
                  {...getFieldProps("oldpassword")}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => setShowOld(!showOld)}>
                        {!showOld ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                  error={Boolean(touched.oldpassword && errors.oldpassword)}
                  helperText={touched.oldpassword && errors.oldpassword}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  variant="outlined"
                  type={showNew ? "text" : "password"}
                  fullWidth
                  required
                  label="New Password"
                  size="small"
                  {...getFieldProps("newpassword")}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => setShowNew(!showNew)}>
                        {!showNew ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                  error={Boolean(touched.newpassword && errors.newpassword)}
                  helperText={touched.newpassword && errors.newpassword}
                />
              </Grid>
            </Grid>

            <br />

            <Grid
              container
              spacing={2}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  variant="outlined"
                  type={showConfirm ? "text" : "password"}
                  fullWidth
                  required
                  label="Confirm Password"
                  size="small"
                  {...getFieldProps("confirmpassword")}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => setShowConfirm(!showConfirm)}>
                        {!showConfirm ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                  error={Boolean(
                    touched.confirmpassword && errors.confirmpassword
                  )}
                  helperText={touched.confirmpassword && errors.confirmpassword}
                />
              </Grid>
            </Grid>

            <Toolbar />

            <RoundedButton
              variant="contained"
              type="submit"
              sx={{ bgcolor: theme.palette.primary.main, color: "white" }}
            >
              Save Changes
            </RoundedButton>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
}
