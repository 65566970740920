import {
  Avatar,
  Box,
  Card,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../utils/hooks/apphook";
import formatDistance from "date-fns/formatDistance";
import RoundedButton from "../button/round_button";
import RoundedButtonOutlined from "../button/rounded_button_outlined";
import { BrandedDialog } from "../dialog";
import { setLoading } from "../../redux/reducers/loader";
import APIService from "../../service";
import toast from "react-hot-toast";
// import { Call, Check, DoneAllOutlined, Preview, PreviewOutlined, ViewArray } from "@mui/icons-material";

interface Props {
  data: any;
  profile?: any;
  setOpen?: any;
}
interface DateOptions {
  includeSeconds: boolean;
  addSuffix: boolean;
}

export default function ApplicantCard(props: Props) {
  const { data } = props;
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDeclineDialog, setOpenDeclineDialog] = React.useState(false);
  const [deviceType, setDeviceType] = React.useState("mobile");
  const [isApplied, setApplied] = React.useState(false);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  const profile = useAppSelector((state) => state.auth.profile);

  const checkApplied = () => {
    for (var element in profile["myJobApplications"]) {
      if (element.toString() === data["id"]) {
        setApplied(true);
      }
    }
  };

  console.log("DATA TEST HERE :: - ::", data);
  

  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet]);

  React.useEffect(() => {
    checkApplied();
  }, []);

  let options: DateOptions = {
    addSuffix: true,
    includeSeconds: true,
  };

  return (
    <Card sx={{ mt: 1 }}>
      <BrandedDialog
        open={openDialog}
        width={200}
        setOpen={setOpenDialog}
        title="Accept Application"
        body={<AcceptContent data={data} profile={profile} setOpen={setOpenDialog} />}
      />
      <BrandedDialog
        open={openDeclineDialog}
        width={200}
        setOpen={setOpenDeclineDialog}
        title="Reject Application"
        body={<DeclineContent data={data} profile={profile} setOpen={setOpenDeclineDialog} />}
      />
      <Box
        p={1}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"start"}
        alignItems={"start"}
      >
        <Box>
          <IconButton>
            <Avatar
              alt="Remy Sharp"
              sx={{
                width: deviceType === "pc" ? 72 : 56,
                height: deviceType === "pc" ? 72 : 56,
              }}
              src={data?.applicant?.bio?.image}
            />
          </IconButton>
        </Box>

        <Box
          p={0.5}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"start"}
          alignItems={"start"}
        >
          <Typography
            mt={1}
            fontWeight={600}
            textTransform={"capitalize"}
            fontSize="1.12rem"
            lineHeight={1.0}
          >
            {`${data?.applicant?.bio?.firstname} ${data?.applicant?.bio?.lastname}`}
          </Typography>

          <Typography
            gutterBottom
            fontSize={"0.75rem"}
            textTransform={"capitalize"}
          >
            {`Applied on ${new Date(data?.createdAt).toLocaleDateString(
              "en-GB"
            )} (${formatDistance(
              Date.parse(`${data?.createdAt}`),
              new Date(),
              options
            )})`}
          </Typography>
        </Box>
      </Box>
      <Box
        px={2}
        pb={2}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"end"}
        alignItems={"center"}
      >
      
        <RoundedButton
          variant="contained"
          sx={{
            flex: 1,
            mx: 1,
            height: 44,
            bgcolor: theme.palette.primary.main,
            color: "white",
            textTransform: "capitalize",
          }}
          onClick={() => navigate(
            "/dashboard/jobs/" + data?.job?._id + "/applications/" + data?.id,
            { state: { data: data } }
          )}
        >
          View
        </RoundedButton>
        
        <RoundedButton
          disabled={data?.status !== "submitted"}
          variant="contained"
          sx={{
            flex: 1,
            mx: 1,
            height: 44,
            bgcolor: theme.palette.success.light,
            color: "white",
            textTransform: "capitalize",
          }}
          onClick={() => setOpenDialog(true)}
        >
          {data?.status === "submitted" ? "Accept" : `${data?.status}`}
        </RoundedButton>
        <RoundedButton
        disabled={data?.status !== "submitted"}
          variant="contained"
          sx={{
            flex: 1,
            height: 44,
            bgcolor: "red",
            color: "white",
          }}
          onClick={() => setOpenDeclineDialog(true)}
        >
          {data?.status === "submitted" ? "Decline" : `${data?.status}`}
        </RoundedButton>
      </Box>
    </Card>
  );
}

const AcceptContent: React.FC<Props> = ({ data, profile , setOpen}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const acceptApplication = async () => {
    dispatch(setLoading(true));
    setOpen(false);
    try {
      const payload = {
        data: data
      };

      const response = await APIService.update("/job/applications/accept", profile?.email, payload);

      dispatch(setLoading(false));

      console.log("RESPONSE :: :: :: ::: ", response.data);
      toast.success(`${response.data?.message}`);

    } catch (error: any) {
        dispatch(setLoading(false));
        toast.success(`${error.data?.message || error?.message || "Check your internet connection"}`);
    }
  };

  return (
    <Box
      p={4}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"start"}
    >
      <Typography variant="body2" gutterBottom mb={2}>
        {`Are you sure you want to accept this job application from  ${data?.applicant?.bio?.firstname} ${data?.applicant?.bio?.lastname}?`}
      </Typography>
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <RoundedButtonOutlined
          variant="outlined"
          sx={{
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            height: 40,
            flex: 1,
            mr: 1,
          }}
          onClick={() => setOpen(false)}
        >
          Cancel
        </RoundedButtonOutlined>

        <RoundedButtonOutlined
          variant="contained"
          sx={{
            bgcolor: theme.palette.primary.main,
            color: "white",
            height: 40,
            flex: 1,
            ml: 1,
          }}
          onClick={() => acceptApplication()}
        >
          Accept
        </RoundedButtonOutlined>
      </Box>
    </Box>
  );
};

const DeclineContent: React.FC<Props> = ({ data, profile , setOpen}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const rejectApplication = async () => {
    dispatch(setLoading(true));
    setOpen(false);
    try {
      const payload = {
        data: data
      };

      const response = await APIService.update("/job/applications/decline", profile?.email, payload);

      dispatch(setLoading(false));

      console.log("RESPONSE :: :: :: ::: ", response.data);
      toast.success(`${response.data?.message}`);

    } catch (error: any) {
        dispatch(setLoading(false));
        toast.success(`${error.data?.message || error?.message || "Check your internet connection"}`);
    }
  };

  return (
    <Box
      p={4}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"start"}
    >
      <Typography variant="body2" gutterBottom mb={2}>
        {`Are you sure you want to reject this job application from  ${data?.applicant?.bio?.firstname} ${data?.applicant?.bio?.lastname}?`}
      </Typography>
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <RoundedButtonOutlined
          variant="outlined"
          sx={{
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            height: 40,
            flex: 1,
            mr: 1,
          }}
          onClick={() => setOpen(false)}
        >
          Cancel
        </RoundedButtonOutlined>

        <RoundedButtonOutlined
          variant="contained"
          sx={{
            bgcolor: theme.palette.primary.main,
            color: "white",
            height: 40,
            flex: 1,
            ml: 1,
          }}
          onClick={() => rejectApplication()}
        >
          Reject
        </RoundedButtonOutlined>
      </Box>
    </Box>
  );
};
