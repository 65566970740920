import {
  Box,
  Button,
  Card,
  IconButton,
  ListItemButton,
  Typography,
  useTheme,
} from "@mui/material";
import noDataImage from "../../assets/images/empty.png";
import CustomizedDialog, {
  BrandedDialog,
  BrandedDialog as ItemUpdateDialog,
} from "../../components/dialog";
import React from "react";
import { useAppSelector } from "../../utils/hooks/apphook";
import { Add, DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import AddExperience from "../../components/forms/experience/add_experience";
import UpdateExperience from "../../components/forms/experience/update_experience";
import RoundedButton from "../../components/button/round_button";
// import UserProfile from "./profile";
import toast from "react-hot-toast";
import { setLoading } from "../../redux/reducers/loader";
import APIService from "../../service";
import { useDispatch } from "react-redux";
import { setProfile } from "../../redux/reducers/auth";

export interface Props {
  experience: any;
  from?: string;
  setOpen?: any;
  userProfile?: any;
}

interface ExperienceItemProps {
  item: any;
  handleUpdate: any;
  handleDelete: any;
  profile: any;
  from?: string;
}

function ExperienceItem({
  item,
  handleUpdate,
  handleDelete,
  profile,
  from,
}: ExperienceItemProps) {
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);

  return (
    <ListItemButton sx={{ paddingX: 0 }} divider>
      {/* Your item rendering code here */}
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"start"}
          alignItems={"center"}
        >
          <img src={item?.companyLogo} alt="" width={70} height={70} />
          <Box
            pl={1}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"start"}
            alignItems={"start"}
          >
            <Typography
              fontWeight={600}
              variant="body1"
              lineHeight={1.0}
              textTransform={"capitalize"}
            >
              {`${item?.role}`}
            </Typography>
            <Typography
              fontWeight={500}
              variant="body2"
              textTransform={"capitalize"}
            >
              {`${item?.company} . ${item?.workType}`}
            </Typography>
            <Typography
              fontWeight={500}
              variant="body2"
              textTransform={"capitalize"}
            >
              {`${new Date(item?.startDate).toLocaleDateString("en-GB")} - ${
                item?.endate === undefined || item?.stillHere === true
                  ? "present"
                  : new Date(item?.endate).toLocaleDateString("en-GB")
              }`}
            </Typography>
            <Typography
              fontWeight={500}
              variant="body2"
              textTransform={"capitalize"}
            >
              {`${item?.region}, ${item?.country}`}
            </Typography>
          </Box>
        </Box>

        <Box
          display={from ? "flex" : "none"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"end"}
        >
          <IconButton onClick={() => setOpenUpdate(true)}>
            <EditOutlined />
          </IconButton>
          <ItemUpdateDialog
            open={openUpdate}
            setOpen={setOpenUpdate}
            width={"50%"}
            title={"Update Work Experience "}
            body={<UpdateExperience data={item} setOpen={setOpenUpdate} />}
          />
          <IconButton onClick={() => setOpenDelete(true)}>
            <DeleteOutlineOutlined />
          </IconButton>
          <CustomizedDialog
            body={
              <DeleteItemContent
                setOpen={setOpenDelete}
                experience={item}
                userProfile={profile}
              />
            }
            open={openDelete}
            setOpen={setOpenDelete}
            title="Delete Confirmation"
            width={"40%"}
          />
        </Box>
      </Box>
    </ListItemButton>
  );
}

export default function ExperienceSection(props: Props) {
  let { experience, from } = props;
  const [open, setOpen] = React.useState(false);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  // const [currentItem, setCurrentItem] = React.useState({});

  const profile = useAppSelector((state: any) => state.auth.profile);

  // React.useEffect(() => {
  //   if (currentItem) {
  //     console.log("JKD D  KD : ", currentItem);
  //   }
  // }, [currentItem]);

  return (
    <Card>
      <BrandedDialog
        open={open}
        setOpen={setOpen}
        width={"50%"}
        title={"Add Work Experience "}
        body={
          <AddExperience
            experience={experience}
            userProfile={profile}
            setOpen={setOpen}
          />
        }
      />

      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"start"}
        alignItems={"start"}
        p={2}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
        >
          <Typography variant="h6" fontWeight={500} gutterBottom>
            Experience
          </Typography>
          {from && experience?.length > 0 && (
            <IconButton onClick={() => setOpen(true)}>
              <Add />
            </IconButton>
          )}
        </Box>
        {experience?.length > 0 ? (
          <Box sx={{ width: "100%" }}>
            {experience &&
              experience?.map((item: any, index: number) => (
                <ExperienceItem
                  key={index}
                  item={item}
                  from={from}
                  profile={profile}
                  handleDelete={() => setOpenDelete(true)}
                  handleUpdate={() => setOpenUpdate(true)}
                />
              ))}
          </Box>
        ) : (
          <Box
            p={4}
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <img src={noDataImage} alt="" width={100} />
            <Typography>No data found</Typography>
            {from && (
              <Button
                startIcon={<Add />}
                sx={{
                  textTransform: "capitalize",
                  display: !from ? "none" : "flex",
                }}
                onClick={() => setOpen(true)}
              >
                Add experience
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Card>
  );
}

const DeleteItemContent = ({ experience, setOpen, userProfile }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <Box
      p={2}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"center"}
    >
      <Typography fontSize={14} gutterBottom pb={2}>
        {"Are you sure you want to delete this experience?"}
      </Typography>
      <RoundedButton
        fullWidth
        variant="contained"
        sx={{ bgcolor: theme.palette.primary.main, color: "white" }}
        onClick={async () => {
          setOpen(false);
          // Delete here
          const updatedExperiences = userProfile?.experience?.filter(
            (expe: any) => expe.company !== experience?.company
          );

          await setTimeout(() => {
            console.log("Just do nothing ...");
          }, 4000);

          const payload = {
            experience: updatedExperiences,
          };

          try {
            const res = await APIService.update(
              "/updateuser",
              userProfile?.email,
              {
                ...payload,
              }
            );

            console.log("RESP HERE >>> ", `${res}`);
            dispatch(setProfile(res.data.data));
            dispatch(setLoading(false));

            toast.success(`${res.data.message}`);
          } catch (error: any) {
            dispatch(setLoading(false));
            console.log(
              "ERROR HERE >>> ",
              `${
                error?.response?.data?.message ||
                error?.message ||
                "Something went wrong, try again."
              }`
            );
          }
        }}
      >
        Proceed
      </RoundedButton>
      <Button fullWidth onClick={() => setOpen(false)} sx={{ my: 2 }}>
        Cancel
      </Button>
    </Box>
  );
};
