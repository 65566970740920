import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import RootPage from "./pages/";
import Footer from "./layouts/footers/";
import MainNavbar, {
  Props as AppBarProps,
} from "./layouts/navbars/main_navbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileNavbar, {
  MobileAuthNavbar,
} from "./layouts/navbars/mobile_navbar";
import JoinNow from "./pages/auth/join";
import Signup from "./pages/auth/signup";
import { Backdrop, CircularProgress, Container, Toolbar } from "@mui/material";

import { useAppSelector, useAppDispatch } from "./utils/hooks/apphook";
import Login from "./pages/auth/login";
import VerifyOTP from "./pages/auth/verify_otp";
import toast, { Toaster } from "react-hot-toast";
import DashbboardLayout from "./layouts/dashboard/dashboard_layout";
import Explore from "./pages/dashboard/explore";
import Jobs from "./pages/dashboard/jobs";
// import Support from "./pages/dashboard/support";
import useProfile from "./utils/hooks/use_profile";
import { setAuth, setProfile } from "./redux/reducers/auth";
import useProfessionals from "./utils/hooks/use_professinals";
import {
  setMyConnection,
  setProfessionals,
  setSavedPro,
} from "./redux/reducers/users";
import useJobs from "./utils/hooks/useJobs";
import {
  setJobs,
  setMyJobApplications,
  setMyPostedJobs,
} from "./redux/reducers/jobs";
import ExplorePro from "./pages/explore";
import usePostedJobs from "./utils/hooks/usePostedJobs";
import UserProfile from "./pages/user/profile";
import MessageCenter from "./pages/messages";
import useConversations from "./utils/hooks/use_chats";
import { setConversations } from "./redux/reducers/messages";
import useSavedPros from "./utils/hooks/use_saved_pros";
import Account from "./pages/dashboard/account";
import JobInfo from "./pages/jobs/jobInfo";
import Category from "./pages/category";
import PrivacyPolicy from "./pages/legal/privacy_policy";
import TermsOfService from "./pages/legal/terms_of_service";
import useProfessions from "./utils/hooks/use_profession";
import { setProfessions } from "./redux/reducers/profession";
import useLegal from "./utils/hooks/use_legal";
import { setLegal } from "./redux/reducers/legal";
import ContactUs from "./pages/contact";
import FAQs from "./pages/faq";
import SearchResultsPage from "./pages/search_results";
import AccountSearchResultsPage from "./pages/dashboard/explore/search_result";
import ReportsOverview from "./pages/dashboard/reports/overview";
import TransactionHistory from "./pages/dashboard/reports/trans_history";
import MyProfile from "./pages/user/myprofile";
import PersonalProfile from "./pages/dashboard/account/personal_profile";
import SecurityTab from "./pages/dashboard/account/security";
import ContactSupport from "./components/forms/support";
import MyPostedJobs from "./pages/dashboard/reports/posted_jobs";
// import { useSocket } from "./utils/context/socket_context";
import useAppliedJobs from "./utils/hooks/useAppliedJobs";
import AboutUs from "./pages/about";
import JobApplicants from "./pages/jobs/applicants";
import ApplicationInfo from "./pages/jobs/applicationinfo";
import UserReviews from "./pages/dashboard/reviews";
import useConnections from "./utils/hooks/useConnection";
import ChatBox from "./pages/messages/chatbox";
import useBanners from "./utils/hooks/use_banners";
import useFAQs from "./utils/hooks/use_faqs";
import { setBanners, setFAQs, setSections } from "./redux/reducers/cms";
import useSections from "./utils/hooks/use_sections";
import UserConnections from "./pages/user/connections";
import APIService from "./service";
import socket from "./utils/socket";

function App() {
  const [deviceType, setDeviceType] = React.useState("mobile");
  const [hidden, setHidden] = React.useState("");
  const [showMobileAuthFooter, setShowMobileAuthFooter] = React.useState(false);
  const location = useLocation();

  // const { socket } = useSocket();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  const loading = useAppSelector((state) => state.loader.isLoading);
  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector((state) => state.auth.isAuth);
  const { data } = useProfile();

  const { data: faqData } = useFAQs();
  const { data: jobData } = useJobs();
  const { data: legalData } = useLegal();
  const { data: bannerData } = useBanners();
  const { data: sectionData } = useSections();
  const { data: proData } = useProfessionals();

  const { data: savedProsData } = useSavedPros();
  const { data: postedJobData } = usePostedJobs();
  const { data: appliedJobData } = useAppliedJobs();
  const { data: professionData } = useProfessions();
  const { data: connectionData } = useConnections();
  const { data: conversationsData } = useConversations();

  React.useEffect(() => {
    // socket.connect();

    if (socket) {
      // socket.
      socket.on("connect", () => {
        console.log("MY SOCKET ID NOW ==>>> ", socket.id); // x8WIv7-mJelg7on_ALbx
      });

      if (data) {
        socket.emit("identity", data?.data?.id);

        socket.on(
          "review-updated",
          (val) => {
            console.log("DATA FROM  REVIEW NOW !!! >>", val);
            // console.log("USER ID >> ${data['userId']}");
            if (val?.data?.email === data?.email) {
              //For me
              console.log("FOR ME !! ");
              dispatch(setProfile(val?.data))
            
            } else {
              // Not for me
              console.log("NOT FOR ME !! ");
            }
          },
        );

        socket.on(
          "review-reply",
          (val) => {
            console.log("DATA FROM REVIEW REPLY !!! >> ${jsonEncode(data)}");
            // console.log("USER ID >> ${data['userId']}");
            if (val?.data?.email === data?.email) {
              //For me
              console.log("FOR ME !! ");
              dispatch(setProfile(val?.data))
            } else {
              // Not for me
              console.log("NOT FOR ME !! ");
            }
          },
        );

        socket.on(
          "isOnline",
          (val) => console.log("DATA FROM  ", val),
        );

        socket.on(
          "job-posted",
          (val) =>  {
            console.log("JOB POSTED NOW!!", val);
            toast.success("NEW JOB JUEST POSTED !!!")
          },
        );

        socket.on(
          "job-application-accepted",
          (val) => {
            console.log("DATA FROM APPLICATION ACCEPTANCE !!! >> ", val);
            // console.log("USER ID >> ${data['userId']}");
            if (val?.applicant?.email === val?.email) {
              //For me
              console.log("FOR ME !! ");
              // fetchDataStream();
              // _prefs.setString('user', data['data']);
              // _controller.userData.value = data['data'];
            } else {
              // Not for me
              console.log("NOT FOR ME !! ");
            }
          },
        );

        socket.on(
          "job-application",
          (val) => {
            // console.log("DATA FROM JOB APPLICATION >> $data");
            if (val?.job?.recruiter?.id === data?.id) {
              //UPDate here
              // console.log("TIRGGER HERE -->>");
              // _controller.onInit();
            }
          },
        );

        socket.on(
          "connection-requested",
          async (val) => {
            console.log("CONNECTION REQUESTED ", val);

            try {
             
              const _profileResp = await APIService.fetcher('/user/'+data?.email)
              
              dispatch(setProfile(_profileResp?.data));
             
            } catch (e) {
              console.log("ERROR ::: -- :: ", e);
            }
          },
        );

        socket.on(
          "connection-accepted",
          async (val) => {
            console.log("CONNECTION ACCEPTED ", val);

            try {
              // Map<String, dynamic> map = jsonDecode(jsonEncode(data));
              // print("CONNECTION DECLINED $map['declinedBy']");
              // final declinedBy = map['declinedBy']['id'];
              // if (declinedBy != _userMap['id']) {
              //   print('OTHER USER');
              //   _controller.currentConversation.add(map['message']);
              // } else {
              //   print('ME THE USER');
              // }

              // final _profileResp = await APIService().getProfile(
              //   _token,
              //   _userMap['email'],
              // );

              // // print("PROFILE ::: ${_profileResp.body}");
              // if (_profileResp.statusCode == 200) {
              //   Map<String, dynamic> map = jsonDecode(_profileResp.body);
              //   String userData = jsonEncode(map['data']);
              //   _prefs.setString('user', userData);
              //   _controller.setUserData(map['data']);
              // }
            } catch (e) {
              console.log("ERROR ::: -- :: ", e);
            }
          },
        );

        socket.on("connection-declined", async function (val) {
          try {
            // Map<String, dynamic> map = jsonDecode(jsonEncode(data));
            console.log("CONNECTION DECLINED ", val?.declinedBy);
            // final declinedBy = map['declinedBy']['id'];
            // if (declinedBy != _userMap['id']) {
            //   print('OTHER USER');
            //   _controller.currentConversation.add(map['message']);
            // } else {
            //   print('ME THE USER');
            // }

            // final _profileResp = await APIService().getProfile(
            //   _token,
            //   _userMap['email'],
            // );

            // // print("PROFILE ::: ${_profileResp.body}");
            // if (_profileResp.statusCode == 200) {
            //   Map<String, dynamic> map = jsonDecode(_profileResp.body);
            //   String userData = jsonEncode(map['data']);
            //   _prefs.setString('user', userData);
            //   _controller.setUserData(map['data']);
            // }
          } catch (e) {
            console.log("ERROR ::: -- :: ", e);
          }
        });

        socket.on(
          "connection-cancelled",
          (val) => {
            console.log("CONNECTION CANCELLED ::: ", val);
            
            // _controller.onInit();
          },
        );

        socket.on("user-blocked", (val) => {
          console.log("BLOCKED USER DATA :: ", val);
        });

        socket.on("user-unblocked", (val) => {
          console.log("UNBLOCKED USER DATA :: ", val);
        });

        socket.on(
          "new-message",
          (val) => {
            console.log("DATA FROM MESSAGE >> ", val);
            // Map<String, dynamic> map = jsonDecode(jsonEncode(data));
            // final senderId = map['senderId'];
            // if (senderId != _userMap['id']) {
            //   _controller.currentConversation.add(map['message']);
            // }

            // _refreshChatList();
            // //Now play sound here
            // AudioPlayer().play(AssetSource('assets/audio/sound2.mp3'));
          },
        );

        socket.on(
          "message recieved",
          (val) => {
            console.log("DATA FROM MESSAGE >> ", val);
            // Map<String, dynamic> map = jsonDecode(jsonEncode(data));
            // final senderId = map['senderId'];

            // _refreshChatList();
            // //Now play sound here
            // AudioPlayer().play(AssetSource('assets/audio/sound2.mp3'));
          },
        );
      }

      socket.on(
        "FromAPI",
        (data) => console.log("DATA FROM SERVER >> $data"),
      );


      socket.on("job-application", (resp) =>
        console.log("JOB APPLICATION RESP :: ", resp)
      );

      socket.on("FromAPI", (dat) => console.log("DATA FROM SERVER >>", dat));
    }

    return () => {
      socket?.disconnect();
    };
  }, [data, dispatch, isAuthenticated, socket]);

  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet]);

  React.useEffect(() => {
    if (
      location.pathname.includes("/signup") ||
      location.pathname.includes("/login") ||
      location.pathname.includes("/verify-otp") ||
      location.pathname.includes("/dashboard") ||
      location.pathname.includes("/privacy-policy") ||
      location.pathname.includes("/terms-of-use")
    ) {
      setHidden("hide");
      setShowMobileAuthFooter(false);
    } else {
      setHidden("show");
      setShowMobileAuthFooter(false);
    }

    if (location.pathname.includes("/dashboard") && deviceType !== "pc") {
      setShowMobileAuthFooter(true);
    }
  }, [deviceType, location]);

  // console.log("POSTED JOBS ::: ", postedJobData);
  

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const childrenElement: JSX.Element = <Box bgcolor={"red"}></Box>;

  // Define the props object to be passed to ElevateAppBar
  const appBarProps: AppBarProps = {
    children: childrenElement,
  };

  React.useEffect(() => {
    if (data) {
      dispatch(setAuth(true));
      dispatch(setProfile(data?.data));
    }
    if (proData) {
      dispatch(setProfessionals(proData?.docs));
    }
    if (jobData) {
      dispatch(setJobs(jobData?.docs));
    }
    if (postedJobData) {
      dispatch(setMyPostedJobs(postedJobData?.docs));
    }
    if (savedProsData) {
      dispatch(setSavedPro(savedProsData?.data));
    }
    if (conversationsData) {
      dispatch(setConversations(conversationsData.data));
    }
    if (professionData) {
      dispatch(setProfessions(professionData?.docs));
    }
    if (legalData) {
      dispatch(setLegal(legalData?.docs));
    }
    if (connectionData) {
      dispatch(setMyConnection(connectionData?.data));
    }
    if (appliedJobData) {
      console.log("APPLIED JOBB FDATA :: ", appliedJobData);
      
      dispatch(setMyJobApplications(appliedJobData?.applications?.docs));
    }
    if (bannerData) {
      dispatch(setBanners(bannerData?.docs));
    }
    if (faqData) {
      dispatch(setFAQs(faqData?.docs));
    }
    if (sectionData) {
      dispatch(setSections(sectionData?.docs));
    }
  }, [
    appliedJobData,
    connectionData,
    conversationsData,
    data,
    bannerData,
    dispatch,
    jobData,
    legalData,
    postedJobData,
    proData,
    faqData,
    sectionData,
    professionData,
    savedProsData,
  ]);

  return (
    <div className="App">
      <Toaster />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 5000 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {hidden === "show" ? (
        <>
          <MainNavbar {...appBarProps} />
          {deviceType !== "pc" ? <MobileNavbar /> : <div />}
        </>
      ) : (
        <>
          {showMobileAuthFooter && deviceType !== "pc" && <MobileAuthNavbar />}
        </>
      )}

      <Routes>
        <Route path="/" element={<RootPage />} />
        <Route path="/signup" element={<JoinNow />} />
        <Route path="/signup/recruiter" element={<Signup />} />
        <Route path="/signup/professional" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/verify-otp" element={<VerifyOTP />} />
        <Route path="/explore" element={<ExplorePro />} />
        <Route path="/jobs/:id" element={<JobInfo />} />
        <Route path="/category/:id" element={<Category />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfService />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/searchresults/:key" element={<SearchResultsPage />} />
        <Route
          path="/professionals/:id"
          element={
            <Box py={5}>
              <Toolbar />
              <Container>
                <UserProfile />
              </Container>
            </Box>
          }
        />
        {isAuthenticated && (
          <Route path="/dashboard" element={<DashbboardLayout />}>
            <Route
              path="/dashboard"
              element={<Navigate to="/dashboard/explore" replace />}
            />
            <Route path="/dashboard/explore" element={<Explore />} />
            <Route path="/dashboard/connections" element={<Jobs />} />
            <Route path="/dashboard/report" element={<div />} />
            <Route path="/dashboard/message" element={<MessageCenter />} />
            <Route path="/dashboard/message/:id" element={<ChatBox deviceType={deviceType} />} />
            <Route path="/dashboard/jobs" element={<Jobs />} />
            <Route path="/dashboard/jobs/:id" element={<JobInfo />} />
            <Route
              path="/dashboard/jobs/:id/applications"
              element={<JobApplicants />}
            />
            <Route
              path="/dashboard/jobs/:id/applications/:ke"
              element={<ApplicationInfo />}
            />
            <Route path="/dashboard/account" element={<Account />} />
            <Route path="/dashboard/reviews" element={<UserReviews />} />
            <Route
              path="/dashboard/search/:key"
              element={<AccountSearchResultsPage />}
            />
            <Route
              path="/dashboard/professionals/:id"
              element={<UserProfile />}
            />
            <Route
              path="/dashboard/professionals/:id/connections"
              element={<UserConnections  />}
            />
            <Route
              path="/dashboard/account/profile"
              element={<UserProfile />}
            />
            <Route path="/dashboard/reports/" element={<ReportsOverview />} />
            <Route path="/dashboard/my-profile" element={<MyProfile />} />
            <Route
              path="/dashboard/account/personal-info"
              element={<PersonalProfile />}
            />
            <Route
              path="/dashboard/account/security"
              element={<SecurityTab />}
            />
            <Route
              path="/dashboard/account/support"
              element={<ContactSupport />}
            />
            <Route
              path="/dashboard/reports/transactions"
              element={<TransactionHistory />}
            />
            <Route
              path="/dashboard/reports/posted-jobs"
              element={<MyPostedJobs />}
            />
            <Route path="/dashboard/about" element={<AboutUs />} />
          </Route>
        )}
      </Routes>
      {hidden === "show" ? <Footer /> : <></>}
    </div>
  );
}

export default App;
