import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import ExploreHeader from "../../layouts/headers/explore";
import { useAppSelector } from "../../utils/hooks/apphook";
import ProCard from "../../components/professional/pro_card";
import theme from "../../assets/theme/Theme";
import CustomContainer from "../../components/container";
import { RootState } from "../../redux/store";
import FullFlex from "../../layouts/sections/full_flex";
import Oxygen from "../../layouts/sections/oxygen";
import Testimonial from "../../layouts/sections/testimonial";
import CallToAction from "../../layouts/sections/cta";

export default function ExplorePro(): React.JSX.Element {
  const professionals = useAppSelector((state) => state.users.professionals);
  const [deviceType, setDeviceType] = React.useState("mobile");
  // const [isFixed, setFixed] = React.useState(false);

  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  const sections = useAppSelector((state: RootState) => state.cms.sections);
  const [exploreSections, setExploreSections] = React.useState<any>([]);

  React.useEffect(() => {
    if (sections) {
      const filterSections = sections?.filter((item: any) => item?.page === "explore");
      setExploreSections(filterSections);
    }
  }, [sections])


  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet]);

  return (
    <Box>
      <ExploreHeader />
      <Box
        paddingY={deviceType === "pc" ? 16 : deviceType === "tablet" ? 12 : 8}
        // bgcolor={"#efefef"}
      >
        <CustomContainer>
          <Grid container spacing={2}>
            <Grid flex={1} flexGrow={1} item xs={12} sm={12} md={12} lg={12}>
              <Box>
                <Typography
                  fontWeight={600}
                  variant={deviceType === "pc" ? "h4" : "h6"}
                >
                  Our Top Professionals
                </Typography>
                <Grid container spacing={2}>
                  {professionals &&
                    professionals?.map((item: any, index:number) => (
                      <Grid key={index} item xs={12} sm={6} md={6} lg={3}>
                        <ProCard
                          data={item}
                          height={
                            deviceType === "pc"
                              ? 375
                              : deviceType === "tablet"
                              ? 328
                              : 320
                          }
                        />
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </CustomContainer>
      </Box>
      {
        exploreSections && <Box>
          {
            exploreSections?.map((item: any, index: number) => {
              return item?.template === "full-flex" ? <FullFlex key={index}  data={item} /> : item?.template === "oxygen" ?  <Oxygen data={item} /> : item?.template === "testimonial" ?  <Testimonial data={item} />  : item?.template === "call to action" ?  <CallToAction data={item} />  : <div />
            })
          }
        </Box>
      }
    </Box>
  );
}
