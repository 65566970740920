import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import theme from "../../assets/theme/Theme";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import React from "react";
import CustomContainer from "../../components/container";
import './quill.css'

interface Props {
  data: any
}

export default function FullFlex({ data }: Props) {
  const [deviceType, setDeviceType] = React.useState("mobile");

  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  const modules: any = {
    toolbar: false
  };

  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet]);

  return (
    <Box
      paddingY={deviceType === "pc" ? 16 : deviceType === "tablet" ? 12 : 8}
      bgcolor={theme.palette.primary.light}
    >
      <CustomContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
          }}
        >
          {/* The ProHelp Advantage */}
          <Typography gutterBottom fontWeight={600} variant="h4">
            {data?.title}
          </Typography>
          <Grid container spacing={2} mb={2} mt={1}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
             <ReactQuill readOnly={true} value={data?.content}  modules={modules} style={{ border: 'none' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
             <img src={data?.featuredAsset} alt=""/>
            </Grid>
          </Grid>
        </Box>
      </CustomContainer>
    </Box>
  );
}
