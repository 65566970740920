import { Box, TextField, Typography } from "@mui/material";
import React from "react";

export interface Props {
  values: any;
  getFieldProps: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  jobData: any;
  setFile: any;
}

export default function QuestionsResumeStep({
  errors,
  getFieldProps,
  values,
  touched,
  setFieldValue,
  jobData,
  setFile,
}: Props) {
  // const [previewImage, setPreviewImage] = React.useState("");

  const handleFileInputChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      try {
        if (event.target?.files[0]) {
          setFile(file);
          setFieldValue("resume", file?.name);
          //   setPreviewImage(URL.createObjectURL(file));
        } else {
          //   setPreviewImage("");
          setFieldValue("resume", "");
        }
      } catch (e) {}
    } else {
      setFile(null);
    }
  };

  React.useEffect(() => {
    console.log("HHS");
    setFieldValue("resume", "");
  }, []);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      p={4}
    >
      {jobData?.screeningQuestions?.map((item: any, index: number) => (
        <Box
          key={index}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"start"}
          alignItems={"start"}
        >
          <Typography>{item}</Typography>
          <TextField
            variant="outlined"
            label={"Your answer"}
            size="small"
            fullWidth
            required
            {...getFieldProps(
              `${
                index === 0
                  ? "answer1"
                  : index === 1
                  ? "answer2"
                  : index === 2
                  ? "answer3"
                  : index === 3
                  ? "answer4"
                  : index === 4
                  ? "answer5"
                  : index === 5
                  ? "answer6"
                  : index === 6
                  ? "answer7"
                  : index === 7
                  ? "answer8"
                  : index === 8
                  ? "answer9"
                  : "answer10"
              }`
            )}
            error={Boolean(
              index === 0
                ? touched.answer1 && errors.answer1
                : index === 1
                ? touched.answer2 && errors.answer2
                : index === 2
                ? touched.answer3 && errors.answer3
                : index === 3
                ? touched.answer4 && errors.answer4
                : index === 4
                ? touched.answer5 && errors.answer5
                : index === 5
                ? touched.answer6 && errors.answer6
                : index === 6
                ? touched.answer7 && errors.answer7
                : index === 7
                ? touched.answer8 && errors.answer8
                : index === 8
                ? touched.answer9 && errors.answer9
                : touched.answer10 && errors.answer10
            )}
            helperText={
              index === 0
                ? errors.answer1
                : index === 1
                ? errors.answer2
                : index === 2
                ? errors.answer3
                : index === 3
                ? errors.answer4
                : index === 4
                ? errors.answer5
                : index === 5
                ? errors.answer6
                : index === 6
                ? errors.answer7
                : index === 7
                ? errors.answer8
                : index === 8
                ? errors.answer9
                : errors.answer10
            }
            sx={{ mb: 1.5 }}
          />
        </Box>
      ))}

      <Typography mt={4}>CV/Resume*</Typography>
      <TextField
        variant="outlined"
        size="small"
        type="file"
        fullWidth
        name="resume"
        onChange={handleFileInputChange}
        required
        error={Boolean(touched.resume && errors.resume)}
        helperText={errors.resume}
        sx={{ mb: 1.5 }}
      />
    </Box>
  );
}
