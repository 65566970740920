import { Box, Grid, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../utils/hooks/apphook";
import MyPostedJobCard from "../../../components/jobs/my_job_card";

import notFoundImage from "../../../assets/images/empty.png";

export default function MyPostedJobs() {

    const profile = useAppSelector((state) => state.auth.profile);
    const postedJobs = useAppSelector((state) => state.jobs.myPostedJobs);

    const theme = useTheme();
    const [deviceType, setDeviceType] = React.useState("mobile");
  
    const mobile = useMediaQuery(theme.breakpoints.only("xs"));
    const tablet = useMediaQuery(theme.breakpoints.only("sm"));
  
    React.useEffect(() => {
      if (mobile) {
        setDeviceType("mobile");
      } else if (tablet) {
        setDeviceType("tablet");
      } else {
        setDeviceType("pc");
      }
    }, [mobile, tablet]);

  return (
    <Box>
         <Box
        px={deviceType === "pc" ? 6 : deviceType === "tablet" ? 4 : 2}
        py={deviceType === "pc" ? 4 : deviceType === "tablet" ? 3 : 2}
        bgcolor={theme.palette.primary.main}
        borderRadius={5}
        color={"white"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={deviceType === "mobile" ? "center" : "start"}
          alignItems={deviceType === "mobile" ? "center" : "start"}
          pb={deviceType !== "pc" ? 2 : 0}
        >
          <Typography
            textAlign={deviceType === "mobile" ? "center" : "start"}
            variant="h5"
            gutterBottom
          >
            My Jobs
          </Typography>
          <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec
            purus urna. Sed eget semper dolor, id venenatis quam. Maecenas id
            justo nec turpis
          </Typography>
        </Box>
      </Box>

      <Toolbar />
      {profile?.myJobs?.length > 0 ? (
        <Grid container spacing={2}>
          {postedJobs?.map((item: any, index: number) => (
            <Grid key={index} item xs={12} sm={12} md={6}>
              <MyPostedJobCard data={item} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          height={320}
          width={"100%"}
        >
          <img src={notFoundImage} alt="" />
          <Typography fontSize={13} textAlign={"center"}>
            No jobs posted yet
          </Typography>
        </Box>
      )}
    </Box>
  );
}
