import * as React from "react";
import {
  Box,
  Button,
  Card,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import googleBrand from "../../assets/images/google_logo.png";
import DividerText, {
  DividerLongText,
} from "../../components/divider/text_divider";
// import LoginForm from "../../components/forms/auth/login_form";
import LoginFormDialog, {
  MDialogProps,
} from "../../components/forms/auth/login_form2";

import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import APIService from "../../service";
import { toast } from "react-hot-toast";
import { useAppDispatch } from "../../utils/hooks/apphook";
import { setLoading } from "../../redux/reducers/loader";
import { setAuth, setProfile } from "../../redux/reducers/auth";

export default function LoginDialogContent(
  props: MDialogProps
): React.JSX.Element {
  const navigate = useNavigate();
  const { open, setOpen } = props;
  const [deviceType, setDeviceType] = React.useState("mobile");
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [user, setUser] = React.useState<any>();

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log("RESP:: ", codeResponse);

      setUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  React.useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then(async (res) => {
          console.log("AUTH DATA HERE ::: ", res.data);
          dispatch(setLoading(false));
          // NOw make API request to server
          try {
            const payload = {
              email: res.data?.email,
              firstname: res.data?.given_name,
              lastname: res.data?.family_name,
              name: res.data?.name,
              picture: res.data?.picture,
              id: res.data?.id,
            };
            const response = await APIService.post("/auth/google/web", payload);
            console.log("RESPONE AUTH GOOGLE :: ", response.data);

            // localStorage.setItem("x-toks", response.data?.token);
            dispatch(setAuth(true));
            dispatch(setProfile(response.data?.data));
            toast.success(`${response.data?.message}`);
            localStorage.setItem("x-toks", response?.data?.token);
            localStorage.setItem("auth-email", response.data?.data?.email);

            dispatch(setLoading(false));
            navigate("/dashboard/account");
          } catch (error) {
            console.log(error);
            dispatch(setLoading(false));
          }
          // setProfile(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet]);

  return (
    <Box bgcolor={"white"} height={"100%"}>
      <Box height={"100%"}>
        <Card>
          <Box
            padding={deviceType !== "mobile" ? 4 : 2}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"start"}
            alignItems={"center"}
          >
            <Typography mb={4} fontWeight={600} variant="h5" gutterBottom>
              Login to Your Prohelp Account
            </Typography>

            <LoginFormDialog open={open} setOpen={setOpen} />

            <Box mb={3} width={deviceType === "mobile" ? "100%" : "70%"}>
              <DividerText />
            </Box>

            <Button
              fullWidth
              variant="contained"
              sx={{
                mb: 4,
                borderRadius: 36,
                textTransform: "capitalize",
                p: 1,
              }}
              onClick={() => login()}
            >
              <Box
                bgcolor={"white"}
                width={32}
                height={32}
                p={2}
                borderRadius={16}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                position={"absolute"}
                left={2}
              >
                <img src={googleBrand} alt="" width={21} />
              </Box>
              <Typography>Continue with Google</Typography>
            </Button>

            <DividerLongText label=" Don't have an account?" />
            <Button
              variant="outlined"
              onClick={() => navigate("/signup")}
              sx={{
                borderRadius: 32,
                mt: 2,
                width: 128,
                textTransform: "capitalize",
              }}
            >
              Signup
            </Button>
          </Box>
        </Card>
      </Box>
    </Box>
  );
}
