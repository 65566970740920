import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";
import theme from "../../assets/theme/Theme";
import CustomContainer from "../../components/container";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quill.css'

interface Props {
  data: any
}

export default function Oxygen({ data }: Props): React.JSX.Element {
  //   const theme = useTheme();
  const [deviceType, setDeviceType] = React.useState("mobile");

  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  const modules: any = {
    toolbar: false
  };

  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet]);

  return (
    <Box
      paddingY={deviceType === "pc" ? 16 : deviceType === "tablet" ? 12 : 8}
    >
      <CustomContainer>
        <Grid
          container
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Grid item xs={12} sm={7} md={7} lg={8} padding={1}>
            <Box p={4} borderRadius={4} bgcolor={theme.palette.primary.light}>
               {/* Why businesses turn to ProHelp */}
              <Typography
                variant={
                  deviceType === "pc"
                    ? "h1"
                    : deviceType === "tablet"
                    ? "h3"
                    : "h4"
                }
                fontSize={
                  deviceType === "pc"
                    ? "4rem"
                    : deviceType === "tablet"
                    ? "3rem"
                    : "2rem"
                }
                fontWeight={600}
                gutterBottom={true}
              >
               {data?.title}
              </Typography>
              
              <ReactQuill readOnly={true} value={data?.content}  modules={modules} style={{ border: 'none' }} />
          
            </Box>
          </Grid>
          <Grid item xs={12} sm={5} md={5} lg={4} p={1}>
            <Box
              p={4}
              height={"100%"}
              color={"white"}
              borderRadius={4}
              display={"flex"}
              alignItems={"center"}
              flexDirection={"column"}
              justifyContent={"end"}
              bgcolor={theme.palette.primary.main}
            >
              <Box
                height={"75%"}
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
                justifyContent={"end"}
                pb={2}
              >
                <Typography variant="h4" fontWeight={600} gutterBottom>
                  We're No1 in credibility and reliability.
                </Typography>
                <Typography>
                  Prohelp was developed to solve identity verification problem
                  faced by other platforms
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CustomContainer>
    </Box>
  );
}
