import React from "react";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
// import image from "../../assets/images/smiling_lady.png";
// import SearchField from "../../components/inputs/search_field";
import useMediaQuery from "@mui/material/useMediaQuery";
import banner from "../../assets/images/banner.jpg";
import banner2 from "../../assets/images/rodion.jpg";
import womanCorporate from "../../assets/images/woman_corporate.png";
import person1 from "../../assets/images/person_chef.png";
import person2 from "../../assets/images/person_cleaner.png";
import person3 from "../../assets/images/person_gardener.png";
import person4 from "../../assets/images/person_driver.avif";
import banner3 from "../../assets/images/banner3.jpg";
import joyfulCleaners from "../../assets/images/joyful_cleaners.webp";

import FreeSolo from "../../components/inputs/auto_complete";
import { useAppSelector } from "../../utils/hooks/apphook";

const bgItems = [
  { bgImage: banner, image: person4 },
  { bgImage: banner2, image: person1 },
  { bgImage: banner3, image: person3 },
  { bgImage: joyfulCleaners, image: person2 },
];

export default function Header() {
  const [deviceType, setDeviceType] = React.useState("mobile");
  const [currentBg, setCurrentBg] = React.useState(banner);
  const [homeData, setHomeData] = React.useState<any>();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  const { banners } = useAppSelector((state) => state.cms);

  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * bgItems.length);
      // setBgImage(getRandomImage);
      setCurrentBg(bgItems[randomIndex].bgImage);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  React.useEffect(() => {
    if (banners) {
      const home = banners?.filter((item: any) => item?.page === "home");
      console.log("EXPLORE DTA :: ", home);
      setHomeData(home[0]);
    }
  }, [banners])

  return (
    <Box
      height={
        deviceType === "pc" ? "80vh" : deviceType === "tablet" ? "60vh" : "75vh"
      }
      marginTop={-8}
      sx={{
        backgroundImage: "url(" + currentBg + ")",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        style={{ width: "100%", height: "100%", backgroundColor: "#00000091" }}
      >
        <Container maxWidth={false} sx={{ height: "100%", zIndex: 1000 }}>
          <Grid
            sx={{ height: "100%" }}
            container
            spacing={2}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                gutterBottom
                fontSize={
                  deviceType === "pc"
                    ? "3.5rem"
                    : deviceType === "tablet"
                    ? "3.125rem"
                    : "2.25rem"
                }
                component="h1"
                color="white"
                fontWeight={700}
                textAlign={"left"}
                lineHeight={1.2}
              >
                {homeData?.title}
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                component="h1"
                color="white"
                textAlign={"left"}
                lineHeight={1.2}
              >
                {homeData?.description}
              </Typography>
              <FreeSolo />
            </Grid>
            {deviceType === "pc" && (
              <Grid
                sx={{ height: "100%" }}
                item
                xs={12}
                sm={12}
                md={6}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"end"}
              >
                <Box
                  width={"75%"}
                  height={"70%"}
                  px={0.5}
                  py={0.5}
                  borderRadius={4}
                  sx={{
                    background: "rgba(255,255,255,0.45)",
                    WebkitBackdropFilter: "blur(5px)",
                    backdropFilter: "blur(5px)",
                    border: "1px solid rgba(255,255,255,0.225)",
                  }}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"end"}
                  alignItems={"center"}
                >
                  <Box borderRadius={4} height={'100%'} width={'100%'} sx={{backgroundImage: 'url('+homeData?.featuredImage+')', backgroundRepeat: 'no-repeat',}} />
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </div>
    </Box>
  );
}
