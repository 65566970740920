import { Box, Skeleton } from "@mui/material";

export default function ApplicantShimmer() {
  return (
    <Box
    
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"start"}
    >
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"start"}
        alignItems={"center"}
      >
        <Skeleton variant="circular" height={64} animation="wave" width={72} />
        <Box width={"100%"}>
          <Skeleton sx={{ mx: 2 }} height={18} animation="wave" width={"75%"} />
          <Skeleton sx={{ mx: 2 }} height={18} animation="wave" width={"50%"} />
        </Box>
      </Box>
      <Box width={"100%"} py={1}>
        <Box
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
  
        >
          <Skeleton
            variant="rounded"
            animation="wave"
            height={40}
            sx={{  flex: 1 }}
            width={"30%"}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            height={40}
            width={"30%"}
            sx={{ mx: 2, flex: 1 }}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            height={40}
            width={"30%"}
            sx={{  flex: 1 }}
          />
        </Box>
      </Box>
    </Box>
  );
}
