import {
  Avatar,
  Box,
  Button,
  IconButton,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import RoundedButton from "../button/round_button";

import formatDistance from "date-fns/formatDistance";
import { Bookmark, BookmarkBorderOutlined, LocationOnOutlined } from "@mui/icons-material";
import { useAppSelector } from "../../utils/hooks/apphook";
import { BrandedDialog } from "../dialog";
import ApplyJob from "../../pages/jobs/apply_job";
import APIService from "../../service";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/reducers/loader";

interface Props {
  data?: any;
  item: any;
  usecase?: string | null;
  isLoading: boolean;
}

interface DateOptions {
  includeSeconds: boolean;
  addSuffix: boolean;
}

export default function JobCard(props: Props) {
  let { item, isLoading, usecase, data } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [deviceType, setDeviceType] = React.useState("mobile");
  const [open, setOpen] = React.useState(false);
  const [isApplied, setApplied] = React.useState(false);
  const [isSaved, setSaved] = React.useState(false);
  const [applicationCount, setApplicationCount] = React.useState(0);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  const myAppliedJobs = useAppSelector((state) => state.jobs.myJobApplications);
  const {isAuth, profile} = useAppSelector((state) => state.auth);

  React.useEffect(() => {
    if (myAppliedJobs) {
      myAppliedJobs?.forEach((elem: any) => {
        if (usecase) {
            setApplied(true);
        }
        
      });
    }
    
  }, [item?.id, myAppliedJobs, usecase]);


  React.useEffect(() => {
    if (profile) {
      profile?.savedJobs?.forEach((elem: any) => {
        if (elem === item?.id) {
          setSaved(true);
         }
      }); 
    }
  }, [item?.id, profile])

  React.useEffect(() => {
    if (isAuth) {
      APIService.fetcher(
        "/job/applications/" + profile?.email + "?jobId=" + item?.id
      )
        .then((resp) => {
          // console.log("M DATAT ++--->> ", resp?.data);
          setApplicationCount(resp?.docs?.length);
        })
        .catch((error) => {
          console.log("ON ERROR ==> ", error);
        });
    }
  }, [isAuth, item?.id, profile?.email]);

  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet]);


  let options: DateOptions = {
    addSuffix: true,
    includeSeconds: true,
  };

  const saveJob = async () => {
    try {
      dispatch(setLoading(true));

      const payload = {
        jobId: item?.id,
        userId: profile?.id,
      }

      const response = await APIService.post('/job/save/'+profile?.emailAddress, payload);
      console.log("SAVE JOB RESPONSE ::: ", response.data);

      dispatch(setLoading(false));
      
    } catch (error) {
      console.log("ERROR OCCURRDD ::: ", error);
      dispatch(setLoading(false));
    }
  }

  // let timeAgo: string = formatDistance(subDays(Date.parse({`${item?.createdAt}`})), new Date(), { addSuffix: true })}

  return (
    <Box
      borderRadius={6}
      border={"0.1px solid"}
      padding={deviceType === "pc" ? 3 : 2}
      height={deviceType === "pc" ? 386 : deviceType === "tablet" ? 325 : 300}
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      position={"relative"}
    >
      <BrandedDialog
        open={open}
        setOpen={setOpen}
        title={`Apply to ${item?.company}`}
        body={<ApplyJob jobData={item} setOpen={setOpen} />}
        width={"100%"}
      />
      {isLoading ? (
        <Skeleton animation="wave" height={10} width={"75%"} />
      ) : (
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} >
           <Typography
          fontWeight={500}
          textTransform={"capitalize"}
          fontSize="1.0rem"
          gutterBottom
        >
          {`${item?.jobTitle} (${item?.workplaceType ?? "Remote"})`}
        </Typography>

        <IconButton onClick={() => {
          saveJob();
        }} >
          {
            isSaved ?  <Bookmark color="primary"  /> :  <BookmarkBorderOutlined />
          }
        </IconButton>
        </Box>
       
      )}
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"start"}
        alignItems={"center"}
        py={0.5}
      >
        <IconButton onClick={() => navigate("")}>
          <Avatar
            alt="Remy Sharp"
            sx={{
              width: deviceType === "pc" ? 50 : 36,
              height: deviceType === "pc" ? 50 : 36,
            }}
            src={item?.recruiter?.bio?.image}
          />
        </IconButton>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"start"}
          alignItems={"start"}
        >
          <Typography fontSize={"1rem"} textTransform={"capitalize"}>
            {item?.recruiter?.name}
          </Typography>

          <Typography
            gutterBottom
            fontSize={"0.75rem"}
            textTransform={"capitalize"}
          >
            {`Posted  ${formatDistance(
              Date.parse(`${item?.createdAt}`),
              new Date(),
              options
            )}`}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography gutterBottom textTransform={"initial"}>
          {`${item?.description}`?.substring(0, 100) + "..."}
        </Typography>
      </Box>
      <Box
        width={"100%"}
        pb={1}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"start"}
          alignItems={"center"}
        >
          <LocationOnOutlined />
          <Typography
            textTransform={
              item?.jobLocation?.state?.startsWith("fct")
                ? "uppercase"
                : "capitalize"
            }
          >
            {`${item?.jobLocation?.state}`}
          </Typography>
        </Box>
        <Box>
          <Typography>{`${item?.applicants?.length} ${
            item?.applicants?.length > 1 ? "applicants" : "applicant"
          } `}</Typography>
        </Box>
      </Box>
      <Box
        flex={1}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"start"}
        alignItems={"end"}
      >
        <Button
          variant="outlined"
          sx={{
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            padding: "12px",
            width: 120,
            height: 40,
            borderRadius: "32px",
            textTransform: "capitalize",
            "&:hover": {
              background: "#131022",
              color: "white",
              border: "none",
            },
          }}
          onClick={() => {
            if (isAuth && profile) {
              navigate("/dashboard/jobs/" + item?.id, {
                state: { data: item, usecase: usecase },
              });
            } else {
              navigate("/jobs/" + item?.id, { state: { data: item } });
            }
            
          }}
        >
          See more
        </Button>
        {profile?.accountType !== "recruiter" && (
          <RoundedButton
            sx={{
              bgcolor: isApplied ? 'darkgray' : theme.palette.primary.main,
              color: "white",
              width: 120,
              height: 40,
              ml: 2,
            }}
            disabled={isApplied}
            onClick={() => {
              if (!isAuth) {
                navigate("/login");
              } else {
                setOpen(true);
              }
            }}
          >
            {isApplied ? "Applied" : "Apply"}
          </RoundedButton>
        )}
      </Box>
    </Box>
  );
}
