import {
  Avatar,
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../utils/hooks/apphook";
import PhoneInput from "react-phone-input-2";

export interface Props {
  values: any;
  getFieldProps: any;
  errors: any;
  touched: any;
  setFieldValue: any;
}

export default function ContactInfoStep({
  errors,
  getFieldProps,
  values,
  touched,
  setFieldValue,
}: Props) {
  const theme = useTheme();

  const userProfile = useAppSelector((state) => state.auth.profile);

  return (
    <Box
      py={4}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"center"}
    >
      <Box
        borderRadius={48}
        width={96}
        height={96}
        bgcolor={theme.palette.primary.main}
      >
        <Avatar
          alt="Remy Sharp"
          sx={{ width: 94, height: 94 }}
          src={userProfile?.bio?.image}
        />
      </Box>
      <Typography mt={2} color={theme.palette.primary.main} fontWeight={900}>
        {`${userProfile?.bio?.firstname} ${userProfile?.bio?.middlename} ${userProfile?.bio?.lastname}`}
      </Typography>
      <Toolbar />
      <TextField
        variant="outlined"
        label="First Name"
        size="small"
        fullWidth
        {...getFieldProps("firstname")}
        error={Boolean(touched.firstname && errors.firstname)}
        helperText={errors.firstname}
        sx={{ mb: 1.5 }}
      />

      <TextField
        variant="outlined"
        label="Last Name"
        size="small"
        fullWidth
        {...getFieldProps("lastname")}
        error={Boolean(touched.lastname && errors.lastname)}
        helperText={errors.lastname}
        sx={{ my: 1.5 }}
      />

      <TextField
        variant="outlined"
        label="Email Address"
        size="small"
        type="email"
        fullWidth
        {...getFieldProps("email")}
        error={Boolean(touched.email && errors.email)}
        helperText={errors.email}
        sx={{ my: 1.5 }}
      />

      <FormControl fullWidth sx={{ mt: 1.5 }}>
        <PhoneInput
          country={"ng"}
          value={values.phone}
          onChange={(phone) => {
            setFieldValue("phone", phone);
          }}
          placeholder="Enter phone number"
          inputProps={{
            name: "phone",
            required: true,
            size: "small",
            //   error: Boolean(touched.lastname && errors.lastname)}
            // helperText: {touched.lastname && errors.lastname}
          }}
          inputStyle={{ width: "100%" }}
          defaultErrorMessage="Phone number is required"
        />
        <FormHelperText>{errors.phone}</FormHelperText>
      </FormControl>
    </Box>
  );
}
