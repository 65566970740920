import {
  Box,
  Button,
  Card,
  IconButton,
  List,
  ListItemButton,
  Typography,
  useTheme,
} from "@mui/material";
import noDataImage from "../../assets/images/empty.png";
import { useAppSelector } from "../../utils/hooks/apphook";
import React from "react";
import AddEducation from "../../components/forms/education/add_education";
import { Add, DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import CustomizedDialog, {
  BrandedDialog,
  BrandedDialog as ItemUpdateDialog,
} from "../../components/dialog";
import UpdateEducation from "../../components/forms/education/update_education";
import RoundedButton from "../../components/button/round_button";
import { useDispatch } from "react-redux";
import { setProfile } from "../../redux/reducers/auth";
import { setLoading } from "../../redux/reducers/loader";
import toast from "react-hot-toast";
import APIService from "../../service";

export interface Props {
  education: any;
  from?: string;
  setOpen?: any;
  userProfile?: any;
}

interface EducationItemProps {
  item: any;
  handleUpdate: any;
  handleDelete: any;
  profile: any;
  from?: string;
}

function EducationItem({
  item,
  handleUpdate,
  handleDelete,
  profile,
  from,
}: EducationItemProps) {
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);

  return (
    <ListItemButton sx={{ paddingX: 0 }} divider>
      {/* Your item rendering code here */}
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"start"}
          alignItems={"center"}
        >
          <img src={item?.schoolLogo} alt="" width={70} height={70} />
          <Box
            pl={1}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"start"}
            alignItems={"start"}
          >
            <Typography
              fontWeight={600}
              variant="body1"
              lineHeight={1.0}
              textTransform={"capitalize"}
            >
              {`${item?.school}`}
            </Typography>
            <Typography
              fontWeight={500}
              variant="body2"
              textTransform={"capitalize"}
            >
              {`${item?.course} (${item?.degree})`}
            </Typography>
            <Typography
              fontWeight={500}
              variant="body2"
              textTransform={"capitalize"}
            >
              {`${
                item?.endate === undefined || item?.stillSchooling === true
                  ? "still a student"
                  : new Date(item?.endate).toLocaleDateString("en-GB")
              }`}
            </Typography>
          </Box>
        </Box>
        <Box
          display={from ? "flex" : "none"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"end"}
        >
          <IconButton onClick={() => setOpenUpdate(true)}>
            <EditOutlined />
          </IconButton>
          <ItemUpdateDialog
            open={openUpdate}
            setOpen={setOpenUpdate}
            width={"50%"}
            title={"Update Work Experience "}
            body={<UpdateEducation data={item} setOpen={setOpenUpdate} />}
          />
          <IconButton onClick={() => setOpenDelete(true)}>
            <DeleteOutlineOutlined />
          </IconButton>
          <CustomizedDialog
            body={
              <DeleteItemContent
                setOpen={setOpenDelete}
                education={item}
                userProfile={profile}
              />
            }
            open={openDelete}
            setOpen={setOpenDelete}
            title="Delete Confirmation"
            width={"40%"}
          />
        </Box>
      </Box>
    </ListItemButton>
  );
}

export default function EducationSection(props: Props) {
  let { education, from } = props;
  const [open, setOpen] = React.useState(false);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);

  const profile = useAppSelector((state: any) => state.auth.profile);

  return (
    <Card>
      <BrandedDialog
        open={open}
        setOpen={setOpen}
        title={
          education?.length > 0
            ? "Update Education Qualification "
            : "Add Education Qualification "
        }
        width={"50%"}
        body={
          <AddEducation
            experience={education}
            userProfile={profile}
            setOpen={setOpen}
          />
        }
      />
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"start"}
        alignItems={"start"}
        p={2}
      >
        <Box
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h6" fontWeight={500} gutterBottom>
            Education
          </Typography>
          {from === "myprofile" && education?.length > 0 && (
            <IconButton onClick={() => setOpen(true)}>
              <Add />
            </IconButton>
          )}
        </Box>
        {education?.length > 0 ? (
          <List disablePadding sx={{ width: "100%" }}>
            {education &&
              education?.map((item: any, index: number) => (
                <EducationItem
                  key={index}
                  item={item}
                  from={from}
                  profile={profile}
                  handleDelete={() => setOpenDelete(true)}
                  handleUpdate={() => setOpenUpdate(true)}
                />
              ))}
          </List>
        ) : (
          <Box
            p={4}
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <img src={noDataImage} alt="" width={100} />
            <Typography>No data found</Typography>
            {from && (
              <Button
                startIcon={<Add />}
                sx={{ textTransform: "capitalize" }}
                onClick={() => setOpen(true)}
              >
                Add education
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Card>
  );
}

const DeleteItemContent = ({ education, setOpen, userProfile }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <Box
      p={2}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"center"}
    >
      <Typography fontSize={14} gutterBottom pb={2}>
        {"Are you sure you want to delete this experience?"}
      </Typography>
      <RoundedButton
        fullWidth
        variant="contained"
        sx={{ bgcolor: theme.palette.primary.main, color: "white" }}
        onClick={async () => {
          setOpen(false);
          // Delete here
          const updatedEducations = userProfile?.education?.filter(
            (edu: any) => edu.school !== education?.school
          );

          await setTimeout(() => {
            console.log("Just do nothing ...");
          }, 4000);

          const payload = {
            education: updatedEducations,
          };

          try {
            const res = await APIService.update(
              "/updateuser",
              userProfile?.email,
              {
                ...payload,
              }
            );

            dispatch(setProfile(res.data.data));
            dispatch(setLoading(false));
            toast.success(`${res.data.message}`);
          } catch (error: any) {
            dispatch(setLoading(false));
            console.log(
              "ERROR HERE >>> ",
              `${
                error?.response?.data?.message ||
                error?.message ||
                "Something went wrong, try again."
              }`
            );
          }
        }}
      >
        Proceed
      </RoundedButton>
      <Button fullWidth onClick={() => setOpen(false)} sx={{ my: 2 }}>
        Cancel
      </Button>
    </Box>
  );
};
