import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import ContactInfoStep from "./steps/step1";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../utils/hooks/apphook";
import RoundedButton from "../../components/button/round_button";
import { useTheme } from "@mui/material";
import QuestionsResumeStep from "./steps/step2";
import ReviewStep from "./steps/step3";
import { setLoading } from "../../redux/reducers/loader";

import {
  storage,
  uploadBytesResumable,
  getDownloadURL,
} from "../../utils/firebase-config";
import { ref } from "firebase/storage";
import APIService from "../../service";
import { toast } from "react-hot-toast";
import { useSWRConfig } from "swr";

interface InitialState {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  answer1: string;
  answer2: string;
  answer3: string;
  answer4: string;
  answer5: string;
  answer6: string;
  answer7: string;
  answer8: string;
  answer9: string;
  answer10: string;
  resume: string;
}

interface Props {
  jobData: any;
  setOpen: any;
}

const steps = ["Contact Info", "Additional Questions", "Review"];

export default function ApplyJob({ jobData, setOpen }: Props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [file, setFile] = React.useState<any>(null); // Resume file
  //   const [skipped, setSkipped] = React.useState(new Set<number>());
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const userProfile = useAppSelector((state) => state.auth.profile);

  const { mutate } = useSWRConfig();

  const step1Schema = Yup.object().shape({
    firstname: Yup.string().required("Firstname is required"),
    lastname: Yup.string().required("Lastname is required"),
    email: Yup.string()
      .email("Email address is not valid")
      .required("Email address is required"),
    phone: Yup.string().required("phone number is required"),
  });

  const step2Schema1 = Yup.object().shape({
    answer1: Yup.string().required("Answer is required!"),
    resume: Yup.string().required("Your resume is required!"),
  });

  const step2Schema2 = Yup.object().shape({
    answer1: Yup.string().required("Answer is required!"),
    answer2: Yup.string().required("Answer is required!"),
    resume: Yup.string().required("Your resume is required!"),
  });

  const step2Schema3 = Yup.object().shape({
    answer1: Yup.string().required("Answer is required!"),
    answer2: Yup.string().required("Answer is required!"),
    answer3: Yup.string().required("Answer is required!"),
    resume: Yup.string().required("Your resume is required!"),
  });

  const step2Schema4 = Yup.object().shape({
    answer1: Yup.string().required("Answer is required!"),
    answer2: Yup.string().required("Answer is required!"),
    answer3: Yup.string().required("Answer is required!"),
    answer4: Yup.string().required("Answer is required!"),
    resume: Yup.string().required("Your resume is required!"),
  });

  const step2Schema5 = Yup.object().shape({
    answer1: Yup.string().required("Answer is required!"),
    answer2: Yup.string().required("Answer is required!"),
    answer3: Yup.string().required("Answer is required!"),
    answer4: Yup.string().required("Answer is required!"),
    answer5: Yup.string().required("Answer is required!"),
    resume: Yup.string().required("Your resume is required!"),
  });

  const step2Schema6 = Yup.object().shape({
    answer1: Yup.string().required("Answer is required!"),
    answer2: Yup.string().required("Answer is required!"),
    answer3: Yup.string().required("Answer is required!"),
    answer4: Yup.string().required("Answer is required!"),
    answer5: Yup.string().required("Answer is required!"),
    answer6: Yup.string().required("Answer is required!"),
    resume: Yup.string().required("Your resume is required!"),
  });

  const step2Schema7 = Yup.object().shape({
    answer1: Yup.string().required("Answer is required!"),
    answer2: Yup.string().required("Answer is required!"),
    answer3: Yup.string().required("Answer is required!"),
    answer4: Yup.string().required("Answer is required!"),
    answer5: Yup.string().required("Answer is required!"),
    answer6: Yup.string().required("Answer is required!"),
    answer7: Yup.string().required("Answer is required!"),
    resume: Yup.string().required("Your resume is required!"),
  });

  const step2Schema8 = Yup.object().shape({
    answer1: Yup.string().required("Answer is required!"),
    answer2: Yup.string().required("Answer is required!"),
    answer3: Yup.string().required("Answer is required!"),
    answer4: Yup.string().required("Answer is required!"),
    answer5: Yup.string().required("Answer is required!"),
    answer6: Yup.string().required("Answer is required!"),
    answer7: Yup.string().required("Answer is required!"),
    answer8: Yup.string().required("Answer is required!"),
    resume: Yup.string().required("Your resume is required!"),
  });

  const step2Schema9 = Yup.object().shape({
    answer1: Yup.string().required("Answer is required!"),
    answer2: Yup.string().required("Answer is required!"),
    answer3: Yup.string().required("Answer is required!"),
    answer4: Yup.string().required("Answer is required!"),
    answer5: Yup.string().required("Answer is required!"),
    answer6: Yup.string().required("Answer is required!"),
    answer7: Yup.string().required("Answer is required!"),
    answer8: Yup.string().required("Answer is required!"),
    answer9: Yup.string().required("Answer is required!"),
    resume: Yup.string().required("Your resume is required!"),
  });

  const step2Schema10 = Yup.object().shape({
    answer1: Yup.string().required("Answer is required!"),
    answer2: Yup.string().required("Answer is required!"),
    answer3: Yup.string().required("Answer is required!"),
    answer4: Yup.string().required("Answer is required!"),
    answer5: Yup.string().required("Answer is required!"),
    answer6: Yup.string().required("Answer is required!"),
    answer7: Yup.string().required("Answer is required!"),
    answer8: Yup.string().required("Answer is required!"),
    answer9: Yup.string().required("Answer is required!"),
    answer10: Yup.string().required("Answer is required!"),
    resume: Yup.string().required("Your resume is required!"),
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const answersGetter = (values: any) => {
    const answers = [];

    switch (jobData?.screeningQuestions.length) {
      case 1:
        const obj = {
          question: jobData?.screeningQuestions[0],
          answer: values.answer1,
        };

        answers.push(obj);

        return answers;
      case 2:
        const objr = [
          {
            question: jobData?.screeningQuestions[0],
            answer: values.answer1,
          },
          {
            question: jobData?.screeningQuestions[1],
            answer: values.answer2,
          },
        ];
        answers.push(...objr);
        return answers;
      case 3:
        const obj3 = [
          {
            question: jobData?.screeningQuestions[0],
            answer: values.answer1,
          },
          {
            question: jobData?.screeningQuestions[1],
            answer: values.answer2,
          },
          {
            question: jobData?.screeningQuestions[2],
            answer: values.answer3,
          },
        ];
        answers.push(...obj3);
        return answers;
      case 4:
        const obj4 = [
          {
            question: jobData?.screeningQuestions[0],
            answer: values.answer1,
          },
          {
            question: jobData?.screeningQuestions[1],
            answer: values.answer2,
          },
          {
            question: jobData?.screeningQuestions[2],
            answer: values.answer3,
          },
          {
            question: jobData?.screeningQuestions[3],
            answer: values.answer4,
          },
        ];
        answers.push(...obj4);
        return answers;
      case 5:
        const obj5 = [
          {
            question: jobData?.screeningQuestions[0],
            answer: values.answer1,
          },
          {
            question: jobData?.screeningQuestions[1],
            answer: values.answer2,
          },
          {
            question: jobData?.screeningQuestions[2],
            answer: values.answer3,
          },
          {
            question: jobData?.screeningQuestions[3],
            answer: values.answer4,
          },
          {
            question: jobData?.screeningQuestions[4],
            answer: values.answer5,
          },
        ];
        answers.push(...obj5);
        return answers;
      case 6:
        const obj6 = [
          {
            question: jobData?.screeningQuestions[0],
            answer: values.answer1,
          },
          {
            question: jobData?.screeningQuestions[1],
            answer: values.answer2,
          },
          {
            question: jobData?.screeningQuestions[2],
            answer: values.answer3,
          },
          {
            question: jobData?.screeningQuestions[3],
            answer: values.answer4,
          },
          {
            question: jobData?.screeningQuestions[4],
            answer: values.answer5,
          },
          {
            question: jobData?.screeningQuestions[5],
            answer: values.answer6,
          },
        ];
        answers.push(...obj6);
        return answers;
      case 7:
        const obj7 = [
          {
            question: jobData?.screeningQuestions[0],
            answer: values.answer1,
          },
          {
            question: jobData?.screeningQuestions[1],
            answer: values.answer2,
          },
          {
            question: jobData?.screeningQuestions[2],
            answer: values.answer3,
          },
          {
            question: jobData?.screeningQuestions[3],
            answer: values.answer4,
          },
          {
            question: jobData?.screeningQuestions[4],
            answer: values.answer5,
          },
          {
            question: jobData?.screeningQuestions[5],
            answer: values.answer6,
          },
          {
            question: jobData?.screeningQuestions[6],
            answer: values.answer7,
          },
        ];
        answers.push(...obj7);
        return answers;
      case 8:
        const obj8 = [
          {
            question: jobData?.screeningQuestions[0],
            answer: values.answer1,
          },
          {
            question: jobData?.screeningQuestions[1],
            answer: values.answer2,
          },
          {
            question: jobData?.screeningQuestions[2],
            answer: values.answer3,
          },
          {
            question: jobData?.screeningQuestions[3],
            answer: values.answer4,
          },
          {
            question: jobData?.screeningQuestions[4],
            answer: values.answer5,
          },
          {
            question: jobData?.screeningQuestions[5],
            answer: values.answer6,
          },
          {
            question: jobData?.screeningQuestions[6],
            answer: values.answer7,
          },
          {
            question: jobData?.screeningQuestions[7],
            answer: values.answer8,
          },
        ];
        answers.push(...obj8);
        return answers;
      case 9:
        const obj9 = [
          {
            question: jobData?.screeningQuestions[0],
            answer: values.answer1,
          },
          {
            question: jobData?.screeningQuestions[1],
            answer: values.answer2,
          },
          {
            question: jobData?.screeningQuestions[2],
            answer: values.answer3,
          },
          {
            question: jobData?.screeningQuestions[3],
            answer: values.answer4,
          },
          {
            question: jobData?.screeningQuestions[4],
            answer: values.answer5,
          },
          {
            question: jobData?.screeningQuestions[5],
            answer: values.answer6,
          },
          {
            question: jobData?.screeningQuestions[6],
            answer: values.answer7,
          },
          {
            question: jobData?.screeningQuestions[7],
            answer: values.answer8,
          },
          {
            question: jobData?.screeningQuestions[8],
            answer: values.answer9,
          },
        ];
        answers.push(...obj9);
        return answers;
      case 10:
        const obj10 = [
          {
            question: jobData?.screeningQuestions[0],
            answer: values.answer1,
          },
          {
            question: jobData?.screeningQuestions[1],
            answer: values.answer2,
          },
          {
            question: jobData?.screeningQuestions[2],
            answer: values.answer3,
          },
          {
            question: jobData?.screeningQuestions[3],
            answer: values.answer4,
          },
          {
            question: jobData?.screeningQuestions[4],
            answer: values.answer5,
          },
          {
            question: jobData?.screeningQuestions[5],
            answer: values.answer6,
          },
          {
            question: jobData?.screeningQuestions[6],
            answer: values.answer7,
          },
          {
            question: jobData?.screeningQuestions[7],
            answer: values.answer8,
          },
          {
            question: jobData?.screeningQuestions[8],
            answer: values.answer9,
          },
          {
            question: jobData?.screeningQuestions[9],
            answer: values.answer10,
          },
        ];
        answers.push(...obj10);
        return answers;
      default:
        return [];
    }
  };

  const apply = async () => {
    try {
      dispatch(setLoading(true));
      // Upload resume to firebase first
      let storageRef = ref(
        storage,
        "resumes/" + userProfile.id + "_" + values.resume
      );
      let uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const uprogress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // setProgress(uprogress);
        },
        (error) => {
          // setIsUploading(false);
          console.log(error);
          // enqueueSnackbar(`${error.message}`, { variant: "error" });
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            const payload = {
              job: jobData,
              jobId: jobData?.id,
              applicant: {
                id: userProfile?.id,
                name: `${userProfile?.bio?.firstname} ${userProfile?.bio?.middlename} ${userProfile?.bio?.lastname}`,
                photo: userProfile?.bio?.image,
                email: userProfile.email,
                phone: userProfile.bio.phone,
              },
              resume: downloadURL,
              answers: answersGetter(values),
            };

            try {
              const res = await APIService.post(
                "/job/apply/" + userProfile?.email,
                {
                  ...payload,
                }
              );

              console.log("RESP HERE >>> ", `${res}`);
              // dispatch(setProfile(res.data.data));
              mutate('/job/all/')
              mutate('/user/'+userProfile?.email)
              mutate('/job/applications/byUser/'+userProfile?.email)
              
              
              setOpen(false);
              dispatch(setLoading(false));

              toast.success(`${res?.data?.message}`);
            } catch (error: any) {
              dispatch(setLoading(false));
              console.log(
                "ERROR HERE >>> ",
                `${
                  error?.response?.data?.message ||
                  error?.message ||
                  "Something went wrong, try again."
                }`
              );
            }
          });
        }
      );
    } catch (error) {
      console.log("DF :: ", error);
      dispatch(setLoading(false));
    }
  };

  const initialValues: InitialState = {
    firstname: userProfile?.bio?.firstname,
    lastname: userProfile?.bio?.lastname,
    email: userProfile?.email,
    phone: userProfile?.bio?.phone,
    answer1: "",
    answer2: "",
    answer3: "",
    answer4: "",
    answer5: "",
    answer6: "",
    answer7: "",
    answer8: "",
    answer9: "",
    answer10: "",
    resume: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema:
      activeStep === 0
        ? step1Schema
        : activeStep === 1
        ? jobData?.screeningQuestions.length === 1
          ? step2Schema1
          : jobData?.screeningQuestions.length === 2
          ? step2Schema2
          : jobData?.screeningQuestions.length === 3
          ? step2Schema3
          : jobData?.screeningQuestions.length === 4
          ? step2Schema4
          : jobData?.screeningQuestions.length === 5
          ? step2Schema5
          : jobData?.screeningQuestions.length === 6
          ? step2Schema6
          : jobData?.screeningQuestions.length === 7
          ? step2Schema7
          : jobData?.screeningQuestions.length === 8
          ? step2Schema8
          : jobData?.screeningQuestions.length === 9
          ? step2Schema9
          : step2Schema10
        : null,
    onSubmit: () => {
      handleNext();
      //   if (activeStep === 0) {
      //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
      if (activeStep === 2) {
        console.log("TESTING >> ", answersGetter(values));
        apply();
      }
      //   alert("hj jh");

      //   }
    },
  });

  const {
    values,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    handleSubmit,
  } = formik;

  return (
    <Box sx={{ p: 4 }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};

          //   if (isStepSkipped(index)) {
          //     stepProps.completed = false;
          //   }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {
        <React.Fragment>
          {/* <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}> */}
          <Box display={"flex"} flexDirection={"column"}>
            {activeStep === 0 ? (
              <ContactInfoStep
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                getFieldProps={getFieldProps}
              />
            ) : activeStep === 1 ? (
              <QuestionsResumeStep
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                getFieldProps={getFieldProps}
                jobData={jobData}
                setFile={setFile}
              />
            ) : (
              <ReviewStep
                formData={{
                  email: values.email,
                  phone: values.phone,
                  resume: values.resume,
                  question1: jobData?.screeningQuestions[0],
                  answer1: values.answer1,
                  answer2: values.answer2,
                  answer3: values.answer3,
                  answer4: values.answer4,
                  answer5: values.answer5,
                  answer6: values.answer6,
                  answer7: values.answer7,
                  answer8: values.answer8,
                  answer9: values.answer9,
                  answer10: values.answer10,
                }}
                questions={jobData?.screeningQuestions}
              />
            )}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />

            <RoundedButton
              variant="contained"
              sx={{
                bgcolor: theme.palette.primary.main,
                color: "white",
                px: 6,
              }}
              onClick={() => handleSubmit()}
            >
              {activeStep === steps.length - 1 ? "Submit Application" : "Next"}
            </RoundedButton>
          </Box>
          {/* </Form>
          </FormikProvider> */}
        </React.Fragment>
      }
    </Box>
  );
}
