import {
  ArrowDropDown,
  BarChart,
  InfoOutlined,
  MessageRounded,
  Notifications,
  Person,
  SupportAgent,
  WindowOutlined,
  WorkOutline,
} from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Drawer,
  IconButton,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { SearchFieldTop } from "../../components/inputs/search_field";
import { setAuth, setProfile } from "../../redux/reducers/auth";
import { setLoading } from "../../redux/reducers/loader";
import { useAppDispatch, useAppSelector } from "../../utils/hooks/apphook";
// import brand from "../../assets/images/longo_light.svg";
import brandDark from "../../assets/images/longo_dark.svg";
import { CustomLink } from "./main_navbar";
import CustomContainer from "../../components/container";

import "./pro.css";
import { BrandedDialog } from "../../components/dialog";
import ContactSupport from "../../components/forms/support";
import Search from "@mui/icons-material/Search";

import drawerIcon from "../../assets/images/menu_icon.svg";
import RoundedButton from "../../components/button/round_button";

const settings = ["Account", "Homepage", "Logout"];
const resources = [
  { title: "Overview", to: "/dashboard/reports/" },
  { title: "Transactions history", to: "/dashboard/reports/transactions" },
];

export default function ProfessionalNavbar() {
  const theme = useTheme();
  const navigate = useNavigate();
  const currlocation = useLocation();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  // const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [openContact, setOpenContact] = React.useState(false);
  const [deviceType, setDeviceType] = React.useState("mobile");
  // const isAuth = useAppSelector((state) => state.auth.isAuth);
  const profile = useAppSelector((state) => state.auth.profile);

  const dispatch = useAppDispatch();

  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet]);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    dispatch(setLoading(true));
    try {
      setTimeout(() => {
        localStorage.clear();
        dispatch(setAuth(false));
        dispatch(setProfile(null));
        dispatch(setLoading(false));
        toast.success("Logged out successfully");
        navigate("/");
      }, 2000);
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      elevation={1.0}
      position="fixed"
      sx={{
        backgroundColor: "white",
        display: { xs: "none", sm: "none", md: "block" },
      }}
    >
      <BrandedDialog
        open={openContact}
        setOpen={setOpenContact}
        body={<ContactSupport setOpen={setOpenContact} />}
        title="Contact Support"
        width={500}
      />

      <CustomContainer>
        <Toolbar>
          {currlocation.pathname === `/dashboard/message/` &&
          deviceType !== "pc" ? (
            <></>
          ) : (
            <img
              src={brandDark}
              alt=""
              width={128}
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            />
          )}

          <CustomLink
            to={"/dashboard/explore"}
            sx={{
              pr: 1,
              pl: 2,
              color:
                currlocation.pathname === "/dashboard/explore"
                  ? theme.palette.primary.main
                  : "black",
            }}
          >
            Find Work
          </CustomLink>

          <CustomLink
            to={"/dashboard/connections"}
            sx={{
              px: 1,
              color:
                currlocation.pathname === "/dashboard/connections"
                  ? theme.palette.primary.main
                  : "black",
            }}
          >
            Connections
          </CustomLink>

          {/* <CustomLink
            to={"/dashboard/report"}
            sx={{
              px: 1,
              color:
                currlocation.pathname === "/dashboard/report"
                  ? theme.palette.primary.main
                  : "black",
            }}
          >
            Reports
          </CustomLink> */}
          <div className="dropdown">
            <Button
              className="dropbtn"
              endIcon={<ArrowDropDown sx={{ ml: -1 }} />}
              sx={{
                display: "flex",
                flexDirection: "row",
                color: currlocation.pathname.startsWith("/dashboard/reports")
                  ? theme.palette.primary.main
                  : "black",
                textTransform: "capitalize",
              }}
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
            >
              Reports
            </Button>
            <div className="dropdown-content">
              {resources?.map((elem, index) => (
                <MenuItem
                  key={index}
                  divider={true}
                  sx={{ fontSize: 14 }}
                  onClick={(e) => {
                    handleClose();
                    navigate(elem?.to);
                  }}
                >
                  {elem.title}
                </MenuItem>
              ))}
            </div>
          </div>

          <CustomLink
            to={"/dashboard/message"}
            sx={{
              px: 1,
              color:
                currlocation.pathname === "/dashboard/message"
                  ? theme.palette.primary.main
                  : "black",
            }}
          >
            Messages
          </CustomLink>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {<SearchFieldTop />}
            <IconButton
              size="large"
              aria-label="sh"
              color="inherit"
              sx={{ ml: 2 }}
              onClick={() => setOpenContact(true)}
            >
              <SupportAgent sx={{ color: "black" }} />
            </IconButton>
            {/* <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              sx={{ mr: 2, ml: 1 }}
            >
              <Badge badgeContent={17} color="error">
                <Notifications sx={{ color: "black" }} />
              </Badge>
            </IconButton>
             */}
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src={profile?.bio?.image} />
              </IconButton>
            </Tooltip> 
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => {
                    handleCloseUserMenu();
                    if (setting === "Logout") {
                      logout();
                    } else if (setting === "Homepage") {
                      navigate("/");
                    } else {
                      navigate("/dashboard/account");
                      setSelectedIndex(5);
                    }
                  }}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </CustomContainer>
    </AppBar>
  );
}

export const ProfessionalNavbarMobile = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const profile = useAppSelector((state) => state.auth.profile);

  const toggleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      setOpenDrawer(!openDrawer);
    };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const logout = () => {
    dispatch(setLoading(true));
    try {
      setTimeout(() => {
        localStorage.clear();
        dispatch(setAuth(false));
        dispatch(setProfile(null));
        dispatch(setLoading(false));
        toast.success("Logged out successfully");
        navigate("/");
      }, 2000);
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  };

  const drawerListItems = [
    {
      title: "Explore",
      route: "/dashboard/explore",
      icon: WindowOutlined,
      key: "Explore",
    },
    {
      title: "Jobs",
      route: "/dashboard/jobs",
      icon: WorkOutline,
      key: "jobs",
    },
    {
      title: "Message",
      route: "/dashboard/message",
      icon: MessageRounded,
      key: "message",
    },
    {
      title: "My Profile",
      route: "/dashboard/my-profile",
      icon: Person,
      key: "myprofile",
    },
    {
      title: "Reports",
      route: "/dashboard/reports",
      icon: BarChart,
      key: "reports",
    },
    {
      title: "About us",
      route: "/dashboard/about",
      icon: InfoOutlined,
      key: "about",
    },
  ];

  const handleItemClick = (
    e: React.MouseEvent<HTMLElement>,
    index: number,
    route: string
  ) => {
    // setSelectedIndex(index);
    // toggleDrawer(anchor, false);
    // setState({ ...state, [anchor]: false });
    setOpenDrawer(false);
    navigate(route);
  };

  const list = () => (
    <Box width={"100%"}>
      {drawerListItems?.map((data, index) => (
        <ListItemButton
          key={index}
          divider
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "white",
            my: 1,
          }}
          onClick={(e) => handleItemClick(e, index, data?.route)}
        >
          <data.icon />
          <ListItemText primary={data.title} sx={{ pl: 2 }} />
        </ListItemButton>
      ))}
    </Box>
  );

  return (
    <AppBar
      elevation={1.0}
      position="fixed"
      sx={{
        backgroundColor: "white",
        display: { xs: "block", sm: "block", md: "none" },
      }}
    >
      <Box pl={2}>
        <Toolbar>
          <img
            src={brandDark}
            alt=""
            width={100}
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          />

          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              flexDirection: "row",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            
            <IconButton
              size="large"
              aria-label="show 17 new "
              color="inherit"
              onClick={() => setOpenDrawer(!openDrawer)}
            >
              <img
                src={drawerIcon}
                alt=""
                style={{ color: "black" }}
                width={21}
              />
            </IconButton>
          </Box>
        </Toolbar>
      </Box>
      <Drawer
        anchor={"right"}
        open={openDrawer}
        sx={{
          width: 275,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        onClose={toggleDrawer()}
      >
        <Box
          width={275}
          pb={4}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"start"}
            alignItems={"center"}
          >
            <Box
              py={4}
              px={2}
              width={"100%"}
              bgcolor={theme.palette.primary.main}
              display="flex"
              flexDirection={"row"}
              justifyContent={"start"}
              alignItems={"center"}
            >
              <Avatar
                alt="Remy Sharp"
                src={profile?.bio?.image}
                sx={{ width: 56, height: 56 }}
              />
              <Box
                p={1}
                display="flex"
                flexDirection={"column"}
                justifyContent={"start"}
                alignItems={"start"}
              >
                <Typography color={"white"}>
                  {`${profile?.bio?.firstname} ${profile?.bio?.lastname}`}
                </Typography>
                <Typography
                  fontSize={12}
                  color={"white"}
                >{`${profile?.email}`}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box p={1}>{list()}</Box>
        <Box
          pb={4}
          flex={1}
          px={3}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <RoundedButton
            fullWidth
            variant="outlined"
            sx={{
              borderColor: theme.palette.primary.main,
              color: theme.palette.primary.main,
              my: 2,
              height: 48,
            }}
            onClick={() => navigate("/")}
          >
            View Site
          </RoundedButton>

          <RoundedButton
            fullWidth
            variant="contained"
            sx={{
              bgcolor: theme.palette.primary.main,
              color: "white",
              height: 48,
            }}
            onClick={() => logout()}
          >
            Logout
          </RoundedButton>
        </Box>
      </Drawer>
    </AppBar>
  );
};
