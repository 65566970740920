import * as React from "react";
import Header from "../layouts/headers/";
import TrustedBy from "../layouts/sections/trusted_by";
import Oxygen from "../layouts/sections/oxygen";
import Slide from "../components/slide/slider";
import PopularJobs from "../layouts/sections/popular_jobs";
import { useAppSelector } from "../utils/hooks/apphook";
import { RootState } from "../redux/store";
import FullFlex from "../layouts/sections/full_flex";
import { Box } from "@mui/material";
import Testimonial from "../layouts/sections/testimonial";
import CallToAction from "../layouts/sections/cta";

export default function RootPage() {
  const sections = useAppSelector((state: RootState) => state.cms.sections);
  const [homeSections, setHomeSections] = React.useState<any>([]);

  React.useEffect(() => {
    if (sections) {
      const filterHomeSections = sections?.filter((item: any) => item?.page === "home");
      setHomeSections(filterHomeSections);
    }
  }, [sections])

  // console.log("HOME SECTIONS ", sections);
  

  return (
    <div>
      <Header />
      <Slide /> 
      <TrustedBy />
      <PopularJobs />
      {
        homeSections && <Box>
          {
            homeSections?.map((item: any, index: number) => {
              return item?.template === "full-flex" ? <FullFlex key={index}  data={item} /> : item?.template === "oxygen" ?  <Oxygen data={item} /> : item?.template === "testimonial" ?  <Testimonial data={item} />  : item?.template === "call to action" ?  <CallToAction data={item} />  : <div />
            })
          }
        </Box>
      }
      {/* <Oxygen data={} /> */}
    </div>
  );
}
