import {
  Avatar,
  Box,
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import theme from "../../assets/theme/Theme";
import { useAppDispatch, useAppSelector } from "../../utils/hooks/apphook";
import { SearchFieldTop } from "../../components/inputs/search_field";

import notfound from "../../assets/images/empty.png";

import CustomTextField from "../../components/inputs/text_field";
import ChatShimmer from "../../components/skeleton/chat_shimmer";
import {
  setCurrentGuest,
  setSelectedConversation,
} from "../../redux/reducers/messages";
import ChatBox from "./chatbox";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface ChatProps {
  data: any;
  profile: any;
}

export default function MessageCenter() {
  const [deviceType, setDeviceType] = React.useState("mobile");
  const [isLoading, setLoading] = React.useState(true);
  const [fetchAgain, setFetchAgain] = React.useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const conversations = useAppSelector((state) => state.messages.conversations);
  const profile = useAppSelector((state) => state.auth.profile);
  const selectedConversation = useAppSelector(
    (state) => state.messages.selectedConversation
  );

  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));

  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  console.log("CONVERSATIONS :: ", conversations);

  return (
    <Box height={"86vh"} width={"100%"}>
      {deviceType === "pc" && (
        <Grid container spacing={0} height={"100%"}>
          <Grid item md={4} height={"100%"} >
            <Box
              maxWidth={"22vw"}
              position={"fixed"}
              height={"100%"}
              component={Card}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"start"}
              alignItems={"start"}
              padding={2}
            >
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"start"}
                alignItems={"center"}
              >
                <SearchFieldTop />
              </Box>
              <Divider />
              <Box
                py={2}
                width={"100%"}
                flex={1}
                display={"flex"}
                flexDirection={"column"}
              >
                {isLoading ? (
                  <List disablePadding>
                    {[1, 2, 3, 4, 5].map((it) => (
                      <ListItem key={it} divider>
                        <ChatShimmer />
                      </ListItem>
                    ))}
                  </List>
                ) : conversations?.length > 0 ? (
                  <List>
                    {conversations?.map((item: any, key: number) => (
                      <ListItemButton
                        key={key}
                        divider
                        disableGutters
                        sx={{
                          py: 1,
                          pl: 1,
                          width: "100%",
                          bgcolor:
                            selectedConversation === item
                              ? theme.palette.primary.light
                              : "transparent",
                        }}
                        onClick={() => {
                          dispatch(setSelectedConversation(item));
                          console.log("SELECTED CHAT :: ", item);

                          item?.users?.forEach((element: any) => {
                            if (element?.email !== profile.email) {
                              dispatch(setCurrentGuest(element));
                            }
                          });
                        }}
                      >
                        <ChatItem data={item} profile={profile} />
                      </ListItemButton>
                    ))}
                  </List>
                ) : (
                  <Box
                    height={"100%"}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <img src={notfound} alt="" width={100} />
                    <Typography>No conversations found</Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item md={8} flex={1}  >
            <ChatBox deviceType={deviceType} />
          </Grid>
        </Grid>
      )}
      {deviceType !== "pc" && (
        <Box
          width={"100%"}
          height={"100%"}
          p={2}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"start"} 
          alignItems={"start"}
        >
          <CustomTextField
            label=""
            placeholder="Search chat ... "
            name="chat"
            value={""}
            onChange={(e: any) => {}}
          />
          <Box
            flex={1}
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"start"}
            alignItems={"stretch"}
            p={2}
          >
            {conversations?.length < 1 ? (
              <Box
                width={"100%"}
                height={"100%"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img src={notfound} alt="" />
                <Typography fontSize={14} textAlign={"center"}>
                  No chats found. Connect with others and start chatting!
                </Typography>
              </Box>
            ) : (
              <Box>
                {conversations?.map((item: any, key: number) => (
                  <ListItemButton
                    key={key}
                    divider
                    disableGutters
                    sx={{ py: 1, width: "100%" }}
                    onClick={() => {
                      dispatch(setSelectedConversation(item));
                      console.log("SELECTED CHAT :: ", item);

                      item?.users?.forEach((element: any) => {
                        if (element?.email !== profile.email) {
                          dispatch(setCurrentGuest(element));
                        }
                      });

                      navigate(`/dashboard/message/${item?.id}`);
                    }}
                  >
                    <ChatItem data={item} profile={profile} />
                  </ListItemButton>
                ))}
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

const ChatItem = ({ data, profile }: ChatProps) => {
  const [guest, setGuest] = React.useState<any>();
  const dispatch = useAppDispatch();


  React.useEffect(() => {
    if (data) {
      data?.users?.forEach((element: any) => {
        console.log("ELEM IOS ::: ", element);
        
        
        if (element?._id !== profile.id) {
          setGuest(element);
        }
      });

      // dispatch(setSelectedConversation(data));
    }
  }, [data, dispatch, profile.id]);

  console.log("GUEST DATA ::: _-- ::", data);
  console.log("ELEM PROFILE DL ::: ", profile);

  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection="row"
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Box
        display={"flex"}
        flexDirection="row"
        justifyContent={"start"}
        alignItems={"center"}
      >
        <Avatar variant="circular" src={guest?.bio?.image} />
        <Box
          pl={1}
          display={"flex"}
          flexDirection="column"
          justifyContent={"start"}
          alignItems={"start"}
        >
          <Typography
            fontSize={18}
            fontWeight={600}
            textTransform={"capitalize"}
          >
            {`${guest?.bio?.firstname} ${guest?.bio?.lastname}`}
          </Typography>
          <Typography mt={-0.5} fontSize={13}>
            {data?.latestMessage?.content?.length > 24
              ? data?.latestMessage?.content?.substring(0, 24) + "..."
              : data?.latestMessage?.content ?? ""}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
