import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { Add, MinimizeOutlined } from "@mui/icons-material";
import { Box, Container, Toolbar } from "@mui/material";
import FAQHeader from "../../layouts/headers/faq";
import { useAppSelector } from "../../utils/hooks/apphook";
import { RootState } from "../../redux/store";
import FullFlex from "../../layouts/sections/full_flex";
import Oxygen from "../../layouts/sections/oxygen";
import Testimonial from "../../layouts/sections/testimonial";
import CallToAction from "../../layouts/sections/cta";

export default function FAQs() {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const sections = useAppSelector((state: RootState) => state.cms.sections);
  const [faqSections, setFAQSections] = React.useState<any>([]);
  const { faqs } = useAppSelector((state: RootState) => state.cms);

 
  React.useEffect(() => {
    if (sections) {
      const filterSections = sections?.filter((item: any) => item?.page === "faq");
      setFAQSections(filterSections);
    }
  }, [sections])


  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box>
      <br />
      <FAQHeader />
      <Toolbar />
      <Container>
        {faqs?.map((item: any, index: number) => (
          <Accordion
            key={index}
            expanded={expanded === "panel1" + index}
            onChange={handleChange("panel1"+index)}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel1"+index ? <Add /> : <MinimizeOutlined />
              }
              aria-controls={"panel1bh-content"+index}
              id={"panel1bh-header"+index}
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                {item?.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
               {item?.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
      <Toolbar />
      {
        faqSections && <Box>
          {
            faqSections?.map((item: any, index: number) => {
              return item?.template === "full-flex" ? <FullFlex key={index}  data={item} /> : item?.template === "oxygen" ?  <Oxygen data={item} /> : item?.template === "testimonial" ?  <Testimonial data={item} />  : item?.template === "call to action" ?  <CallToAction data={item} />  : <div />
            })
          }
        </Box>
      }
    </Box>
  );
}
