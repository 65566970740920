import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./assets/theme/Theme";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { SWRConfig } from "swr";
import APIService from "./service";
import SocketProvider from "./utils/context/socket_context";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId="964741321159-oqbch9v7eih4nukf3luf31ddf1t18dfl.apps.googleusercontent.com">
        <Provider store={store}>
          <SocketProvider>
            <Router>
              <SWRConfig
                value={{
                  // refreshInterval: 3000,
                  fetcher: (url) => APIService.fetcher(url),
                }}
              >
                <App />
              </SWRConfig>
            </Router>
          </SocketProvider>
        </Provider>
      </GoogleOAuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
