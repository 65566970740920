import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import image from "../../assets/images/about1.png";

type Props = {
  item: any;
};

const AboutUs: React.FC = () => {
  const [deviceType, setDeviceType] = React.useState("mobile");
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet]);

  return (
    <Box display={"flex"} flexDirection={"column"} justifyContent={"start"}>
      <Box
        bgcolor={theme.palette.primary.light}
        width={"100%"}
        display={"flex"}
        paddingY={deviceType === "pc" ? 1 : 6}
        flexDirection={"column"}
        justifyContent={"start"}
      >
        <Container sx={{ height: "100%" }}>
          <Grid
            container
            spacing={2}
            display="flex"
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            height={"100%"}
          >
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                gutterBottom
                fontSize={
                  deviceType === "pc"
                    ? "3.5rem"
                    : deviceType === "tablet"
                    ? "3.125rem"
                    : "2.0rem"
                }
                component="h1"
                color="black"
                fontWeight={700}
                textAlign={"left"}
              >
                About ProHelp
              </Typography>
              <Typography gutterBottom variant="body2" >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={deviceType !== "pc" ? "center" : "end"}
            >
              <img src={image} alt="" width={deviceType !== "pc" ? "99%" : "75%"} />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box
        bgcolor={"white"}
        py={10}
        color={"black"}
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"start"}
      >
        <Container sx={{ height: "100%" }}></Container>
      </Box>
    </Box>
  );
};

export default AboutUs;
