import {
  Avatar,
  Box,
  Card,
  IconButton,
  Rating,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
// import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../utils/hooks/apphook";
import RoundedButtonOutlined from "../button/rounded_button_outlined";
import { setLoading } from "../../redux/reducers/loader";
import APIService from "../../service";
import toast from "react-hot-toast";
import { MoreVertRounded } from "@mui/icons-material";

interface Props {
  data: any;
  profile?: any;
  setOpen?: any;
}
// interface DateOptions {
//   includeSeconds: boolean;
//   addSuffix: boolean;
// }

export default function ReviewsCard(props: Props) {
  const { data } = props;
  //   const navigate = useNavigate();
  //   const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [deviceType, setDeviceType] = React.useState("mobile");
  const [isApplied, setApplied] = React.useState(false);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  const profile = useAppSelector((state) => state.auth.profile);

  const checkApplied = () => {
    for (var element in profile["myJobApplications"]) {
      if (element.toString() === data["id"]) {
        setApplied(true);
      }
    }
  };

  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet]);

  React.useEffect(() => {
    checkApplied();
  }, []);

  //   let options: DateOptions = {
  //     addSuffix: true,
  //     includeSeconds: true,
  //   };

  return (
    <Card sx={{ mt: 1 }}>
      <Box
        p={0.75}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"start"}
        alignItems={"start"}
      >
        <Box
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"start"}
          alignItems={"start"}
        >
          <Box>
            <IconButton>
              <Avatar
                alt="Remy Sharp"
                sx={{
                  width:
                    deviceType === "pc"
                      ? 56
                      : deviceType === "tablet"
                      ? 50
                      : 36,
                  height:
                    deviceType === "pc"
                      ? 56
                      : deviceType === "tablet"
                      ? 50
                      : 36,
                }}
                src={data?.reviewer?.photo}
              />
            </IconButton>
          </Box>

          <Box
            pt={1}
            flex={1}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"start"}
            alignItems={"start"}
          >
            <Box
              width={"100%"}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography
                fontWeight={800}
                textTransform={"capitalize"}
                fontSize="1.15rem"
                lineHeight={1.0}
              >
                {`${data?.reviewer?.name} ${
                  data?.reviewer?.email === profile?.email ? "(You)" : ""
                }`}
              </Typography>
              {data?.reviewer?.email !== profile?.email && <MoreVertRounded />}
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"start"}
              alignItems={"center"}
              pb={2}
            >
              <Rating name="read-only" value={data?.rating ?? 1} readOnly />
              <Typography textTransform={"capitalize"} fontSize="0.80rem">
                {`${data?.rating ?? 1.0}/${5.0}, ${new Date(
                  data?.createdAt
                ).toLocaleDateString("en-GB")}`}
              </Typography>
            </Box>
            <Typography
              gutterBottom
              fontSize={"0.75rem"}
            >
              {`${data?.comment}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

const CloseContent: React.FC<Props> = ({ data, profile, setOpen }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const closeJob = async () => {
    dispatch(setLoading(true));
    setOpen(false);
    const payload = { jobStatus: "closed" };
    try {
      const resp = await APIService.update(
        "/job/update",
        profile?.email + "?jobId=" + data?.id,
        payload
      );

      dispatch(setLoading(false));
      toast.success(`${resp.data?.message}`);
      console.log(`CLOSE JOB RESPONSE ==> ${resp.data}`);
    } catch (e: any) {
      toast.error(`${e?.message || "Check your internet connection "}`);
      dispatch(setLoading(false));
    }
  };

  return (
    <Box
      p={4}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"start"}
    >
      <Typography variant="body2" gutterBottom mb={2}>
        {`Are you sure you want to mark this job as 'closed'?`}
      </Typography>
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <RoundedButtonOutlined
          variant="outlined"
          sx={{
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            height: 40,
            flex: 1,
            mr: 1,
          }}
          onClick={() => setOpen(false)}
        >
          Cancel
        </RoundedButtonOutlined>

        <RoundedButtonOutlined
          variant="contained"
          sx={{
            bgcolor: theme.palette.primary.main,
            color: "white",
            height: 40,
            flex: 1,
            ml: 1,
          }}
          onClick={() => closeJob()}
        >
          Close Job
        </RoundedButtonOutlined>
      </Box>
    </Box>
  );
};
