import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../utils/hooks/apphook";
import RoundedButton from "../../components/button/round_button";
import { useTheme } from "@mui/material";

import { setLoading } from "../../redux/reducers/loader";

import APIService from "../../service";
import { toast } from "react-hot-toast";
import JobInfoStep from "./poststeps/step1";
import JobRequirementStep from "./poststeps/step2";
import ScreeningStep from "./poststeps/step3";
import { setProfile } from "../../redux/reducers/auth";
import { setRequirements, setScreeningQuestions } from "../../redux/reducers/jobs";
import { mutate } from "swr";

interface InitialState {
  jobTitle: string;
  company: string;
  jobType: string;
  country: string;
  state: string;
  city: string;
  profession: string;
  workplaceType: string;
  description: string;
  minQualification: string;
  requirement1: string;
  requirement2: string;
  requirement3: string;
  requirement4: string;
  requirement5: string;
  question1: string;
  question2: string;
  question3: string;
  question4: string;
  question5: string;
  question6: string;
  question7: string;
  question8: string;
  question9: string;
  question10: string;
}

interface Props {
  setOpen: any;
}

const steps = ["Job Information", "Job Requirements", "Screening Questions"];

export default function PostJob({ setOpen }: Props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [requirementsCount, setRequirementsCount] = React.useState(1);
  const [questionsCount, setQuestionsCount] = React.useState(1);
  //   const [file, setFile] = React.useState<any>(null); // Resume file
  //   const [skipped, setSkipped] = React.useState(new Set<number>());
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const userProfile = useAppSelector((state) => state.auth.profile);
  const requirements = useAppSelector((state) => state.jobs.requirements);
  const screeningQuestions = useAppSelector(
    (state) => state.jobs.screeningQuestions
  );

  const step1Schema = Yup.object().shape({
    jobTitle: Yup.string().required("Job title is required"),
    company: Yup.string().required("Company name is required"),
    jobType: Yup.string().required("Job type name is required"),
    state: Yup.string().required("State name is required"),
    country: Yup.string().required("Country name is required"),
    city: Yup.string().required("City name is required"),

    workplaceType: Yup.string().required("Workplace type is required"),
  });

  const step2Schema = Yup.object().shape({
    profession: Yup.string().required("Profession is required!"),
    description: Yup.string().required("Job description is required!"),
    minQualification: Yup.string().required(
      "Minimum qualification is required!"
    ),
  });

  //   const step3Schema = Yup.object().shape({
  //     requirement1: Yup.string().required("This field is required"),
  //     question1: Yup.string().required("Your screening question is required"),
  //   });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const postJob = async () => {
    dispatch(setLoading(true));
    const payload = {
      jobTitle: values.jobTitle,
      profession: values.profession,
      company: values.company,
      workplaceType: values.workplaceType.toLowerCase(),
      jobLocation: {
        state: values.state,
        city: values.city,
        country: values.country,
      },
      screeningQuestions: screeningQuestions,
      requirements: requirements,
      jobType: values.jobType.toLowerCase(),
      minimumQualification: values.minQualification,
      description: values.description,
      recruiter: {
        id: userProfile?.id,
      },
     
    };

    try {
      const res = await APIService.post("/job/post/" + userProfile?.email, {
        ...payload,
      });

      console.log("RESP HERE >>> ", `${res}`);

      mutate('/job/byUser/'+userProfile?.email);

      dispatch(setRequirements([]))
      dispatch(setScreeningQuestions([]))

      setOpen(false);
      setActiveStep(0);
      dispatch(setLoading(false));

      toast.success(`${res?.data?.message}`);
    } catch (error: any) {
      dispatch(setLoading(false));
      console.log(
        "ERROR HERE >>> ",
        `${
          error?.response?.data?.message ||
          error?.message ||
          "Something went wrong, try again."
        }`
      );
    }
  };

  const initialValues: InitialState = {
    jobTitle: "",
    company: "",
    jobType: "",
    city: "",
    country: "",
    description: "",
    minQualification: "",
    profession: "",
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
    question6: "",
    question7: "",
    question8: "",
    question9: "",
    question10: "",
    requirement1: "",
    requirement2: "",
    requirement3: "",
    requirement4: "",
    requirement5: "",
    state: "",
    workplaceType: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema:
      activeStep === 0 ? step1Schema : activeStep === 1 ? step2Schema : null,
    onSubmit: () => {
      handleNext();
      if (activeStep === 2) {
        if (userProfile?.wallet?.balance >= 200) {
          postJob();
        } else {
          toast.error("Low wallet balance. Topup your wallet!");
        }
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    getFieldProps,
    setFieldValue,
    handleSubmit,
  } = formik;

  return (
    <Box sx={{ p: 4 }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {
        <React.Fragment>
          {/* <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}> */}
          <Box display={"flex"} flexDirection={"column"}>
            {activeStep === 0 ? (
              <JobInfoStep
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                getFieldProps={getFieldProps}
              />
            ) : activeStep === 1 ? (
              <JobRequirementStep
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                getFieldProps={getFieldProps}
                handleChange={handleChange}
              />
            ) : (
              <ScreeningStep
                values={values}
                errors={errors}
                touched={touched}
                questionsCount={questionsCount}
                requirementsCount={requirementsCount}
                setQuestionsCount={setQuestionsCount}
                setRequirementCount={setRequirementsCount}
                setFieldValue={setFieldValue}
                getFieldProps={getFieldProps}
              />
            )}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />

            <RoundedButton
              variant="contained"
              sx={{
                bgcolor: theme.palette.primary.main,
                color: "white",
                px: 6,
              }}
              onClick={() => handleSubmit()}
            >
              {activeStep === steps.length - 1 ? "Submit Application" : "Next"}
            </RoundedButton>
          </Box>
          {/* </Form>
          </FormikProvider> */}
        </React.Fragment> 
      }
    </Box>
  );
}
