import {
  Avatar,
  Box,
  Card,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../utils/hooks/apphook";
import formatDistance from "date-fns/formatDistance";
import RoundedButton from "../button/round_button";
import RoundedButtonOutlined from "../button/rounded_button_outlined";
import { setLoading } from "../../redux/reducers/loader";
import APIService from "../../service";
import toast from "react-hot-toast";
import { BrandedDialog } from "../dialog";

interface Props {
  data: any;
  profile?: any;
  setOpen?: any;
}
interface DateOptions {
  includeSeconds: boolean;
  addSuffix: boolean;
}

export default function MyPostedJobCard(props: Props) {
  const { data } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [deviceType, setDeviceType] = React.useState("mobile");
  const [isApplied, setApplied] = React.useState(false);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  const profile = useAppSelector((state) => state.auth.profile);

  const checkApplied = () => {
    for (var element in profile["myJobApplications"]) {
      if (element.toString() === data["id"]) {
        setApplied(true);
      }
    }
  };

  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet]);

  React.useEffect(() => {
    checkApplied();
  }, []);

  let options: DateOptions = {
    addSuffix: true,
    includeSeconds: true,
  };


  return (
    <Card sx={{ mt: 1 }}>
      <BrandedDialog
        open={open}
        setOpen={setOpen}
        title={`Close Job Applications`}
        body={<CloseContent data={data} setOpen={setOpen} profile={profile} />}
      />
      <Box
        p={2}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"start"}
        alignItems={"start"}
      >
        <Box>
          <IconButton onClick={() => navigate("")}>
            <Avatar
              alt="Remy Sharp"
              sx={{
                width: deviceType === "pc" ? 64 : 36,
                height: deviceType === "pc" ? 64 : 36,
              }}
              src={data?.recruiter?.bio?.image}
            />
          </IconButton>
        </Box>

        <Box
          p={0.5}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"start"}
          alignItems={"start"}
        >
          <Typography
            fontWeight={600}
            textTransform={"capitalize"}
            fontSize="1.2rem"
            lineHeight={1.0}
          >
            {data?.jobTitle}
          </Typography>

          <Typography textTransform={"capitalize"} fontSize="1.0rem">
            {`${data?.company}  (${data?.workplaceType})`}
          </Typography>

          <Typography textTransform={"capitalize"} fontSize="1.0rem">
            {`${data?.jobLocation?.city}, ${data?.jobLocation?.state}, ${data?.jobLocation?.country}`}
          </Typography>
          <Typography
            gutterBottom
            fontSize={"0.75rem"}
            textTransform={"capitalize"}
          >
            {`Posted  ${formatDistance(
              Date.parse(`${data?.createdAt}`),
              new Date(),
              options
            )}`}
          </Typography>
        </Box>
      </Box>
      <Box
        px={2}
        pb={2}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <RoundedButtonOutlined
          variant="outlined"
          sx={{
            flex: 1,
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
          }}
          onClick={() =>
            navigate("/dashboard/jobs/" + data?.id, { state: { data: data } })
          }
        >
          View
        </RoundedButtonOutlined>

        <RoundedButton
          disabled={data?.jobStatus !== "accepting"}
          variant="contained"
          sx={{
            flex: 1,
            mx: 1,
            bgcolor: theme.palette.primary.main,
            color: "white",
          }}
          onClick={() => setOpen(true)}
        >
          {data?.jobStatus !== "accepting" ? "Closed" : "Close"}
        </RoundedButton>
      </Box>
    </Card>
  );
}


const CloseContent: React.FC<Props> = ({ data, profile , setOpen}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const closeJob = async () => {
    dispatch(setLoading(true));
    setOpen(false);
    const payload = { jobStatus: "closed" };
    try {
      const resp = await APIService.update(
        "/job/update",
        profile?.email + "?jobId=" + data?.id,
        payload
      );

      dispatch(setLoading(false));
      toast.success(`${resp.data?.message}`);
      console.log(`CLOSE JOB RESPONSE ==> ${resp.data}`);
    } catch (e: any) {
      toast.error(`${e?.message || "Check your internet connection "}`);
      dispatch(setLoading(false));
    }
  };

  return (
    <Box
      p={4}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"start"}
    >
      <Typography variant="body2" gutterBottom mb={2}>
        {`Are you sure you want to mark this job as 'closed'?`}
      </Typography>
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <RoundedButtonOutlined
          variant="outlined"
          sx={{
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            height: 40,
            flex: 1,
            mr: 1,
          }}
          onClick={() => setOpen(false)}
        >
          Cancel
        </RoundedButtonOutlined>

        <RoundedButtonOutlined
          variant="contained"
          sx={{
            bgcolor: theme.palette.primary.main,
            color: "white",
            height: 40,
            flex: 1,
            ml: 1,
          }}
          onClick={() => closeJob()}
        >
          Close Job
        </RoundedButtonOutlined>
      </Box>
    </Box>
  );
};