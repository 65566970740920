import React from "react";
import Box from "@mui/material/Box";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Typography from "@mui/material/Typography";
import {  useMediaQuery } from "@mui/material";
import theme from "../../assets/theme/Theme";
import CustomContainer from "../../components/container";
import RoundedButton from "../../components/button/round_button";

interface Props {
  data: any;
}

const CallToAction: React.FC<Props> = ({ data }) => {
  const [deviceType, setDeviceType] = React.useState("mobile");
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));
  const tabletBig = useMediaQuery(theme.breakpoints.only("md"));

  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else if (tabletBig) {
      setDeviceType("tabletBig");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet, tabletBig]);

  return (
    <Box paddingY={deviceType === "pc" ? 16 : deviceType === "tablet" ? 12 : 8}>
      <CustomContainer>
        <Box
         
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography gutterBottom fontWeight={600} variant="h4">
            {data?.title}
          </Typography>
          <Typography variant="body2" >
            {data?.excerpt}
          </Typography>
          <br/>
          <RoundedButton sx={{width: 100}}  >
            Learn More
          </RoundedButton>
        </Box>
      </CustomContainer>
    </Box>
  );
};

export default CallToAction;
