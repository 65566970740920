import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
// import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import parse from "autosuggest-highlight/parse";
// import { useAppSelector } from "../../utils/hooks/apphook";
// import { useSWR } from "swr";
import APIService from "../../service";
import { Button, Divider, useMediaQuery, useTheme } from "@mui/material";

// import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useNavigate } from "react-router-dom";
import RoundedButton from "../button/round_button";
import { LocationOn, SearchTwoTone } from "@mui/icons-material";
import { myLocations } from "../../utils/data/locations";

export default function MyAutoComplete() {
  const [mLocation, setLocation] = React.useState<string | null>(null);
  // const [inputValue, setInputValue] = React.useState("");
  // const [options, setOptions] = React.useState<string[] | null>();
  const [deviceType, setDeviceType] = React.useState("mobile");
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  // const loaded = React.useRef(false);
  const navigate = useNavigate();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  const searchFetcher = async (key: string) => {
    try {
      if (mLocation === null || mLocation === undefined) {
        let resp = await APIService.fetcher("/searching/" + key + "?location=nigeria");
        console.log(resp);
        navigate("/searchresults/" + key, {
          state: {
            data: resp?.data,
            searchTerm: key,
            searchLocation: 'nigeria',
          },
        });
      } else {
        let resp = await APIService.fetcher(
          "/searching/" + key + "?location=" + mLocation.toLowerCase()
        );
        console.log(resp);
        navigate("/searchresults/" + key, {
          state: {
            data: resp?.data,
            searchTerm: key,
            searchLocation: mLocation,
          },
        });
      }
      // setOptions(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="start"
      alignItems="stretch"
    >
      <Box
        pl={1.5}
        border="1px solid"
        borderColor={theme.palette.primary.light}
        borderRadius={1}
        display="flex"
        bgcolor={"white"}
        color={"black"}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <TextField
          fullWidth
          variant="standard"
          size="small"
          placeholder="Search for any service here ..."
          sx={{ border: "none", borderRadius: 32, width: "100%" }}
          onChange={(e) => {
            setSearchTerm(e?.target?.value);
          }}
          InputProps={{
            disableUnderline: true,
            style: {
              color: "black",
              fontSize: 13,
            },
            startAdornment: <SearchTwoTone />,
          }}
        />
        <Box
          bgcolor={"#ccc"}
          width={2}
          height={56}
          p={0.05}
          mr={2}
          zIndex={10}
        />
        <Autocomplete
          id="prohelp-search-demo"
          sx={{
            width: "50%",
            minWidth: 75,
            maxWidth: "70%",
            color: "white",
            display: "inline-block",
            "& input": {
              width: 200,
              color: "black",
              border: "none",
            },
          }}
          getOptionLabel={(option: string) => option}
          // filterOptions={(x) => x}
          options={myLocations}
          autoComplete
          value={mLocation}
          autoHighlight
          noOptionsText="No data found"
          onChange={(event: any, newValue) => {
            // setOptions(options);
            setLocation(newValue);
          }}
          // onInputChange={(event, newInputValue) => {
          //   setInputValue(newInputValue);
          //   console.log("Picked NOW");
          // }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              // value={value}
              variant="standard"
              size="small"
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                autoComplete: "new-password",
                style: {
                  fontSize: 13,
                },
              }}
              placeholder={"Location "}
              sx={{
                border: "none",
                borderRadius: 32,
                width: "100%",
                color: "white",
              }}
            />
          )}
          renderOption={(props, option: string) => {
            return (
              <Box
                component={"li"}
                {...props}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"start"}
                alignItems={"center"}
                mx={-1}
                py={1}
              >
                <LocationOn fontSize="small" sx={{ color: "text.secondary" }} />
                <Typography
                  textTransform={"capitalize"}
                  color="text.secondary"
                  fontSize={"0.75rem"}
                  ml={1}
                >
                  {`${option}`}
                </Typography>
              </Box>
            );
          }}
        />

        <Box
          borderColor="white"
          borderRadius={32}
          bgcolor={"white"}
          display={deviceType === "mobile" ? "none" : "flex"}
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          padding={1}
          sx={{
            height: "100%",
            width:
              deviceType === "pc" ? 120 : deviceType === "tablet" ? 105 : 86,
          }}
        >
          <Button
            variant="contained"
            sx={{
              textTransform: "capitalize",
              bgcolor: theme.palette.primary.main,
              color: "white",
              paddingY: 2,
              minWidth:
                deviceType === "pc" ? 108 : deviceType === "tablet" ? 90 : 50,
            }}
            onClick={!searchTerm ? () => {} : () => searchFetcher(searchTerm)}
          >
            Search
          </Button>
        </Box>
      </Box>
      {deviceType === "mobile" && (
        <Button
          variant="contained"
          sx={{
            bgcolor: theme.palette.primary.main,
            color: "white",
            minWidth: 70,
            mt: 1,
            p: 1.5,
            textTransform: "capitalize",
          }}
        >
          Search
        </Button>
      )}
    </Box>
  );
}

/*
 */
