import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
interface CMSState {
  banners: any;
  sections: any;
  faqs: any;
}

// Define the initial state using that type
const initialState: CMSState = {
  sections: [],
  banners: [],
  faqs: [],
};

export const cmsSlice = createSlice({
  name: "cms",
  initialState,
  reducers: {
    setSections: (state, action: PayloadAction<any>) => {
      state.sections = action.payload;
    },
    setBanners: (state, action: PayloadAction<any>) => {
      state.banners = action.payload;
    },
    setFAQs: (state, action: PayloadAction<any>) => {
      state.faqs = action.payload;
    },
  },
});

export const { setBanners, setFAQs, setSections } = cmsSlice.actions;

export default cmsSlice.reducer;
