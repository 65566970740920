import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import RoundedButton from "../button/round_button";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  ...theme.typography.body2,
  "& > :not(style) ~ :not(style)": {
    marginTop: theme.spacing(2),
  },
}));

interface Props {
  label: string;
  onClick?: any;
  type?: any;
}

export default function DividerText() {
  return (
    <Root>
      <Divider>
        <Chip label="Or" />
      </Divider>
    </Root>
  );
}

export function DividerButton({ label , onClick, type}: Props) {
  const theme = useTheme();
  return (
    <Root>
      <Divider>
        <RoundedButton
          variant="outlined"
          type={type}
          sx={{
            height: 32,
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
          }}
          onClick={onClick}
        >
          {label}
        </RoundedButton>
      </Divider>
    </Root>
  );
}

export function DividerLongText(props: Props) {
  let { label } = props;
  return (
    <Root>
      <Divider>{label}</Divider>
    </Root>
  );
}
