import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import image from "../../assets/images/xpl.png";
import React from "react";
import theme from "../../assets/theme/Theme";
import CustomContainer from "../../components/container";
import { useAppSelector } from "../../utils/hooks/apphook";

export default function ExploreHeader() {
  const [deviceType, setDeviceType] = React.useState("mobile");
  const [exploreData, setExploreData] = React.useState<any>();

  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  const { banners } = useAppSelector((state) => state.cms);

  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet]);

  React.useEffect(() => {
    if (banners) {
      const explore = banners?.filter((item: any) => item?.page === "explore");
      console.log("EXPLORE DTA :: ", explore);
      setExploreData(explore[0]);
    }
  }, [banners])

  return (
    <Box py={4} height={"75%"} bgcolor={"white"}>
      <CustomContainer>
        <Grid
          container
          spacing={2}
          color={"black"}
          display={"flex"}
          flexDirection="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={7} md={6} lg={5}>
            <Typography
              variant={deviceType === "pc" ? "h2" : "h4"}
              fontWeight={600}
              gutterBottom
              lineHeight={1.1}
            >
             {exploreData?.title}
            </Typography>
            <Typography>
             {exploreData?.description}
            </Typography>
          </Grid>
          <Grid
            sx={{ display: { xs: "none", sm: "block", md: "block" } }}
            item
            xs={12}
            sm={5}
            md={6}
            lg={5}
          >
            <img src={exploreData?.featuredImage} alt="" width={"100%"} />
          </Grid>
        </Grid>
      </CustomContainer>
    </Box>
  );
}
