import {
  Box,
  IconButton,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { DividerButton } from "../../../components/divider/text_divider";
import { DeleteOutline, DeleteOutlineOutlined } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/apphook";
import {
  setRequirements,
  setScreeningQuestions,
} from "../../../redux/reducers/jobs";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

export interface Props {
  values: any;
  getFieldProps: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  setRequirementCount: any;
  setQuestionsCount: any;
  requirementsCount: number;
  questionsCount: number;
}

export default function ScreeningStep({
  errors,
  getFieldProps,
  values,
  touched,
  setFieldValue,
  questionsCount,
  requirementsCount,
  setQuestionsCount,
  setRequirementCount,
}: Props) {
  const [clicked, setClicked] = React.useState(false);
  const [showMore, setShowMore] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const dispatch = useAppDispatch();
  const requirements = useAppSelector((state) => state.jobs.requirements);

  const schema = Yup.object().shape({
    requirement: Yup.string().required("THis field is required"),
  });

  const addRequirement = (value: string) => {
    let arr: string[] = [];
    arr.push(value);
    setTimeout(() => {
      const allTogether = [...arr, ...requirements];
      dispatch(setRequirements(allTogether));
      setClicked(true);
      setShowMore(false);
    }, 3000);
  };

  const formik = useFormik({
    initialValues: { requirement: "" },
    validationSchema: schema,
    onSubmit: (values) => {
      console.log("BEFORE :: ", requirements);

      addRequirement(values.requirement);

      console.log("AFTER :: ", requirements);
    },
  });

  const {
    errors: errs,
    handleSubmit,
    touched: tched,
    getFieldProps: getFProps,
  } = formik;

  return (
    <Box width={"100%"} py={4}>
      <Typography textAlign={"center"}>
        You are almost done. Add Requirements
      </Typography>

      {requirements?.length > 0 && (
        <Box display={"flex"} flexDirection={"column"} justifyContent={"start"}>
          <Typography
            variant="h6"
            fontWeight={700}
            color={"black"}
            gutterBottom
          >
            Requirements
          </Typography>
          {requirements?.map((item: string, index: number) => {
            return (
              <Box
                key={index}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                component={ListItem}
                divider
                disablePadding
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"start"}
                  alignItems={"start"}
                >
                  <Typography>{`${index + 1}. `}</Typography>
                  <Typography px={1}>{item}</Typography>
                </Box>
                <IconButton>
                  <DeleteOutline />
                </IconButton>
              </Box>
            );
          })}
        </Box>
      )}

      {(requirements?.length < 1 || showMore) && (
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Box>
              <TextField
                variant="outlined"
                label="Requirement"
                size="small"
                required
                fullWidth
                {...getFProps(`requirement`)}
                error={Boolean(tched.requirement && errs.requirement)}
                helperText={errs.requirement}
                sx={{ mt: 2, mb: 2 }}
              />

              <DividerButton label={"Save Requirement"} type={"submit"} />
            </Box>
          </Form>
        </FormikProvider>
      )}

      {clicked && (
        <>
          <br />
          <DividerButton
            label={"Add More"}
            onClick={() => {
              setCount(count + 1);
              setShowMore(true);
              setClicked(false);
            }}
          />
        </>
      )}

      <ScreeningQuestions />
    </Box>
  );
}

const ScreeningQuestions = () => {
  const [clicked, setClicked] = React.useState(false);
  const [showMore, setShowMore] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const dispatch = useAppDispatch();
  const screeningQuestions = useAppSelector(
    (state) => state.jobs.screeningQuestions
  );

  const schema = Yup.object().shape({
    screeningQuestions: Yup.string().required("THis field is required"),
  });

  const addQuestions = (value: string) => {
    let arr: string[] = [];
    arr.push(value);
    setTimeout(() => {
      const allTogether = [...arr, ...screeningQuestions];
      dispatch(setScreeningQuestions(allTogether));
      setClicked(true);
      setShowMore(false);
    }, 3000);
  };

  const formik = useFormik({
    initialValues: { screeningQuestions: "" },
    validationSchema: schema,
    onSubmit: (values) => {
      console.log("BEFORE :: ", screeningQuestions);

      addQuestions(values.screeningQuestions);

      console.log("AFTER :: ", screeningQuestions);
    },
  });

  const {
    errors: errs,
    handleSubmit,
    touched: tched,
    getFieldProps: getFProps,
  } = formik;

  return (
    <Box width={"100%"} py={2}>
      {screeningQuestions?.length > 0 && (
        <Box display={"flex"} flexDirection={"column"} justifyContent={"start"}>
          <Typography
            variant="h6"
            fontWeight={700}
            color={"black"}
            gutterBottom
          >
            Screening Questions
          </Typography>
          {screeningQuestions?.map((item: string, index: number) => {
            return (
              <Box
                key={index}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                component={ListItem}
                divider
                disablePadding
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"start"}
                  alignItems={"start"}
                >
                  <Typography>{`${index + 1}. `}</Typography>
                  <Typography px={1}>{item}</Typography>
                </Box>
                <IconButton>
                  <DeleteOutline />
                </IconButton>
              </Box>
            );
          })}
        </Box>
      )}

      {(screeningQuestions?.length < 1 || showMore) && (
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Box>
              <TextField
                variant="outlined"
                label="Question"
                size="small"
                required
                fullWidth
                {...getFProps(`screeningQuestions`)}
                error={Boolean(tched.screeningQuestions && errs.screeningQuestions)}
                helperText={errs.screeningQuestions}
                sx={{ mt: 2, mb: 2 }}
              />

              <DividerButton label={"Save Question"} type={"submit"} />
            </Box>
          </Form>
        </FormikProvider>
      )}

      {clicked && (
        <>
          <br />
          <DividerButton
            label={"Add More"}
            onClick={() => {
              setCount(count + 1);
              setShowMore(true);
              setClicked(false);
            }}
          />
        </>
      )}
    </Box>
  );
};
