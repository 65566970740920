import {
  Box,
  Chip,
  Grid,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../utils/hooks/apphook";

export default function ReportsOverview() {
  const theme = useTheme();
  const [deviceType, setDeviceType] = React.useState("mobile");
  const profile = useAppSelector((state) => state.auth.profile);
  const myAppliedJobs = useAppSelector((state) => state.jobs.myJobApplications);
  const myPostedJobs = useAppSelector((state) => state.jobs.myPostedJobs);

  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet]);

  return (
    <Box
      p={2}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
    >
      <Box
        px={deviceType === "pc" ? 6 : deviceType === "tablet" ? 4 : 2}
        py={deviceType === "pc" ? 4 : deviceType === "tablet" ? 3 : 2}
        bgcolor={theme.palette.primary.main}
        borderRadius={5}
        color={"white"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={deviceType === "mobile" ? "center" : "start"}
          alignItems={deviceType === "mobile" ? "center" : "start"}
          pb={deviceType !== "pc" ? 2 : 0}
        >
          <Typography
            textAlign={deviceType === "mobile" ? "center" : "start"}
            variant="h5"
            gutterBottom
          >
            Overview
          </Typography>
          <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec
            purus urna. Sed eget semper dolor, id venenatis quam. Maecenas id
            justo nec turpis
          </Typography>
        </Box>
      </Box>

      <Toolbar />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            p={4}
            bgcolor={theme.palette.primary.light}
            display={"flex"}
            borderRadius={4}
            flexDirection={"column"}
            justifyContent={"start"}
            alignItems={"start"}
          >
            <Typography gutterBottom fontSize={14} fontWeight={600}>
              My Balance
            </Typography>
            <Typography
              fontSize={21}
              fontWeight={"900"}
            >{`${profile?.wallet?.balance} Coins`}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            p={4}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"start"}
            alignItems={"start"}
            borderRadius={4}
            bgcolor={theme.palette.primary.light}
          >
            <Typography gutterBottom fontSize={14} fontWeight={600}>
              Connections
            </Typography>
            <Typography
              fontSize={21}
              fontWeight={"900"}
            >{`${profile?.connections?.length} Connections`}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            p={4}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"start"}
            alignItems={"start"}
            borderRadius={4}
            bgcolor={theme.palette.primary.light}
          >
            <Typography gutterBottom fontSize={14} fontWeight={600}>
              {profile?.accountType === "recruiter"
                ? "Jobs Pssted"
                : "Jobs Applied"}
            </Typography>
            <Typography fontSize={21} fontWeight={"900"}>{`${
              profile?.accountType === "recruiter"
                ? myPostedJobs?.length
                : myAppliedJobs.length
            } Jobs`}</Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={2}>
        {profile.accountType !== "recruiter" && (
          <Grid item xs={12} sm={6} md={4}>
            <Box
              p={4}
              bgcolor={theme.palette.primary.light}
              display={"flex"}
              borderRadius={4}
              flexDirection={"column"}
              justifyContent={"start"}
              alignItems={"start"}
              height={"100%"}
            >
              <Typography gutterBottom fontSize={14} fontWeight={600}>
                Skills
              </Typography>
              <Typography
                fontSize={21}
                fontWeight={"900"}
                gutterBottom
              >{`${profile?.skills?.length} skills`}</Typography>
              <Grid
                container
                spacing={2}
                width={"100%"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"start"}
                alignItems={"center"}
              >
                {profile?.skills?.map((item: any, index: number) => {
                  return (
                    <Grid key={index} item xs={12} sm={6} md={6}>
                      <Chip label={item?.name} sx={{ width: 120 }} />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <Box
            p={4}
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"start"}
            alignItems={"start"}
            borderRadius={4}
            bgcolor={theme.palette.primary.light}
          >
            <Typography gutterBottom fontSize={14} fontWeight={600}>
              Previous Balance
            </Typography>
            <Typography
              fontSize={21}
              fontWeight={"900"}
            >{`${profile?.wallet?.prevBalance} Coins`}</Typography>
          </Box>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4}  >
          <Box
            p={4}
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"start"}
            alignItems={"start"}
            borderRadius={4}
            bgcolor={theme.palette.primary.light}
          >
            <Typography gutterBottom fontSize={14} fontWeight={600}>
              Jobs Applied
            </Typography>
            <Typography
              fontSize={21}
              fontWeight={"900"}
            >{`${profile?.myJobApplications?.length} Jobs`}</Typography>
          </Box>
        </Grid> */}
      </Grid>
      <Toolbar />
    </Box>
  );
}
