import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import theme from "../../assets/theme/Theme";
import CustomContainer from "../../components/container";
import { useAppSelector } from "../../utils/hooks/apphook";
import RoundedButton from "../../components/button/round_button";

export default function FAQHeader() {
  const [deviceType, setDeviceType] = React.useState("mobile");
  const [faqData, setFAQData] = React.useState<any>();

  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const tablet = useMediaQuery(theme.breakpoints.only("sm"));

  const { banners } = useAppSelector((state) => state.cms);

  React.useEffect(() => {
    if (mobile) {
      setDeviceType("mobile");
    } else if (tablet) {
      setDeviceType("tablet");
    } else {
      setDeviceType("pc");
    }
  }, [mobile, tablet]);

  React.useEffect(() => {
    if (banners) {
      const faq = banners?.filter((item: any) => item?.page === "faq");
      console.log("FAQ DATA :: ", faq);
      setFAQData(faq[0]);
    }
  }, [banners])

  return (
    <Box
      bgcolor={deviceType !== "pc" ? "transparent" : theme.palette.primary.main}
      sx={{
        backgroundImage: deviceType !== "pc" ? "url(" + faqData?.featuredImage + ")" : "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      padding={theme.spacing(2)}
      position={'relative'}
    >
      {
        deviceType !== "pc" && <Box width={'100%'} height={'100%'} bgcolor={'#00000087'} position={'absolute'} top={0} left={0} right={0} />
      }
      <CustomContainer >
        <Grid
          container
          spacing={2}
          display="flex"
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={6} color={"white"} pr={4} width={ deviceType !== "pc" ? "96%" : "75%"} zIndex={10} >
            <Box maxWidth={420}>
              {
                deviceType !== "pc" ? <br /> : <></>
              }
              <Typography gutterBottom variant={deviceType !== "pc" ? "h4" : "h3"}>
                 {faqData?.title}
              </Typography>
              <Typography gutterBottom variant="body1">
                {faqData?.description}
              </Typography>
              {/* <Box display={"flex"} flexDirection={"row"} pt={2} pb={8}>
                <RoundedButton sx={{ width: 144 }}>Get Started</RoundedButton>
              </Box> */}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            p={theme.spacing(1)}
            sx={{ display: { xs: "none", sm: "none", md: "block" } }}
          >
            <img
              src={faqData?.featuredImage}
              alt=""
              style={{ borderRadius: 10 }}
              width={"100%"}
              height={"100%"}
            />
          </Grid>
        </Grid>
      </CustomContainer>
    </Box>
  );
}
