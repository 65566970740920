import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import contactImage from "../../../assets/images/support_big.svg";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import RoundedButton from "../../button/round_button";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/reducers/loader";
import { useAppSelector } from "../../../utils/hooks/apphook";
import APIService from "../../../service";
import toast from "react-hot-toast";

type ContactType = {
  reason: string;
  message: string;
};

interface Props {
  setOpen?: any;
}

export default function ContactSupport({ setOpen }: Props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const profile = useAppSelector((state) => state.auth.profile);

  const supportSchema = Yup.object().shape({
    reason: Yup.string().required("Reason is required "),
    message: Yup.string().required("Message is required "),
  });

  const initialValues: ContactType = {
    reason: "",
    message: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: supportSchema,
    onSubmit: async () => {
      try {
        dispatch(setLoading(true));

        const payload = {
          purpose: values.reason,
          message: values.message,
          user: {
            fullname: `${profile.bio.firstname} ${profile.bio.lastname}`,
            email: profile?.email,
            id: profile?.id,
            image: profile?.bio?.image,
          },
        };

        const resp = await APIService.post(
          "/support/" + profile?.email,
          payload
        );
        dispatch(setLoading(false));
        if (setOpen) {
          setOpen(false);
        }
        toast.success(`${resp.data.message}`);
      } catch (error: any) {
        dispatch(setLoading(true));
        toast.error(`${error?.message || "Check your internet connection!"}`);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    getFieldProps,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = formik;

  return (
    <Box
      p={4}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"center"}
    >
      <img src={contactImage} alt="" width={150} height={150} />
      <Typography
        fontSize={14}
        gutterBottom
        pb={4}
        pt={2}
        px={6}
        textAlign={"center"}
      >
        Contact our support team by filling out the form below.
      </Typography>
      <FormikProvider value={formik}>
        <Form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
          style={{ width: "100%" }}
        >
          <Box display={"flex"} flexDirection={"column"} width={"100%"}>
            <FormControl
              fullWidth
              error={Boolean(errors.reason && touched.reason)}
              sx={{ mb: 2 }}
            >
              <InputLabel id="demo-simple-select-label">Purpose</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                variant="outlined"
                label="Degree"
                type="text"
                size="small"
                required
                fullWidth
                name="reason"
                value={values.reason}
                onChange={(e) => {
                  handleChange("reason");
                  setFieldValue("reason", e.target.value);
                  console.log("CURRENT DEGREE :: ", e.target.value);
                }}
                placeholder="Select degree here"
                //   {...getFieldProps("degree")}
              >
                {["support", "complain", "request"].map(
                  (item: string, index: number) => (
                    <MenuItem
                      key={index}
                      sx={{ textTransform: "capitalize" }}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  )
                )}
              </Select>
              <FormHelperText>{errors.reason}</FormHelperText>
            </FormControl>
            <TextField
              variant="outlined"
              fullWidth
              {...getFieldProps("message")}
              label="Message"
              placeholder="Enter message here"
              minRows={5}
              maxRows={10}
              multiline
              error={Boolean(errors.message && touched.message)}
              helperText={errors.message}
            />
            <Toolbar />
            <RoundedButton
              type="submit"
              variant="contained"
              sx={{ bgcolor: theme.palette.primary.main, color: "white" }}
            >
              Submit Request
            </RoundedButton>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
}
